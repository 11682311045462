import axios from 'axios'
// next import is absolutely necessary to render the Bar chart
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS } from 'chart.js/auto' // for some reason we need /auto here
import { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import BookConsultationCTA from '../common/BookConsultationCTA/BookConsultationCTA'
import ChartAndNav from './ChartAndNav/ChartAndNav'
import Footer from '../Footer/Footer'
import ResultsHeader from './ResultsHeader/ResultsHeader'
import ResultsTable from './ResultsTable/ResultsTable'
import ResultsText from './ResultsText/ResultsText'
import { calcModResult } from './ResultsUtils/calcModResults'
import { getChartDataMod2 } from './ResultsUtils/getChartDataMod2'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { showNoCategoryBenchInfo } from '../../utils/getMiscToastMsg'
import { CONSTRUCTS, MIN_SAMPLE_SIZE } from '../../utils/constants'
import { getFeedbackTexts } from '../../utils/getFeedbackTexts'
import { formatDate } from '../../utils/formatDate'

const API_URL = process.env.REACT_APP_API_URL
const CONSTRUCTS_M2 = [ // the order here pertains only to the text rendering below the table
  CONSTRUCTS.VISIONARY_LEADERSHIP,
  CONSTRUCTS.SERVANT_LEADERSHIP,
]

// render the Module 2 (Step 3) survey results; called by Results.js
const ResultsModule2 = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const [benchMod2All, setBenchMod2All] = useState(null)
  const [benchMod2Cat, setBenchMod2Cat] = useState(null)
  const [mod2AllResults, setMod2AllResults] = useState(null)
  const [mod2CatResults, setMod2CatResults] = useState(null)
  const [mod2BenchCatResults, setMod2BenchCatResults] = useState(null)  // NPO category compared with all NPOs
  const [mod2FeedbackTexts, setMod2FeedbackTexts] = useState({})
  const [chartImages, setChartImages] = useState({})
  const noCatBenchInfoHasBeenShown = useRef(null)

  useEffect(() => {
    // get latest benchmark
    if (user.strapiJwt && jt.toast) {
      if (!benchMod2All) { // only run this once
        axios
          .get(
            `${API_URL}/api/benchmarks` +
            `?filters[module][$eq]=2` +
            `&filters[orgCategory][$eq]=all`,
            authHeaders(),
          )
          .then(res => {
            const benchmarkModuleAll = res.data.data[0].attributes.benchmark
            setBenchMod2All(benchmarkModuleAll)
          })
          .catch(error => handleStrapiAPIError(error))
      }
      if (!benchMod2Cat) { // only run this once
        if (user.orgCategory) {
          axios
            .get(`${API_URL}/api/benchmarks` +
              `?filters[module][$eq]=2` +
              `&filters[orgCategory][$eq]=${user.orgCategory}`,
              authHeaders(),
            )
            .then(res => {
              const benchMod2CatData = res.data.data[0].attributes.benchmark
              // console.log('benchMod2CatData:', benchMod2CatData)
              if (benchMod2CatData.sample_size >= MIN_SAMPLE_SIZE) {
                setBenchMod2Cat(benchMod2CatData)
              } else {
                if (noCatBenchInfoHasBeenShown.current !== true) {
                  showNoCategoryBenchInfo(jt.toast)
                  noCatBenchInfoHasBeenShown.current = true
                }
                setBenchMod2Cat('Category not available')
              }
            })
            .catch(error => handleStrapiAPIError(error))
        } else setBenchMod2Cat('Category not available')
      }
      // get the latest answers for this user if they have fully completed this module
      if (benchMod2All && benchMod2Cat) {
        axios
          .get(
            `${API_URL}/api/npo-surveys` +
            `?filters[organizationID][$eq]=${user.orgID}` +
            `&filters[module][$eq]=2` +
            `&sort=createdAt:desc`,
            authHeaders(),
          )
          .then(res => {
            if (res.data.data.length === 0) return
            const answerObject = res.data.data[0].attributes.npoAnswers
            const resultsAll = calcModResult(2, benchMod2All, answerObject)
            const resultsCat = benchMod2Cat === 'Category not available' ? null
              : calcModResult(2, benchMod2Cat, answerObject)
            const resultsBenchCat = benchMod2Cat === 'Category not available' ? null
              : calcModResult(2, benchMod2All, benchMod2Cat)
            // console.log('resultsBenchCat:', resultsBenchCat)
            setMod2AllResults(resultsAll)
            setMod2CatResults(resultsCat)
            setMod2BenchCatResults(resultsBenchCat)
            const resultsForFB = resultsCat ? resultsCat : resultsAll
            setMod2FeedbackTexts(getFeedbackTexts(jt.results, resultsForFB, CONSTRUCTS_M2))
          })
          .catch(error => handleStrapiAPIError(error))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jt, user, benchMod2All, benchMod2Cat])

  const chartData = mod2AllResults && benchMod2All ?
    {
      first: getChartDataMod2(
        jt.label,
        benchMod2All,
        mod2BenchCatResults,
        mod2AllResults,
        mod2CatResults,
        'summary',
      ),
    }
    : null


  const chartLabels = {
    first: jt.label?.mod2Overview || 'Module 2 Overview',
  }

  const pdfFileName = formatDate(new Date(), 'YYYY.MM.DD') + '_' +
    (jt.label?.module2ResultsFileName || 'Module_2_Results') + '.pdf'

  return (
    <div>
      <ResultsHeader
        module={'module2'}
        chartsLoaded={!!chartImages.first?.imgData}
        chartImages={chartImages}
        benchModAll={benchMod2All}
        benchModCat={benchMod2Cat}
        modAllResults={mod2AllResults}
        modCatResults={mod2CatResults}
        constructs={CONSTRUCTS_M2}
        feedbackTexts={mod2FeedbackTexts}
        pdfFileName={pdfFileName}
      />

      <Container>
        <ChartAndNav
          chartData={chartData}
          chartLabels={chartLabels}
          type={'bar'}
          setChartImages={setChartImages}
        />
        {chartData &&
          <ResultsTable
            jtLabel={jt.label || {}}
            benchModAll={benchMod2All}
            benchModCat={benchMod2Cat}
            modAllResults={mod2AllResults}
            modCatResults={mod2CatResults}
            constructs={CONSTRUCTS_M2}
          />
        }
        <ResultsText
          module={'module2'}
          constructs={CONSTRUCTS_M2}
          modAllResults={mod2AllResults}
          modCatResults={mod2CatResults}
          feedbackTexts={mod2FeedbackTexts}
        />

        <BookConsultationCTA />
      </Container>
      <Footer />
    </div>
  )
}

export default ResultsModule2
