import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import BookConsultationCTA from '../common/BookConsultationCTA/BookConsultationCTA'
import ChartAndNav from './ChartAndNav/ChartAndNav'
import Footer from '../Footer/Footer'
import ResultsHeader from './ResultsHeader/ResultsHeader'
import ResultsTable from './ResultsTable/ResultsTable'
import ResultsText from './ResultsText/ResultsText'
import { calcModResult } from './ResultsUtils/calcModResults'
import { getChartDataMod3 } from './ResultsUtils/getChartDataMod3'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { showNoCategoryBenchInfo } from '../../utils/getMiscToastMsg'
import { CONSTRUCTS, MIN_SAMPLE_SIZE } from '../../utils/constants'
import { getFeedbackTexts } from '../../utils/getFeedbackTexts'
import { formatDate } from '../../utils/formatDate'

const API_URL = process.env.REACT_APP_API_URL
const CONSTRUCTS_M3 = [ // the order here pertains only to the text rendering below the table
  CONSTRUCTS.CONSENSUS,
  CONSTRUCTS.STRATEGY,
  CONSTRUCTS.COMMUNICATION,
  CONSTRUCTS.HR_DIVERSITY,
  CONSTRUCTS.HR_LOYALTY,
]

// render the Module 3 (Step 4) survey results; called by Results.js
const ResultsModule3 = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const [benchMod3All, setBenchMod3All] = useState(null)
  const [benchMod3Cat, setBenchMod3Cat] = useState(null)
  const [mod3AllResults, setMod3AllResults] = useState(null)
  const [mod3CatResults, setMod3CatResults] = useState(null)
  const [mod3BenchCatResults, setMod3BenchCatResults] = useState(null)  // NPO category compared with all NPOs
  const [mod3FeedbackTexts, setMod3FeedbackTexts] = useState({})
  const [chartImages, setChartImages] = useState({})
  const noCatBenchInfoHasBeenShown = useRef(null)

  useEffect(() => {
    // get latest benchmark
    if (user.strapiJwt && jt.toast) {
      if (!benchMod3All) { // only run this once
        axios
          .get(`${API_URL}/api/benchmarks` +
            `?filters[module][$eq]=3` +
            `&filters[orgCategory][$eq]=all`,
            authHeaders(),
          )
          .then(res => {
            const benchmarkModuleAll = res.data.data[0].attributes.benchmark
            setBenchMod3All(benchmarkModuleAll)
          })
          .catch(error => handleStrapiAPIError(error))
      }
      if (!benchMod3Cat) { // only run this once
        if (user.orgCategory) {
          axios
            .get(`${API_URL}/api/benchmarks` +
              `?filters[module][$eq]=3` +
              `&filters[orgCategory][$eq]=${user.orgCategory}`,
              authHeaders(),
            )
            .then(res => {
              const benchMod3CatData = res.data.data[0].attributes.benchmark
              // console.log('benchMod3CatData:', benchMod3CatData)
              if (benchMod3CatData.sample_size >= MIN_SAMPLE_SIZE) {
                setBenchMod3Cat(benchMod3CatData)
              } else {
                if (noCatBenchInfoHasBeenShown.current !== true) {
                  showNoCategoryBenchInfo(jt.toast)
                  noCatBenchInfoHasBeenShown.current = true
                }
                setBenchMod3Cat('Category not available')
              }
            })
            .catch(error => handleStrapiAPIError(error))
        } else setBenchMod3Cat('Category not available')
      }
      // get the latest answers for this user if they have fully completed this module
      if (benchMod3All && benchMod3Cat) {
        axios
          .get(
            `${API_URL}/api/npo-surveys` +
            `?filters[organizationID][$eq]=${user.orgID}` +
            `&filters[module][$eq]=3` +
            `&sort=createdAt:desc`,
            authHeaders(),
          )
          .then(res => {
            if (res.data.data.length === 0) return
            const answerObject = res.data.data[0].attributes?.npoAnswers
            const resultsAll = calcModResult(3, benchMod3All, answerObject)
            const resultsCat = benchMod3Cat === 'Category not available' ? null
              : calcModResult(3, benchMod3Cat, answerObject)
            const resultsBenchCat = benchMod3Cat === 'Category not available' ? null
              : calcModResult(3, benchMod3All, benchMod3Cat)
            setMod3AllResults(resultsAll)
            setMod3CatResults(resultsCat)
            setMod3BenchCatResults(resultsBenchCat)
            const resultsForFB = resultsCat ? resultsCat : resultsAll
            setMod3FeedbackTexts(getFeedbackTexts(jt.results, resultsForFB, CONSTRUCTS_M3))
          })
          .catch(error => handleStrapiAPIError(error))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jt, user, benchMod3All, benchMod3Cat])

  const chartData = mod3AllResults && benchMod3All ?
    {
      first: getChartDataMod3(
        jt.label,
        benchMod3All,
        mod3BenchCatResults,
        mod3AllResults,
        mod3CatResults,
        'summary',
      ),
    }
    : null

  const chartLabels = {
    first: jt.label?.mod3Overview || 'Module 3 Overview',
  }

  const pdfFileName = formatDate(new Date(), 'YYYY.MM.DD') + '_' +
    (jt.label?.module3ResultsFileName || 'Module_3_Results') + '.pdf'

  return (
    <div>
      <ResultsHeader
        module={'module3'}
        chartsLoaded={!!chartImages.first?.imgData}
        chartImages={chartImages}
        benchModAll={benchMod3All}
        benchModCat={benchMod3Cat}
        modAllResults={mod3AllResults}
        modCatResults={mod3CatResults}
        constructs={CONSTRUCTS_M3}
        feedbackTexts={mod3FeedbackTexts}
        pdfFileName={pdfFileName}
      />

      <Container>
        <ChartAndNav
          chartData={chartData}
          chartLabels={chartLabels}
          type={'radar'}
          setChartImages={setChartImages}
        />
        {chartData &&
          <ResultsTable
            jtLabel={jt.label || {}}
            benchModAll={benchMod3All}
            benchModCat={benchMod3Cat}
            modAllResults={mod3AllResults}
            modCatResults={mod3CatResults}
            constructs={CONSTRUCTS_M3}
          />
        }
        <ResultsText
          module={'module3'}
          constructs={CONSTRUCTS_M3}
          modAllResults={mod3AllResults}
          modCatResults={mod3CatResults}
          feedbackTexts={mod3FeedbackTexts}
        />

        <BookConsultationCTA />
      </Container>
      <Footer />
    </div>
  )
}

export default ResultsModule3
