import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { ListGroup } from 'react-bootstrap'
import { ReactComponent as EditIcon } from '../../assets/icons/edit-Icon.svg'
import { ReactComponent as RocketIcon } from '../../assets/icons/rocket-Icon.svg'
import { ReactComponent as StatisticsIcon } from '../../assets/icons/statistics-Icon.svg'
import { CONSTANTS } from '../../utils/constants'
import { gotoLink, gotoSurveyLink } from './SurveyFullUtils'
import styles from './SurveyFull.module.scss'

const OPACITY = 0.8

// Render a vertical bar item with a drive module
// Called by SurveyFull.js
// Since Module 0 was moved to the end of the array, Module 1 is now at index 0, and so forth.
const VerticalItem = ({ index, item, surveyStatus, orgSurveysDone, noEdit }) => {
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)
  const currentSurveyStatus = surveyStatus[`module${index + 1}`] // compensate for moving Module 0 to end

  const boxColor = (() => {
    switch (index) {
      case 1 :   // Module 2, since Module 0 was moved to the end
      case 2 :   // Module 3
        return CONSTANTS.colors.EOGesamtmodellGreen
      case 3 :   // Module 4
        return CONSTANTS.colors.EOGesamtmodellGray
      case 4 :   // Module 5
        return CONSTANTS.colors.EOGesamtmodellBlack
      default:
        return 'inherit'
    }
  })()

  return (
    <LinkContainer
      to={gotoLink(index, item, surveyStatus)}
      className={styles.linkContainer}
      style={{
        border: `1px solid ${boxColor}`,
        color: boxColor,
      }}
    >
      <ListGroup.Item action className={styles.verticalItem}>
        <h1 className={styles.vSurveyStatus}>
          {currentSurveyStatus || '0%'}
        </h1>
        <h6 className={styles.filledOut} style={{ opacity: OPACITY }}>
          {jt.header?.filledOut || 'Filled out'}
        </h6>
        <hr className={styles.horizontalLine}></hr>

        <h6 style={{ opacity: OPACITY }}>
          {item.module.toLocaleUpperCase()}
        </h6>
        <h6 className={styles.vMinutes}>
          {jt.label?.approx || 'Approx.'} {item.minutes} {jt.label?.minutes || 'Minutes'}
        </h6>
        <hr className={styles.horizontalLine}></hr>

        <div className={styles.vTitleContainer}>
          <h2 className={styles.vTitle}>{item.title}</h2>
          {orgSurveysDone &&
            <div className={styles.surveysFinished}>
              {jt.label?.surveysFinished || 'Surveys finished'}{': '} {orgSurveysDone[`module${index + 1}`]}
            </div>
          }
        </div>

        <div className={styles.vEditContainer}>
          {currentSurveyStatus === '100%' && (
            <div className={styles.vEditContainer2} onClick={e => gotoSurveyLink(e, item, navigate)}>
              <EditIcon className={styles.tinyEditIcon} alt='edit icon' />
              <div className={styles.tinyEditText}>
                {jt.label?.edit || 'Edit'}
              </div>
            </div>
          )}
        </div>

        <div className={styles.vMainIconContainer}>
          {(currentSurveyStatus === '100%' || noEdit) ? (
            <>
              <StatisticsIcon className={styles.statisticsIcon} />
              <div className={styles.statisticsText}>
                {jt.label?.results || 'Results'}
              </div>
            </>
          ) : (
            <>
              <RocketIcon className={styles.rocketIcon} />
              {(!currentSurveyStatus || currentSurveyStatus === '0%') ?
                (
                  <div className={styles.startNowText}>
                    {jt.label?.startNow || 'Start now'}
                  </div>
                )
                :
                (
                  <div className={styles.continueText}>
                    {jt.label?.continue || 'Continue'}
                  </div>
                )
              }
            </>
          )}
        </div>

      </ListGroup.Item>
    </LinkContainer>
  )
}

export default VerticalItem
