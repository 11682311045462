
import { useEffect, useState } from "react";
import QuestionFormRadio from '../common/QuestionFormRadio/QuestionFormRadio'
import { GEM_OR_KOP_SHORT_LENGTH, giveAnswer } from '../../utils/giveAnswer'
import styles from './Module1.module.scss'

// render the KOP section of Module 1 (Step 2) of the full survey; called by Module1.js
const Module1KOP = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}); // future object like { "KOP0": 1, "KOP1": 0, ...}

  useEffect(()=>{
    /* set up initialAnswerObj: { KOP0: null, KOP1: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar, if KOP0 has been answered 'no' ('Nein'), reduce questions from 6 to 1
      const numKopQs = previousAnswers.KOP0 === questions[0].answerArray[1] ?
        GEM_OR_KOP_SHORT_LENGTH
        : questions.length
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'KOP', numKopQs)
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module1.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[previousAnswers])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      // if first question has been answered 'no', hide the rest of the questions
      if (idx >= 1 && answerObj[questions[0].questionID] === questions[0].answerArray[1]) return null
      return <div key={question.questionID}>
        <QuestionFormRadio
          questionIndex={idx}
          question={question.question1}
          questionFormat={question.format}
          answers={question.answerArray}
          currentAnswer={answerObj[question.questionID]}
          giveAnswer={(val, questionIndex) => giveAnswer(
            'KOP', val, questionIndex, null, questions, answerObj,
            setAnswerObj, updateAnswers, updateProgress,
          )}
        />
        <hr className={styles.horizontalLine}></hr>
      </div>
    })}
  </div>)
}

export default Module1KOP;
