import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import styles from './EmailConfirmed.module.scss'

const EmailConfirmed = () => {
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>
          {jt.header?.registrationConfirmed || "Registration confirmed"}!
        </h1>
        <p>
          {jt.block?.registrationConfirmed || "Your registration has been confirmed.\nYou can now login and begin!"}
        </p>
        <div className={styles.buttons}>
          <button
            onClick={() => navigate('/login')}
            className={styles.headerBtn}
          >
            {jt.label?.goToLogin || "Go to login"}
          </button>
        </div>
      </div>

      <div className={styles.bottomBlock}>
        <h1 className={styles.boldLine}>{jt.header?.startSurveyNow || "Start Your Survey Now"}</h1>
        <p className={styles.secondLine}>
          {jt.block?.niceToSeeYouHere || "Nice to see you here"}!
          <br/>
          {jt.block?.beginYourAssessment || 'Start your survey and begin the assessment of your NPO.'}
        </p>
        <LinkContainer to={'/login'}>
          <button className={styles.bottomBtn}>{jt.label?.login || "Log in"}</button>
        </LinkContainer>
      </div>

      <Footer />
    </div>
  )
}

export default EmailConfirmed
