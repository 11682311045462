import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  jsonText: {},
}

export const jsonTextSlice = createSlice({
  name: 'jsonText',
  initialState,
  reducers: {
    addJsonText: (state, action) => {
      state.jsonText = { ...action.payload }
    },
  },
})

export const { addJsonText } =
  jsonTextSlice.actions
export default jsonTextSlice.reducer
