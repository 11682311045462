import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import classnames from 'classnames'
import { ReactComponent as DoubleArrow } from '../../../assets/icons/double-arrow.svg'
import { CONSTANTS, KVE2c, QUESTION_FORMAT_KEY } from '../../../utils/constants'
import { getToggleBtnStyle } from '../../../utils/getToggleBtnStyle'
import styles from './QuestionForm.module.scss'

/* render the most common survey questions
  called by SurveyDemo2.js, Module1AGR.js, Module1OtherQs.js, Module2MLQ.js, Module2SVL.js,
  Module3KON.js, Module3OtherQs.js, Module4KVE1.js, Module4KVE23.js, Module4KVE45.js, Module4KVI.js,
  Module5MOD.js, Module5PER1.js, Module5PER23.js
*/
const QuestionForm = ({
                        question1,
                        question2,
                        questionIndex,
                        questionFormat,
                        answerLabels,
                        currentAnswer,
                        giveAnswer
                      }) => {

  const jt = useSelector(state => state.jsonText.jsonText)
  const [offSwitchChecked, setOffSwitchChecked] = useState(false)
  const { colors, defaultLabels, offSwitch } = QUESTION_FORMAT_KEY[questionFormat]
  const { answers } = question1 === KVE2c ? QUESTION_FORMAT_KEY.D  // KVE2c has the answer values reversed
    : QUESTION_FORMAT_KEY[questionFormat]

  useEffect(() => {
    // for some reason, this was not initializing correctly in the initial state declaration, so we do it here
    setOffSwitchChecked(currentAnswer === -1)
  }, [currentAnswer])

  const answerChange = val => giveAnswer(val, questionIndex)

  // handle offSwitch toggle (the 'no answer possible' checkbox)
  const setOffSwitch = () => {
    // current value of offSwitchChecked is the previous value
    // thus the new value is either null (not selected) or -1 (no answer possible)
    offSwitchChecked ? giveAnswer(null, questionIndex) : giveAnswer(-1, questionIndex)
    setOffSwitchChecked(!offSwitchChecked)
  }

  return (
    <div>
      {(questionFormat === 'B' || questionFormat === 'D') && // dual questions types; B = no offSwitch; D = with offSwitch
        <Row className='align-items-center'>
          <Col>
            <div className={classnames(styles.question, styles.question1)}>{question1}</div>
          </Col>
          <Col>
            <div><DoubleArrow className={styles.doubleArrow} alt='double arrow' /></div>
            <ToggleButtonGroup
              type='radio'
              value={currentAnswer}
              className='mt-1'
              name={`answer-radios-Q${questionIndex}`}  // name must be shared by all radio buttons in group
              // size="sm"                              // and be distinct from other buttongroups
              onChange={answerChange}
            >
              {answers.map((answerValue, i) => {
                let backgroundColor = CONSTANTS.colors.white
                if (offSwitchChecked) {
                  backgroundColor = CONSTANTS.colors.gray
                } else if (answerValue === currentAnswer) {
                  backgroundColor = colors[i];
                }
                const borderColor = offSwitchChecked ? CONSTANTS.colors.gray : colors[i]
                return (
                  <ToggleButton
                    key={answerValue}
                    id={`tbg-btn-Q${questionIndex}-${i + 1}`} // unique id is required in a ToggleButtonGroup
                    value={answerValue}                       // this id must also be unique in the HTML document
                    data-toggle="tooltip"
                    data-placement="right"
                    title={answerLabels === 'default' ? defaultLabels[i] : answerLabels[i]}
                    // the only way to overwrite bootstrap styling
                    style={getToggleBtnStyle("center", i, borderColor, backgroundColor)}
                    disabled={offSwitchChecked}
                  />
                );
              })}
            </ToggleButtonGroup>
            {offSwitch && <div>
              <Form.Check
                type="checkbox"
                id={`Q${questionIndex}-offSwitch`}
                className={styles.offSwitchD}
                label={jt.label?.noAnswerPossible || 'No answer is possible'}
                checked={offSwitchChecked}
                onChange={setOffSwitch}
              />
            </div>}
          </Col>
          <Col>{question2 && <div className={classnames(styles.question, styles.question2)}>{question2}</div>}</Col>
        </Row>
      }
      {(questionFormat === 'A' || questionFormat === 'C') && // single questions types; A = no offSwitch; C = with offSwitch
        <div>
          <div className={styles.question}>{question1}</div>
          <div><DoubleArrow className={styles.doubleArrow} alt='double arrow' /></div>
          <ToggleButtonGroup
            type='radio'
            value={currentAnswer}
            className='mt-1'
            name={`answer-radios-Q${questionIndex}`}  // name must be shared by all radio buttons in group
            // size="sm"                              // and be distinct from other buttongroups
            onChange={answerChange}
          >
            {answers.map((answerValue, i) => {
              let backgroundColor = CONSTANTS.colors.white
              if (offSwitchChecked) {
                backgroundColor = CONSTANTS.colors.gray
              } else if (answerValue === currentAnswer) {
                backgroundColor = colors[i];
              }
              const borderColor = offSwitchChecked ? CONSTANTS.colors.gray : colors[i]
              return (
                <ToggleButton
                  key={answerValue}
                  id={`tbg-btn-Q${questionIndex}-${i + 1}`} // unique id is required in a ToggleButtonGroup
                  value={answerValue}                       // this id must also be unique in the HTML document
                  data-toggle="tooltip"
                  data-placement="right"
                  title={answerLabels === 'default' ? defaultLabels[i] : answerLabels[i]}
                  // the only way to overwrite bootstrap styling
                  style={getToggleBtnStyle("center", i, borderColor, backgroundColor)}
                  disabled={offSwitchChecked}
                />
              );
            })}
          </ToggleButtonGroup>
          {offSwitch && <div>
            <Form.Check
              type="checkbox"
              id={`Q${questionIndex}-offSwitch`}
              className={styles.offSwitchC}
              label={jt.label?.noAnswerPossible || 'No answer is possible'}
              checked={offSwitchChecked}
              onChange={setOffSwitch}
            />
          </div>}
        </div>
      }
    </div>
  );
};

export default QuestionForm;
