import React from 'react'
import { Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer'
import { formatDate } from '../../../utils/formatDate'
import { pdfStyles } from './pdfStyles'
import { LOGO_URL } from '../../../utils/constants'

const styles = StyleSheet.create({
  date: {
    fontSize: 12,
  },
  header: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    height: 50,
    paddingHorizontal: 30,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  benchContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  brand: {
    fontSize: 16,
    fontWeight: 'semibold',
    marginBottom: 0,
  },
  bench: {
    fontSize: 8,
    marginBottom: 2,
  },
  benchLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  line: pdfStyles.line,
  logo: {
    // padding: 2,
    // backgroundColor: 'rgba(0, 0, 0, .05)',
    marginRight: 10,
    height: 40,
    width: 40,
  },
})

const PdfHeader = ({ jtLabel }) => (
  <View fixed style={styles.header}>
    <View style={styles.headerContainer}>
      <View style={styles.leftContainer}>
        <Image src={LOGO_URL} style={styles.logo} />
        <View style={styles.benchContainer}>
          <Text style={styles.brand}>
            {jtLabel.brandText || 'NPO Benchmark'}
          </Text>
          <View style={styles.bench}>
            <Link src={jtLabel.benchmarkWebsite || 'www.npo-benchmark.ch'} style={styles.benchLink}>
              {jtLabel.benchmarkWebsite || 'www.npo-benchmark.ch'}
            </Link>
            <Text>
              {jtLabel.benchmarkEmail || 'info@npo-benchmark.ch'}
            </Text>
          </View>
        </View>
      </View>
      <Text style={styles.date}>{formatDate(new Date())}</Text>
    </View>
    <View style={styles.line} />
  </View>
)


export default PdfHeader
