import { getDataForChart } from '../../../utils/reactChartConfig'
import { CONSTRUCTS } from '../../../utils/constants'
import { onlyPreserveValidData } from './onlyPreserveValidData'

/**
 * called by ResultsModule1.js
 * @param jtLabels, object, 'label' portion of the jsonText object from Redux
 * @param benchMod1All, object, benchmark of all organization categories
 * @param benchMod1Cat, object, benchmark of user's organization category
 * @param mod1AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod1CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param type, string, either 'summary' (overall result) or the section name ('activation' or 'future orientation')
 * @return object, the chart data for Module 1
 */
export const getChartDataMod1 = (jtLabels, benchMod1All, benchMod1Cat, mod1AllResults, mod1CatResults, type) => {
  // console.log('benchMod1Cat:', benchMod1Cat)
  let chartLabels, benchAllData, benchCatData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION.name] || 'Entrepreneurial Orientation'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.ACTIVATION.name] || 'Activation'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.FUTURE_ORIENTATION.name] || 'Future Orientation'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod1All.UFK.stdDev === 0 ? 100 : 50,
        benchMod1All.AKT.stdDev === 0 ? 100 : 50,
        benchMod1All.ZKO.stdDev === 0 ? 100 : 50,
      ]
      benchCatData = [
        benchMod1Cat?.UFK.percentile,
        benchMod1Cat?.AKT.percentile,
        benchMod1Cat?.ZKO.percentile,
      ]
      allResultsData = [
        mod1AllResults.UFK.percentile,
        mod1AllResults.AKT.percentile,
        mod1AllResults.ZKO.percentile,
      ]
      catResultsData = [
        mod1CatResults?.UFK.percentile,
        mod1CatResults?.AKT.percentile,
        mod1CatResults?.ZKO.percentile,
      ]
      break
    case CONSTRUCTS.ACTIVATION.name:
      chartLabels = [
        `${jtLabels[CONSTRUCTS.AUTONOMY.name] || 'Autonomy'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.COMMUNITY_MOBILIZATION.name] || 'Community Mobilization'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.COOPERATIVE_MOBILIZATION.name] || 'Cooperative Mobilization'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.AGGRESSIVENESS.name] || 'Aggressiveness'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod1All.AUT.stdDev === 0 ? 100 : 50,
        benchMod1All.GEM.stdDev === 0 ? 100 : 50,
        benchMod1All.KOP.stdDev === 0 ? 100 : 50,
        benchMod1All.AGR.stdDev === 0 ? 100 : 50,
      ]
      benchCatData = [
        benchMod1Cat?.AUT.percentile,
        benchMod1Cat?.GEM.percentile,
        benchMod1Cat?.KOP.percentile,
        benchMod1Cat?.AGR.percentile,
      ]
      allResultsData = [
        mod1AllResults.AUT.percentile,
        mod1AllResults.GEM.percentile,
        mod1AllResults.KOP.percentile,
        mod1AllResults.AGR.percentile,
      ]
      catResultsData = [
        mod1CatResults?.AUT.percentile,
        mod1CatResults?.GEM.percentile,
        mod1CatResults?.KOP.percentile,
        mod1CatResults?.AGR.percentile,
      ]
      break
    case CONSTRUCTS.FUTURE_ORIENTATION.name:
      chartLabels = [
        `${jtLabels[CONSTRUCTS.PROACTIVITY.name] || 'Proactivity'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.INNOVATION.name] || 'Innovation'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.RISK_TAKING.name] || 'Risk-taking'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod1All.PRO.stdDev === 0 ? 100 : 50,
        benchMod1All.INN.stdDev === 0 ? 100 : 50,
        benchMod1All.RSK.stdDev === 0 ? 100 : 50,
      ]
      benchCatData = [
        benchMod1Cat?.PRO.percentile,
        benchMod1Cat?.INN.percentile,
        benchMod1Cat?.RSK.percentile,
      ]
      allResultsData = [
        mod1AllResults.PRO.percentile,
        mod1AllResults.INN.percentile,
        mod1AllResults.RSK.percentile,
      ]
      catResultsData = [
        mod1CatResults?.PRO.percentile,
        mod1CatResults?.INN.percentile,
        mod1CatResults?.RSK.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }

  // if the category benchmark or category results are undefined, null or 0,
  // set the data to null so that they will not be included in chart
  benchCatData = onlyPreserveValidData(benchCatData)
  // if there is no benchmark for this category, the results based on this are irrelevant, also.
  catResultsData = benchCatData ? onlyPreserveValidData(catResultsData) : null
  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatData, allResultsData, catResultsData)
}
