import { Image } from 'react-bootstrap'
import BVMLogo from '../../../assets/images/Logo-BVM.png'
import kultiviererLogo from '../../../assets/images/Logo-Kultivierer.png'
import VMILogo from '../../../assets/images/Logo-VMI.png'
import { BVM_URL, KULTIVIERER_URL, LOGO_URL, VMI_URL } from '../../../utils/constants'
import styles from './LogosHeader.module.scss'

/**
 * render the Benchmark logo, Benchmark brand text + 'ein Projekt von:' +
 * VMI, Kultivierer and B'vm logos
 */
const LogosHeader = ({ jtLabel }) => {
  const screenSize = window.innerWidth > 830 ? 'lg' : 'sm'
  return (
    <div>
      <div className={styles.logosHeader}>
        <Image
          className={styles.benchLogo}
          src={LOGO_URL}
          roundedCircle
          width={screenSize === 'sm' ? 40 : 60}
          height={screenSize === 'sm' ? 40 : 60}
          alt={'Benchmark logo'}
        />
        <div className={styles.brandText}>
          {jtLabel.brandText || 'NPO Benchmark'}
        </div>
        <div className={styles.projectText}>
          {' - '}
          {jtLabel.aJointVentureOf || 'a joint venture of'}
          {': '}
        </div>
        <Image
          className={styles.linkedLogo}
          src={VMILogo}
          // roundedCircle
          width={screenSize === 'sm' ? 45 : 70}
          height={screenSize === 'sm' ? 35 : 50}
          alt={'VMI logo'}
          onClick={() => window.open(VMI_URL)}
        />
        <Image
          className={styles.linkedLogo}
          src={kultiviererLogo}
          // roundedCircle
          width={screenSize === 'sm' ? 110 : 150}
          height={screenSize === 'sm' ? 35 : 45}
          alt={'Kultivierer logo'}
          onClick={() => window.open(KULTIVIERER_URL)}
        />
        <Image
          className={styles.linkedLogo}
          src={BVMLogo}
          // roundedCircle
          width={screenSize === 'sm' ? 40 : 60}
          height={screenSize === 'sm' ? 30 : 45}
          alt={'BVM logo'}
          onClick={() => window.open(BVM_URL)}
        />
      </div>
      <hr className={styles.horizontalLine}></hr>
    </div>
  )
}

export default LogosHeader
