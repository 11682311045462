import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import SurveyDemo2 from './SurveyDemo2'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { MAX_DEMO_SURVEY_QUESTIONS_PER_PAGE } from '../../utils/constants'
import styles from './SurveyDemo.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// render demo survey
const SurveyDemo = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const [questions, setQuestions] = useState(null)
  const [progress, setProgress] = useState(0) // in %

  useEffect(() => {
    axios
      .get(`${API_URL}/api/survey-questions?filters[demo][$eq]=true`)
      .then(res => {
        const demoQuestions = res.data.data.map(item => {
          return {
            questionID: item.attributes.questionID,
            question1: item.attributes.question1,
            question2: item.attributes.question2,
            format: item.attributes.format,
          }
        })
        setQuestions(demoQuestions)
      })
      .catch(error => handleStrapiAPIError(error))
  }, [])

  const updateProgress = (currentAnswerObj) => {
    let answerCount = 0
    for (const answer in currentAnswerObj) {
      if (currentAnswerObj[answer]) answerCount++
    }
    setProgress(Math.round((answerCount / questions.length) * 100))
  }

  return (
    <Container>
      <h1 className="mt-5">{jt.header?.demoSurvey || 'Demo survey'}</h1>
      <h3 className={styles.subtitle}>
        {jt.block?.takeDemoSurvey || 'Take the demo survey now!'}
      </h3>
      <br/><br/>
      {questions && (
        <SurveyDemo2
          module='demo'
          questions={questions}
          maxQuestionsPerPage={MAX_DEMO_SURVEY_QUESTIONS_PER_PAGE}
          progress={progress}
          updateProgress={updateProgress}
          resultsPage="/demo/results"
        />
      )}
      <LinkContainer to={'/login'}>
        <Button variant="light" size="sm">
          {jt.label?.loginForFullVersion || 'Login for the full version!'}
        </Button>
      </LinkContainer>
      <br/><br/><br/><br/>
    </Container>
  )
}

export default SurveyDemo
