import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import { addAnswers, changeAnswers } from '../../features/answers/answerSlice'
import QuestionForm from '../common/QuestionForm/QuestionForm'
import ProgressBarContainer from '../common/ProgressBarContainer/ProgressBarContainer'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import styles from './SurveyDemo2.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// called by SurveyDemo.js
const SurveyDemo2 = ({
                       module,
                       questions,
                       maxQuestionsPerPage,
                       progress,
                       updateProgress,
                       resultsPage,
                     }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)
  const prevReduxAnswers = useSelector(state => state.answers.answers) //state.<slice>.<name>
  const user = useSelector(state => state.user.user)

  const [answerObj, setAnswerObj] = useState({})
  const [currentQuestions, setCurrentQuestions] = useState([]) // questions of current page
  const [questionOffset, setQuestionOffset] = useState(0) // first question of current page
  const [notLoggedInMsg, setNotLoggedInMsg] = useState(false)

  useEffect(() => {
    /* set up initialAnswerObj: { PRO1: null, PRO2: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* check if localStorage exists and if it contains any of the previous answers
       if so, update initialAnswerObj */
    const prevLocalStoreAnswers = JSON.parse(localStorage.getItem(module))
    if (prevLocalStoreAnswers) {
      for (const questKey in initialAnswerObj) {
        if (prevLocalStoreAnswers[questKey]) initialAnswerObj[questKey] = prevLocalStoreAnswers[questKey]
      }
    }
    setAnswerObj(initialAnswerObj)
    updateProgress(initialAnswerObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const endOffset = questionOffset + maxQuestionsPerPage
    setCurrentQuestions((prev) => questions.slice(questionOffset, endOffset))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionOffset])

  const totalPages = Math.ceil(questions.length / maxQuestionsPerPage)

  const giveAnswer = (val, questionIndex) => {
    const newAnswerObj = {...answerObj} // create new object
    const questionID = questions[questionIndex].questionID
    newAnswerObj[questionID] = val
    setAnswerObj((prev) => newAnswerObj)
    updateProgress(newAnswerObj)
  }

  const saveAnswers = () => {
    // console.log("saveAnswers called")
    // first save to localStorage:
    localStorage.setItem(module, JSON.stringify(answerObj))
    // then save to Redux:
    const answerPayload = {
      module,
      answers: answerObj,
    }
    let answersExist = false
    prevReduxAnswers.forEach(answerCollection => {
      if (answerCollection.module === module) {
        answersExist = true
      }
    })
    answersExist
      ? dispatch(changeAnswers(answerPayload))
      : dispatch(addAnswers(answerPayload))
    // let user know saving was a success
    toast.success(jt.toast?.answerSaveSuccess || 'Answers successfully saved')
    // and finally save to the backend DB to calculate future benchmark:
    axios
      .post(`${API_URL}/api/answer-sessions`, {
        data: {
          module,
          answers: answerObj,
          userID: user.userID || 0, // if user is not logged in, userID = 0.
        },
      })
      .then(() => {
        user.userID ? setNotLoggedInMsg(false) : setNotLoggedInMsg(true)
        if (progress === 100) navigate(resultsPage)
      })
      .catch((error) => handleStrapiAPIError(error))
  }

  const handlePageClick = ({ selected }) => {
    const newOffset = (selected * maxQuestionsPerPage) % questions.length
    setQuestionOffset((prev) => newOffset)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const saveAnsBtnText = progress < 100 ?   // since progress is a prop variable, this code will run on each update
    jt.label?.saveAnswers || 'Save answers'
    : jt.label?.saveAndFinish || 'Save and finish'

  return (
    <div>
      {/* Avoid errors where answerArray has not yet been filled out
          or where currentQuestions has not yet updated and temporarily attempts
          to render more questions than there are answers for  */}
      {Object.keys(answerObj).length > questionOffset + currentQuestions.length - 1 &&
        currentQuestions.map((question, idx) => {
          return (
            <div key={questionOffset + idx}>
              <QuestionForm
                question1={question.question1}
                question2={question.question2}
                questionIndex={questionOffset + idx}
                questionFormat={question.format}
                answerLabels={question.answerLabels || 'default'}
                currentAnswer={answerObj[question.questionID]}
                giveAnswer={giveAnswer}
              />
              <hr className={styles.horizontalLine}></hr>
            </div>
          )
        })}

      <ReactPaginate
        // breakLabel="..."
        nextLabel={jt.label?.nextPage || "Next >"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel={jt.label?.prevPage || "Back >"}
        renderOnZeroPageCount={null}
        containerClassName={styles.paginationBtns}
        activeClassName={styles.paginationActiveBtn}
      />

      <ProgressBarContainer
        btnLabel={saveAnsBtnText}
        progress={progress}
        handleButtonClick={saveAnswers}
      />
      {notLoggedInMsg && (
        <div className={styles.notLoggedInMsg}>
          {jt.block?.loginToSaveAnswers || 'Please log in to save your answers in the database for later!'}
        </div>
      )}
    </div>
  )
}

export default SurveyDemo2
