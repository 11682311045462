import { toast } from 'react-toastify'

export const handleStrapiAPIError = (err) => {
  console.error('err:', err)
  toast.error(
    `${err.response?.status} Error: ${
      err.response?.data?.error?.message || err.error?.message || err
    }`,
    { autoClose: 8000 }
  )
}
