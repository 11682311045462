import { getDataForChart } from '../../../utils/reactChartConfig'
import { CONSTRUCTS } from '../../../utils/constants'
import { onlyPreserveValidData } from './onlyPreserveValidData'

/**
 * called by ResultsModule2.js
 * @param jtLabels, object, 'label' portion of the jsonText object from Redux
 * @param benchMod2All, object, benchmark of all organization categories
 * @param benchMod2Cat, object, benchmark of user's organization category
 * @param mod2AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod2CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param type, string, either 'summary' (overall result) or the section name (not yet used)
 * @return object, the chart data for Module 2
 */
export const getChartDataMod2 = (jtLabels, benchMod2All, benchMod2Cat, mod2AllResults, mod2CatResults, type) => {
  let chartLabels, benchAllData, benchCatData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.VISIONARY_LEADERSHIP.name] || 'Visionary Leadership'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.SERVANT_LEADERSHIP.name] || 'Servant Leadership'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod2All.MLQ.stdDev === 0 ? 100 : 50,
        benchMod2All.SVL.stdDev === 0 ? 100 : 50,
      ]
      benchCatData = [
        benchMod2Cat?.MLQ.percentile,
        benchMod2Cat?.SVL.percentile,
      ]
      allResultsData = [
        mod2AllResults.MLQ.percentile,
        mod2AllResults.SVL.percentile,
      ]
      catResultsData = [
        mod2CatResults?.MLQ.percentile,
        mod2CatResults?.SVL.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }
  // if the category benchmark or category results are undefined, null or 0,
  // set the data to null so that they will not be included in chart
  benchCatData = onlyPreserveValidData(benchCatData)
  // if there is no benchmark for this category, the results based on this are irrelevant, also.
  catResultsData = benchCatData ? onlyPreserveValidData(catResultsData) : null
  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatData, allResultsData, catResultsData)
}
