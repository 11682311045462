import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Container, Image, ListGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as EditIcon } from '../../assets/icons/edit-Icon.svg'
import RightChevron from '../../assets/images/right-chevron.png'
import model from '../../assets/images/EO_Gesamtmodell_Grafik.png'
import { addOrgToUser } from '../../features/user/userSlice'
import Footer from '../Footer/Footer'
import HorizontalItem from './HorizontalItem'
import VerticalItem from './VerticalItem'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { showMissingOrgError, showCategoryNotSetWarning } from '../../utils/getMiscToastMsg'
import { CONSTANTS, REDIRECTION_TIMEOUT } from '../../utils/constants'
import { getNumSurveysFilledOut } from '../../utils/getNumSurveysFilledOut'
import { closeSurvey } from '../../utils/closeSurvey'
import { getDateOfNextAllowedSurvey } from '../../utils/getSurveyDate'
import styles from './SurveyFull.module.scss'

const API_URL = process.env.REACT_APP_API_URL

const benchmarkModules = [
  {
    title: 'Entrepreneurial Culture',
    module: 'Main Module',  // Module 1
    subtitle: '',
    surveyLink: '/survey/module1',
    resultsLink: '/results',
    minutes: '15',
  },
  {
    title: 'Leadership',
    module: 'Driver Module 1',  // Module 2
    subtitle: '',
    surveyLink: '/survey/module2',
    resultsLink: '/results/module2',
    minutes: '15',
  },
  {
    title: 'Organization and Development',
    module: 'Driver Module 2',  // Module 3
    subtitle: '',
    surveyLink: '/survey/module3',
    resultsLink: '/results/module3',
    minutes: '15',
  },
  {
    title: 'Context and Development',
    module: 'Driver Module 3',  // Module 4
    subtitle: '',
    surveyLink: '/survey/module4',
    resultsLink: '/results/module4',
    minutes: '15',
  },
  {
    title: 'Performance',
    module: 'Driver Module 4',  // Module 5
    subtitle: '',
    surveyLink: '/survey/module5',
    resultsLink: '/results/module5',
    minutes: '15',
  },
  {
    title: 'Basic information about the NPO',
    module: 'Basic Data',  // Module 0
    subtitle: 'Only the author has access to edit this information.',
    surveyLink: '/survey/module0',
    resultsLink: '/survey/module0',
    minutes: '5',
  },
]

// render the full survey landing page
const SurveyFull = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  // console.log('user:', user)
  const surveyStatus = user.surveyStatus
  const [disableModule0, setDisableModule0] = useState(true)
  const [allModulesComplete, setAllModulesComplete] = useState(false)
  const [orgSurveysDone, setOrgSurveysDone] = useState(null)
  const [viewImage, setViewImage] = useState(false)
  const [surveyDates, setSurveyDates] = useState({
    lastSurvey: '', nextSurvey: '', // nextSurvey will only be set if this date lies in the future
  })
  const categoryNotSetWarningHasBeenShown = useRef(null)
  const orgMissingErrorHasBeenShown = useRef(null)

  benchmarkModules.forEach((mod, idx) => {
    // compensate for moving module 0 to the end of the array of modules
    const trueIndex = idx < 5 ? idx + 1 : 0
    if (jt.header) {
      mod.title = jt.header?.[`module${trueIndex}Title`] || 'Module Title'
      mod.module = jt.header?.[`module${trueIndex}`] || 'Module Name'
      mod.subtitle = trueIndex === 0 ?  // only Module 0 has a subtitle
        (jt.header?.[`module${trueIndex}Subtitle`] || 'Module Subtitle')
        : ''
    }
  })

  const module0Index = 5
  const module1Index = 0

  useEffect(() => {
    const getOrgDataAndUpdateStatus = async () => {
      // console.log('user:', user)
      let isCreator = false
      if (user.orgID) {
        const reduxUpdateNeeded = !user.isOrgCreator || !user.orgCategory
        await axios
          .get(
            `${API_URL}/api/organizations/${user.orgID}`,
            authHeaders(),
          )
          .then(res => {
            const data = res.data.data
            if (!data) handleMissingOrg()
            const orgData = data.attributes
            // console.log('orgData:', orgData)
            if (orgData.userID === user.userID) {
              isCreator = true
              setDisableModule0(false)
            }
            if (reduxUpdateNeeded) {
              dispatch(
                addOrgToUser({
                  orgID: data.id,
                  orgName: orgData.name,
                  isOrgCreator: isCreator,
                  orgCategory: orgData.orgCategory,
                }),
              )
            }
            if (!orgData.orgCategory && !categoryNotSetWarningHasBeenShown.current) {
              showCategoryNotSetWarning(jt.toast || {})
              categoryNotSetWarningHasBeenShown.current = true
            }
            if (orgData.lastCompletedSurvey) handleOrgHasCompletedSurvey(orgData.lastCompletedSurvey)
          })
          .catch(error => handleStrapiAPIError(error))

      } else if (user.userID) handleMissingOrg()

      const module0Status = isCreator ?
        surveyStatus?.module0 && surveyStatus.module0 === '100%'
        : true
      if (
        module0Status &&
        surveyStatus?.module1 === '100%' &&
        surveyStatus.module2 === '100%' &&
        surveyStatus.module3 === '100%' &&
        surveyStatus.module4 === '100%' &&
        surveyStatus.module5 === '100%'
      ) {
        setAllModulesComplete(true)
      } else setAllModulesComplete(false)

      if (isCreator) {
        const surveysFilledOut = await getNumSurveysFilledOut(user.orgID)
        // console.log('surveysFilledOut:', surveysFilledOut)
        setOrgSurveysDone(surveysFilledOut)
      }
    }
    getOrgDataAndUpdateStatus().then()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userID])

  const handleMissingOrg = () => {
    if (orgMissingErrorHasBeenShown.current) return
    showMissingOrgError(jt.toast || {})
    orgMissingErrorHasBeenShown.current = true
    setTimeout(() => navigate('/organization-select'), REDIRECTION_TIMEOUT)
  }

  const handleOrgHasCompletedSurvey = (lastSurveyDate) => {
    const nextDate = getDateOfNextAllowedSurvey(lastSurveyDate) // if next date is in the future, it is in format 'DD.MM.YYYY'; else ''
    // console.log('nextDate:', nextDate)
    if (nextDate) {
      setSurveyDates({ lastSurvey: lastSurveyDate, nextSurvey: nextDate })
      benchmarkModules.forEach(mod => mod.surveyLink = mod.resultsLink)
    }
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>{jt.header?.takeFullSurvey || 'Take the full survey'}!</h1>
        <p>
          {jt.block?.surveyAbstract || 'This survey was prepared by Freiburg University in Switzerland.'}
        </p>
      </div>

      <Container className={styles.mainContent}>
        <ListGroup className={styles.list}>
          <div className={styles.orgLabel}>{jt.label?.yourOrganization || 'Your organization'}</div>
          <div className={styles.orgName}>{user.orgName}</div>
          {!disableModule0 && (
            <div
              className={styles.editDiv}
              onClick={() => navigate('/organization-edit')}
            >
              <EditIcon
                className={styles.smallEditIcon}
                alt='edit icon'
                style={{ fill: CONSTANTS.colors.grayText }}
              />
              <div className={styles.smallEditText}>{jt.label?.edit || 'edit'}</div>
            </div>
          )}
          <hr className={styles.hr} />

          {surveyDates.nextSurvey &&
            <div className={styles.nextDateContainer}>
              <p className={styles.infoText}>
                <span className={styles.attention}>
                  {jt.label?.attention || 'Attention'}!
                </span>
                <br />
                {jt.block?.lastSurveyDate || 'The survey can only be taken yearly. The date of your organization\'s last survey was'}
                {': '}
                <span className={styles.fwNormal}>{surveyDates.lastSurvey}</span>
                .
                <br />
                {jt.block?.nextSurveyDate || 'The earliest date for your organization\'s next survey is'}
                {': '}
                <span className={styles.fwBold}>{surveyDates.nextSurvey}</span>
                .
              </p>
            </div>
          }

          <div className={styles.infoContainer}>
            <p className={styles.infoText}>
              {jt.block?.overallSurveyInfo || 'The full survey consists of one main module and 4 auxiliary modules.  Please fill them all out to access your full results.'}
            </p>
            <button className={styles.secondBtn} onClick={() => setViewImage(!viewImage)}>
              {viewImage ? jt.label?.hideModelAgain || 'Hide the model again'
                : jt.label?.viewFullModel || 'View the full model'
              }
            </button>
          </div>
          {viewImage && <Image src={model} className={styles.image} alt='Modell overview' />}

          {surveyStatus?.module0 !== undefined && (
            <div>
              <HorizontalItem
                index={module1Index}
                item={benchmarkModules[module1Index]}
                surveyStatus={surveyStatus}
                orgSurveysDone={orgSurveysDone}
                noEdit={!!surveyDates.nextSurvey}
              />
              <Row className={styles.verticalSection}>
                {benchmarkModules.map((item, index) => {
                  if (index === module1Index || index === module0Index) return null  // skip modules 1 and 0
                  else return (
                    <VerticalItem index={index} item={item} surveyStatus={surveyStatus}
                                  orgSurveysDone={orgSurveysDone}
                                  noEdit={!!surveyDates.nextSurvey}
                                  key={`${item.title}-${index}`} />
                  )
                })}
              </Row>
              <div className={styles.infoContainer}>
                <p className={styles.infoText}>
                  {jt.block?.afterFillingOutAllModules || 'After filling out all modules, you will receive an assessment of relevant correlations for your organization.'}
                </p>
              </div>
              {!disableModule0 && (
                <div>
                  <HorizontalItem
                    index={module0Index}
                    item={benchmarkModules[module0Index]}
                    surveyStatus={surveyStatus}
                  />
                  <div className={styles.infoContainer}>
                    <p className={styles.infoText}>
                      {jt.block?.periodicalNewAssessments || 'Periodically, new assessment updates with anonymous data are made available to registered organizations.'}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </ListGroup>

        {!disableModule0 && allModulesComplete ? (
          <div className={styles.bottomContainer}>
            <h2 className={styles.closeSurveyTitle}>
              {jt.header?.closeSurveyAndCalculateResults || 'Want to close the survey and calculate your results?'}
            </h2>
            <p className={styles.infoText}>
              {jt.block?.closeSurveyAndCalculateResultsInfo || 'This will generate the results for your NPO.\nHowever, since this operation will close the survey for a year, we strongly recommend that you wait until enough coworkers have filled out their surveys.'}
            </p>
            <button className={styles.closeSurveyBtn}
                    onClick={() => closeSurvey(
                      {
                        orgID: user.orgID,
                        orgName: user.orgName,
                        orgCategory: user.orgCategory,
                      },
                      navigate)}
            >
              {jt.label?.closeSurvey || 'Close survey'}
              <img
                src={RightChevron}
                alt='right chevron'
                className={styles.rightChevron}
              />
            </button>
          </div>
        ) : <br/>}

      </Container>
      <Footer />
    </div>
  )
}

export default SurveyFull
