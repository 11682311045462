import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg'
import SideTgglBtnForm from "../common/SideTgglBtnForm/SideTgglBtnForm";
import { countSubQAns } from '../../utils/countSubQAns'
import { giveAnswer } from '../../utils/giveAnswer'
import styles from "./Module5.module.scss"

// render the PER5af section of Module 5 (Step 6) of the full survey; called by Module5.js
const Module5PER5af = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {
  const jt = useSelector(state => state.jsonText.jsonText)

  /* future object like
    {
      "PER5a": {
        Wichtigkeit: 1,
        Zielerfolg: 5,
      },
      "PER5b": {
        Wichtigkeit: 1,
        Zielerfolg: 5,
      },
      ...
    }
  */
  const [answerObj, setAnswerObj] = useState({})

  // get array of subQuestions from the first question of this section
  const subQuestions = [questions[0].subQuestion1, questions[0].subQuestion2]

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    /* initially fill in answerObj with null values for each subQuestion like:
      for each question such as:
      {
        "PER5a": {
          Wichtigkeit: null,
          Zielerfolg: null,
        },
        ...
      }
    */
    const initialAnswerObj = {}
    questions.forEach(question => {
      initialAnswerObj[question.questionID] = {}
      subQuestions.forEach( subQ => initialAnswerObj[question.questionID][subQ] = null)
    })

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) {
          for (const subQ in previousAnswers[questKey]) {
            initialAnswerObj[questKey][subQ] = previousAnswers[questKey][subQ]
          }
        }
      }
      // also update progress bar
      updateProgress(countSubQAns(initialAnswerObj), 'PER5af');
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module5.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[previousAnswers])

  return (<div>
    {Object.keys(answerObj).length &&
      <div className={styles.container}>
        <div className={styles.headerQuestion}>
          {jt.block?.headerQuestionPER5 ||
            'How important are the following goals?\nHow well have you been achieving them?'
          }:
        </div>
        <hr className={styles.horizontalLine2}></hr>
      </div>
    }
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      return <div key={question.questionID} className={styles.container}>
        <div className={styles.question}>{question.question1}</div>
        <div><RightArrow className={styles.rightArrow} alt="right arrow" /></div>
        {subQuestions.map((subQ, subIdx) => {
          return <div key={subQ}>
            <SideTgglBtnForm
              question={subQ}
              questionIndex={idx}
              subQuestionIndex={subIdx}
              questionFormat={question.format}
              answerLabels={question.answerLabels || 'default'}
              currentAnswer={answerObj[question.questionID][subQ]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'PER5af', val, questionIndex, subQ, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress
              )}
            />
          </div>
        })}
        <hr className={styles.horizontalLine2}></hr>
      </div>
    })}
  </div>)
}

export default Module5PER5af;
