
// common pdf-styling
export const pdfStyles = {
  line: {
    width: '100%',
    height: 1,
    backgroundColor: '#aaaaaa',
  },
  paragraph: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'justify',
  },
  section: {
    margin: 10,
  },
}
