import axios from 'axios'
import { authHeaders } from './authHeaders'
import { handleStrapiAPIError } from './handleStrapiAPIError'
import { getDateOfLastValidSurvey } from './getSurveyDate'

const API_URL = process.env.REACT_APP_API_URL

/**
 * saves the user's answers in the database
 * If the user has a previous answer session, it is updated.
 * Otherwise, a new answer session is created.
 * @param user, object: { userID, orgID, orgCategory }
 * @param module, string such as 'Module1'
 * @param answers, object with questionIDs and matching answer values
 * @return nothing is returned
 */
export const saveAnswersInDB = (user, module, answers) => {
  axios
    .get(
      `${API_URL}/api/answer-sessions` +
      `?filters[userID][$eq]=${user.userID}` +
      `&filters[module][$eq]=${module}` +
      `&filters[updatedAt][$gte]=${getDateOfLastValidSurvey()}` +
      `&sort=updatedAt:desc`,
      authHeaders(),
    )
    .then(res => {
      // console.log('res.data.data:', res.data.data);
      const lastSession = res.data.data[0]
      // console.log('lastSession:', lastSession);
      if (lastSession) {
        axios
          .put(
            `${API_URL}/api/answer-sessions/${lastSession.id}`,
            { data: { answers } },
            authHeaders(),
          )
          .then()
      } else {
        axios
          .post(
            `${API_URL}/api/answer-sessions`,
            {
              data: {
                module,
                answers,
                userID: user.userID,
                organizationID: user.orgID,
                orgCategory: user.orgCategory,
              },
            },
            authHeaders(),
          )
          .then()
      }
    })
    .catch(error => handleStrapiAPIError(error))

}
