import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Image, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { updateLanguage } from '../../features/user/userSlice'
import HeaderProfile from '../HeaderProfile/HeaderProfile'
import { DEFAULT_LANGUAGE, LANGUAGES, LOGO_URL } from '../../utils/constants'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { getCurrentLanguage } from '../../utils/getCurrentLanguage'
import styles from './Header.module.scss'

const API_URL = process.env.REACT_APP_API_URL

const Header = () => {
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const [currentLang, setCurrentLang] = useState(DEFAULT_LANGUAGE)
  let loggedIn = user.strapiJwt ? true : false

  useEffect(() => {
    setCurrentLang(getCurrentLanguage(user, dispatch))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.language])

  const onLangSelect = (lang) => {
    dispatch(
      updateLanguage({ language: lang }),
    )
    localStorage.setItem('language', lang)
    // console.log('new language:', lang)
    if (loggedIn) {
      axios
        .put(
          `${API_URL}/api/users/${user.userID}`,
          { language: lang },
          authHeaders(),
        )
        .then()
        .catch(error => handleStrapiAPIError(error))
    }
  }

  return (
    <Navbar bg='dark' variant='dark' className={styles.navbar}>
      <LinkContainer to={loggedIn ? '/welcome-back' : '/'}>
        <Navbar.Brand>
          <Image className={styles.logoBrandMark} src={LOGO_URL} roundedCircle width={42} height={42} />
        </Navbar.Brand>
      </LinkContainer>
      <Nav className='me-auto'>
        <LinkContainer to={loggedIn ? '/welcome-back' : '/'}>
          <Nav.Link className={styles.navLabel}>{jt.label?.brandText || 'NPO Benchmark'}</Nav.Link>
        </LinkContainer>
      </Nav>

      <Nav align='end'>
        <LinkContainer to={'/demo'}>
          <Nav.Link className={styles.navLabel}>{jt.header?.demoSurvey || 'Demo Survey'}</Nav.Link>
        </LinkContainer>
      </Nav>
      <Nav align='end'>
        <LinkContainer to={loggedIn ? '/survey' : '/register'}>
          <Nav.Link className={styles.navLabel}>{jt.label?.fullSurvey || 'Full Survey'}</Nav.Link>
        </LinkContainer>
      </Nav>
      <Nav align='end'>
        <LinkContainer to={loggedIn ? '/results' : '/register'}>
          <Nav.Link className={styles.navLabel}>{jt.label?.results || 'Results'}</Nav.Link>
        </LinkContainer>
      </Nav>
      <Nav align='end'>
        <LinkContainer to={'/about'}>
          <Nav.Link className={styles.navLabel}>{jt.label?.aboutUs || 'About Us'}</Nav.Link>
        </LinkContainer>
      </Nav>

      <HeaderProfile />

      <NavDropdown
        menuVariant='dark'
        id='lang-dropdown'
        align='end'
        // drop='start'
        title={LANGUAGES[user.language]?.flag || LANGUAGES[DEFAULT_LANGUAGE].flag}
        className={styles.langDropdown}
      >
        {Object.keys(LANGUAGES).map(lang => {
          if (lang === currentLang) return null  // omit the current language from the list
          return <NavDropdown.Item
            key={lang}
            onClick={() => onLangSelect(lang)}
            className={styles.languageItem}
          >
            <span className={styles.languageName}>{LANGUAGES[lang].name}</span>
            {LANGUAGES[lang].flag}
          </NavDropdown.Item>
        })}
      </NavDropdown>

    </Navbar>
  )
}

export default Header
