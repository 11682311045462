import { CONSTANTS } from './constants'

export const RADAR_OPTIONS = {
  responsive: true,
  maintainAspectRatio: true,
  scale: {
    min: 0,
    max: 100,
    ticks: {
      stepSize: 25,
    },
    font: {
      family: 'Barlow, Helvetica, sans-serif',
      size: 10,
    },
  },
  scales: {
    r: {
      pointLabels: {
        font: {
          family: 'Barlow, Helvetica, sans-serif',
          size: 12,
          // style: 'italic'
        }
      }
    }
  },
  plugins: {
    title: {
      display: true,
      text: 'Title',
      font: {
        family: 'Barlow, Helvetica, sans-serif',
        size: 18,
      },
      color: '#000', // Optional: change the color of the title
      padding: {
        top: 10,
        bottom: 10,
      },
    },
    legend: {
      labels: {
        // This font property applies to legend labels
        font: {
          family: 'Barlow, Helvetica, sans-serif',
          size: 18,
        },
      },
    },
  },
}

export const barLegendMargin = {
  beforeInit(chart) {
    // console.log('chart.legend.fit:', chart.legend.fit)
    const fitValue = chart.legend.fit
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)()
      return this.height += 30 // increases margin between legend and chart
    }
  },
}
export const BAR_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  scale: {
    min: 0,
    max: 100,
    ticks: {
      stepSize: 25,
    },
    font: {
      family: 'Barlow, Helvetica, sans-serif',
      size: 16,
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Title',
      font: {
        family: 'Barlow, Helvetica, sans-serif',
        size: 18,
      },
      color: '#000', // Optional: change the color of the title
      padding: {
        top: 10,
        bottom: 10,
      },
    },
    legend: {
      labels: {
        // This font property applies to legend labels
        font: {
          family: 'Barlow, Helvetica, sans-serif',
          size: 16,
        },
        // padding: 20, // increases margin above each line of the legend
      },
    },
  },
}

export const BORDER_THICKNESS = 2
const rgbaOpacity = ', 0.1)'
export const getDataForChart = (jtLabels, chartLabels, benchAllData, benchCatData, allResultsData, catResultsData) => {
  const datasets = []
  if (benchAllData) datasets.push(
    {
      label: jtLabels.benchmarkAllNPOs || 'Benchmark of all NPOs',
      data: benchAllData,
      backgroundColor: CONSTANTS.colors.benchLogoYellow.replace(')', rgbaOpacity), // add transparency
      borderColor: CONSTANTS.colors.benchLogoYellow,
      borderWidth: BORDER_THICKNESS,
    },
  )
  if (benchCatData) datasets.push(
    {
      label: jtLabels.benchmarkNPOsOfSameField || 'Benchmark of NPOs in Same Field',
      data: benchCatData,
      backgroundColor: CONSTANTS.colors.benchLogoOrange.replace(')', rgbaOpacity),
      borderColor: CONSTANTS.colors.benchLogoOrange,
      borderWidth: BORDER_THICKNESS,
    },
  )
  if (allResultsData) datasets.push(
    {
      label: jtLabels.yourPercentileAllNPOs || 'Your Percentile - All NPOs',
      data: allResultsData,
      backgroundColor: CONSTANTS.colors.benchLogoRed.replace(')', rgbaOpacity),
      borderColor: CONSTANTS.colors.benchLogoRed,
      borderWidth: BORDER_THICKNESS,
    },
  )
  if (catResultsData) datasets.push(
    {
      label: jtLabels.yourPercentileNPOsSameField || 'Your Percentile - NPOs of Same Field',
      data: catResultsData,
      backgroundColor: CONSTANTS.colors.benchLogoAnthracite.replace(')', rgbaOpacity),
      borderColor: CONSTANTS.colors.benchLogoAnthracite,
      borderWidth: BORDER_THICKNESS,
    },
  )
  // console.log('datasets:', datasets)
  return {
    labels: chartLabels,
    datasets,
  }
}
export const BENCH_ALL_BG = CONSTANTS.colors.benchLogoOrange.replace(')', ', 0.2)') // add transparency
export const BENCH_ALL_BORDER = CONSTANTS.colors.benchLogoOrange
export const BENCH_CAT_BG = CONSTANTS.colors.benchLogoRed.replace(')', ', 0.2)')
export const BENCH_CAT_BORDER = CONSTANTS.colors.benchLogoRed
export const NPO_VS_ALL_BG = CONSTANTS.colors.benchLogoRed.replace(')', ', 0.2)')
export const NPO_VS_ALL_BORDER = CONSTANTS.colors.benchLogoRed
export const NPO_VS_CAT_BG = CONSTANTS.colors.benchLogoBlue.replace(')', ', 0.2)')
export const NPO_VS_CAT_BORDER = CONSTANTS.colors.benchLogoBlue
