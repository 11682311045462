import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { updateSurveyStatus } from '../../features/user/userSlice'
import ProgressBarContainer from '../common/ProgressBarContainer/ProgressBarContainer'
import Module1AGR from './Module1AGR'
import Module1GEM from './Module1GEM'
import Module1IVB from './Module1IVB'
import Module1KOP from './Module1KOP'
import Module1OtherQs from './Module1OtherQs'
import { formatDate } from '../../utils/formatDate'
import { shuffle } from '../../utils/shuffleArray'
import {
  MAX_SURVEY_QUESTIONS_PER_PAGE,
} from '../../utils/constants'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { answerSavedMsg } from '../../utils/answerSavedMsg'
import { saveAnswersInDB } from '../../utils/saveAnswersInDB'
import { showMissingAnswerWarning, showCategoryNotSetWarning } from '../../utils/getMiscToastMsg'
import { AGROptionalQs, GEM_OR_KOP_SHORT_LENGTH, IVB_SHORT_LENGTH } from '../../utils/giveAnswer'
import styles from './Module1.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// render survey Module 1 (Step 2); called from SurveyFull.js
const Module1 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  // console.log('user:', user)

  const [progress, setProgress] = useState(0) // in %
  const [progressGEM, setProgressGEM] = useState(0) // number of answered GEM questions
  const [currentGemQlength, setCurrentGemQlength] = useState(0) // Reduced number if GEM0 is answered with 'no'
  const [progressIVB, setProgressIVB] = useState(0) // number of answered GEM7-10 and PRO10-14 questions (Interessenverbände IVB)
  const [currentIvbQlength, setCurrentIvbQlength] = useState(0) // Reduced number if PRO9 is answered with 'no'
  const [progressKOP, setProgressKOP] = useState(0) // number of answered KOP questions
  const [currentKopQlength, setCurrentKopQlength] = useState(0) // Reduced number if KOP0 is answered with 'no'
  const [progressAGR, setProgressAGR] = useState(0) // number of answered AGR questions
  const [currentAgrQlength, setCurrentAgrQlength] = useState(0) // Reduced number if AGR0 is answered with 'none'
  const [progressOther, setProgressOther] = useState(0) // number of answered Other questions

  const [gemQuestions, setGemQuestions] = useState(null)
  const [ivbQuestions, setIvbQuestions] = useState(null) // IVB = IVB0 + GEM7-10 + PRO10-14 (Interessenverbände)
  const [kopQuestions, setKopQuestions] = useState(null)
  const [agrQuestions, setAgrQuestions] = useState(null)
  const [otherModule1Questions, setOtherModule1Questions] = useState(null)
  const [questionsReceived, setQuestionsReceived] = useState(false)
  const [sectionToRender, setSectionToRender] = useState('module1GEM')

  const [gemPrevAnswers, setGemPrevAnswers] = useState({})
  const [ivbPrevAnswers, setIvbPrevAnswers] = useState({})
  const [kopPrevAnswers, setKopPrevAnswers] = useState({})
  const [agrPrevAnswers, setAgrPrevAnswers] = useState({})
  const [otherQsPrevAnswers, setOtherQsPrevAnswers] = useState({})
  const [gemAnswers, setGemAnswers] = useState({})
  const [ivbAnswers, setIvbAnswers] = useState({})
  const [kopAnswers, setKopAnswers] = useState({})
  const [agrAnswers, setAgrAnswers] = useState({})
  const [otherQsAnswers, setOtherQsAnswers] = useState({})

  useEffect(() => {
    if (!user.userID) navigate('/survey')
    // load questions, but only run this once
    if (!questionsReceived) {
      if (user.userID && !user.orgCategory) showCategoryNotSetWarning(jt.toast || {})
      axios
        .get(`${API_URL}/api/survey-questions` +
          '?filters[module][$eq]=1' +
          '&pagination[pageSize]=70')
        .then(res => {
          // console.log('res.data:', res.data)
          const module1Questions = res.data.data.map(item => {
            let format = item.attributes.format
            if (format === 'A') format = 'C' // add offSwitch to single type (Demo questions are without offSwitch)
            if (format === 'B') format = 'D' // add offSwitch to double type
            return {
              questionID: item.attributes.questionID,
              question1: item.attributes.question1,
              question2: item.attributes.question2,
              answerArray: item.attributes.answerArray?.answerArray || null,
              format: format,
            }
          })

          const gemQs = []
          const ivbQs = []
          let ivb0 = {} // this is the filter question for this section
          const kopQs = []
          const agrQs = []
          const otherQs = []
          for (const q of module1Questions) {
            switch (q.questionID.substring(0, 3)) {
              case 'GEM':
                if (q.questionID.slice(3) < 7) gemQs.push(q)
                else ivbQs.push(q)  // IVB = IVB0 + GEM7-10 + PRO10-14 (Interessenverbände)
                break
              case 'KOP':
                kopQs.push(q)
                break
              case 'AGR':
                agrQs.push(q)
                break
              case 'IVB':
                ivb0 = q
                break
              default:
                if (q.questionID.startsWith('PRO') && q.questionID.slice(3) > 9) ivbQs.push(q)
                else otherQs.push(q)
            }
          }

          gemQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setGemQuestions(gemQs)
          setCurrentGemQlength(gemQs.length)

          ivbQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          // move IVB0 (the filter question) to the start of the array
          ivbQs.unshift(ivb0)
          setIvbQuestions(ivbQs)
          setCurrentIvbQlength(ivbQs.length)

          kopQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setKopQuestions(kopQs)
          setCurrentKopQlength(kopQs.length)

          agrQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setAgrQuestions(agrQs)
          setCurrentAgrQlength(agrQs.length)

          shuffle(otherQs)
          setOtherModule1Questions(otherQs)

          setQuestionsReceived(true)
        })
        .catch(error => handleStrapiAPIError(error))
    } else {
      // if questions already received, load previous answers if they exist
      // note: this API call must run after the above one, since we are updating the same state (question length)
      axios
        .get(API_URL + '/api/users/me', authHeaders())
        .then(res => {
          const previousAnswers = res.data.module1 || {}
          // console.log('previousAnswers:', previousAnswers)
          if (Object.keys(previousAnswers).length) {
            const gemAns = {}
            const ivbAns = {}
            const kopAns = {}
            const agrAns = {}
            const otherQsAns = {}
            for (const questKey in previousAnswers) {
              if (questKey.startsWith('GEM') && questKey.slice(3) < 7) gemAns[questKey] = previousAnswers[questKey]
              else if (questKey.startsWith('GEM')) ivbAns[questKey] = previousAnswers[questKey]
              else if (questKey.startsWith('IVB')) ivbAns[questKey] = previousAnswers[questKey]
              else if (questKey.startsWith('KOP')) kopAns[questKey] = previousAnswers[questKey]
              else if (questKey.startsWith('AGR')) agrAns[questKey] = previousAnswers[questKey]
              else if (questKey.startsWith('PRO') && questKey.slice(3) > 9) ivbAns[questKey] = previousAnswers[questKey]
              else if (!questKey.startsWith('progress')) otherQsAns[questKey] = previousAnswers[questKey]
            }
            setGemPrevAnswers(gemAns)
            setIvbPrevAnswers(ivbAns)
            setKopPrevAnswers(kopAns)
            setAgrPrevAnswers(agrAns)
            setOtherQsPrevAnswers(otherQsAns)
            // if GEM0 has been answered 'no' ('Nein'), reduce question length from 7 to 1
            if (previousAnswers.GEM0 === gemQuestions[0].answerArray[1])
              setCurrentGemQlength(GEM_OR_KOP_SHORT_LENGTH)
            // if IVB0 has been answered 'no' ('Nein'), reduce question length from 10 to 1
            if (previousAnswers.IVB0 === ivbQuestions[0].answerArray[1])
              setCurrentIvbQlength(IVB_SHORT_LENGTH)
            // if KOP0 has been answered 'no' ('Nein'), reduce question length from 6 to 1
            if (previousAnswers.KOP0 === kopQuestions[0].answerArray[1])
              setCurrentKopQlength(GEM_OR_KOP_SHORT_LENGTH)
            // if AGR0 has been answered 'none' ('keine'), reduce question length from 7 to 4
            if (previousAnswers.AGR0 === agrQuestions[0].answerArray[0])
              setCurrentAgrQlength(agrQuestions.length - AGROptionalQs.length)
            // set each section's progress
            setProgressGEM(previousAnswers.progressGEM ?? 0)
            setProgressIVB(previousAnswers.progressIVB ?? 0)
            setProgressKOP(previousAnswers.progressKOP ?? 0)
            setProgressAGR(previousAnswers.progressAGR ?? 0)
            setProgressOther(previousAnswers.progressOther ?? 0)
          }
        })
        .catch(error => handleStrapiAPIError(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsReceived])

  const advance = async () => {
    // save progress in DB
    const newSurveyStatus = {
      ...user.surveyStatus,
      module1: `${progress}%`,
      lastUpdate: formatDate(new Date()),
    }
    dispatch(updateSurveyStatus({ surveyStatus: newSurveyStatus }))
    // if new answer objects have been filled, use them; else use previous answers
    const saveGemAnswers = Object.keys(gemAnswers).length ? gemAnswers : gemPrevAnswers
    const saveIvbAnswers = Object.keys(ivbAnswers).length ? ivbAnswers : ivbPrevAnswers
    const saveKopAnswers = Object.keys(kopAnswers).length ? kopAnswers : kopPrevAnswers
    const saveAgrAnswers = Object.keys(agrAnswers).length ? agrAnswers : agrPrevAnswers
    const saveOtherQsAnswers = Object.keys(otherQsAnswers).length ? otherQsAnswers : otherQsPrevAnswers
    const allModule1Answers = {
      ...saveGemAnswers, ...saveIvbAnswers, ...saveKopAnswers, ...saveAgrAnswers, ...saveOtherQsAnswers,
    }
    const module1Progress = { progressGEM, progressIVB, progressKOP, progressAGR, progressOther }
    await axios
      .put(
        `${API_URL}/api/users/${user.userID}`,
        {
          surveyStatus: newSurveyStatus,
          module1: { ...allModule1Answers, ...module1Progress },
        },
        authHeaders(),
      )
      .then(() => answerSavedMsg(sectionToRender !== 'module1OtherQs'))
      .catch(error => handleStrapiAPIError(error))

    if (progress === 100) {
      // change all "Ja" answers to 1 and all "Nein" answers to 0
      for (const questKey in allModule1Answers) {
        if (allModule1Answers[questKey] === 'Ja') allModule1Answers[questKey] = 1
        if (allModule1Answers[questKey] === 'Nein') allModule1Answers[questKey] = 0
      }
      saveAnswersInDB(user, '1', allModule1Answers)
    }

    if (sectionToRender === 'module1GEM') {
      if (progressGEM < currentGemQlength) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module1IVB')
    }
    if (sectionToRender === 'module1IVB') {
      if (progressIVB < currentIvbQlength) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module1KOP')
    }
    if (sectionToRender === 'module1KOP') {
      if (progressKOP < currentKopQlength) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module1AGR')
    }
    if (sectionToRender === 'module1AGR') {
      if (progressAGR < currentAgrQlength) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module1OtherQs')
    }
    if (sectionToRender === 'module1OtherQs') {
      if (progress < 100) showMissingAnswerWarning(jt.toast)
      navigate('/survey')
    }
  }

  const updateProgress = (
    numberOfAnsweredQs,
    questionSection,
    newNumberOfQsInSection
  ) => {
    let totalNumberOfQs
    switch (questionSection) {
      case 'GEM':
        if (!newNumberOfQsInSection) newNumberOfQsInSection = gemQuestions.length
        setCurrentGemQlength(newNumberOfQsInSection)
        totalNumberOfQs =
          newNumberOfQsInSection +
          currentIvbQlength +
          currentKopQlength +
          currentAgrQlength +
          otherModule1Questions.length
        setProgressGEM(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((numberOfAnsweredQs + progressIVB + progressKOP + progressAGR + progressOther) /
              totalNumberOfQs) *
            100,
          ),
        )
        break
      case 'IVB':
        if (!newNumberOfQsInSection)
          newNumberOfQsInSection = ivbQuestions.length
        setCurrentIvbQlength(newNumberOfQsInSection)
        totalNumberOfQs =
          currentGemQlength +
          newNumberOfQsInSection +
          currentKopQlength +
          currentAgrQlength +
          otherModule1Questions.length
        setProgressIVB(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressGEM + numberOfAnsweredQs + progressKOP + progressAGR + progressOther) /
              totalNumberOfQs) *
            100,
          ),
        )
        break
      case 'KOP':
        if (!newNumberOfQsInSection)
          newNumberOfQsInSection = kopQuestions.length
        setCurrentKopQlength(newNumberOfQsInSection)
        totalNumberOfQs =
          currentGemQlength +
          currentIvbQlength +
          newNumberOfQsInSection +
          currentAgrQlength +
          otherModule1Questions.length
        setProgressKOP(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressGEM + progressIVB + numberOfAnsweredQs + progressAGR + progressOther) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'AGR':
        if (!newNumberOfQsInSection)
          newNumberOfQsInSection = agrQuestions.length
        setCurrentAgrQlength(newNumberOfQsInSection)
        totalNumberOfQs =
          currentGemQlength +
          currentIvbQlength +
          currentKopQlength +
          newNumberOfQsInSection +
          otherModule1Questions.length
        setProgressAGR(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressGEM + progressIVB + progressKOP + numberOfAnsweredQs + progressOther) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'Other':
        setProgressOther(numberOfAnsweredQs)
        totalNumberOfQs =
          currentGemQlength +
          currentIvbQlength +
          currentKopQlength +
          currentAgrQlength +
          otherModule1Questions.length
        const newProgress = Math.round(
          ((progressGEM + progressIVB + progressKOP + progressAGR + numberOfAnsweredQs) /
            totalNumberOfQs) *
          100
        )
        setProgress(newProgress)
        break
      default:
        console.log(`This questionSection:${questionSection} is unknown!`)
    }
  }

  const getSaveAnsBtnText = () => {
    return jt.label?.saveAnswers || 'Save current answers'
    /*
    let text
    // if current section is finished, show 'save and advance to next page'
    if ((sectionToRender === 'module1GEM' && progressGEM === currentGemQlength) ||
      (sectionToRender === 'module1KOP' && progressKOP === currentKopQlength) ||
      (sectionToRender === 'module1AGR' && progressAGR === currentAgrQlength)
    ) text = jt.label?.saveAndAdvance || 'Save and advance'
    // if all questions are answered, show 'save and finish'
    else if (progress === 100) text = jt.label?.saveAndFinish || 'Save and finish'
    // Otherwise, simply show 'save current answers'
    else text = jt.label?.saveAnswers || 'Save current answers'
    return text
    */
  }

  return (
    <div className={styles.surveyContainer}>
      <h6 className={styles.moduleHeader}>
        {jt.header?.module1 || 'Module 1'}
      </h6>
      <h1 className='mb-5'>{jt.header?.module1Title || 'Entrepreneurial Culture'}</h1>
      <br />
      <Container>
        {questionsReceived && sectionToRender === 'module1GEM' && (
          <Module1GEM
            questions={gemQuestions}
            previousAnswers={gemPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setGemAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module1IVB' && (
          <Module1IVB
            questions={ivbQuestions}
            previousAnswers={ivbPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setIvbAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module1KOP' && (
          <Module1KOP
            questions={kopQuestions}
            previousAnswers={kopPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setKopAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module1AGR' && (
          <Module1AGR
            questions={agrQuestions}
            previousAnswers={agrPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setAgrAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module1OtherQs' && (
          <Module1OtherQs
            questions={otherModule1Questions}
            previousAnswers={otherQsPrevAnswers}
            maxQuestionsPerPage={MAX_SURVEY_QUESTIONS_PER_PAGE}
            updateProgress={updateProgress}
            updateAnswers={setOtherQsAnswers}
          />
        )}
      </Container>

      <ProgressBarContainer
        btnLabel={getSaveAnsBtnText()}
        progress={progress}
        handleButtonClick={advance}
      />
    </div>
  )
}

export default Module1
