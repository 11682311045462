import { calcPercentile } from '../../../utils/statisticCalc'
import { CONSTRUCTS } from '../../../utils/constants'

const CONSTRUCTS_FOR_RESULTS = { // the order here pertains only to the table
  mod1: [
    CONSTRUCTS.PROACTIVITY.short, // 'PRO'
    CONSTRUCTS.INNOVATION.short, // 'INN'
    CONSTRUCTS.RISK_TAKING.short, // 'RSK'
    CONSTRUCTS.FUTURE_ORIENTATION.short, // 'ZKO'
    CONSTRUCTS.AUTONOMY.short, // 'AUT'
    CONSTRUCTS.COMMUNITY_MOBILIZATION.short, // 'GEM'
    CONSTRUCTS.COOPERATIVE_MOBILIZATION.short, // 'KOP'
    CONSTRUCTS.AGGRESSIVENESS.short, // 'AGR'
    CONSTRUCTS.ACTIVATION.short, // 'AKT'
    CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION.short, // 'UFK'
  ],
  mod2: [
    CONSTRUCTS.VISIONARY_LEADERSHIP.short, // 'MLQ'
    CONSTRUCTS.SERVANT_LEADERSHIP.short, // 'SVL'
  ],
  mod3: [
    CONSTRUCTS.CONSENSUS.short, // 'KON'
    CONSTRUCTS.STRATEGY.short, // 'STR'
    CONSTRUCTS.COMMUNICATION.short, // 'IKM'
    CONSTRUCTS.HR_DIVERSITY.short, // 'HRD'
    CONSTRUCTS.HR_LOYALTY.short, // 'HRB'
  ],
  mod4: [
    CONSTRUCTS.COMPLEXITY.short, // 'KMP'
    CONSTRUCTS.FUNDING_OPPORTUNITY.short, // 'MUN'
    CONSTRUCTS.HOSTILITY.short, // 'HST'
    CONSTRUCTS.LATITUDE.short, // 'FRE'
  ],
  mod5: [
    CONSTRUCTS.SOCIAL_MISSION.short, // 'SAZ'
    CONSTRUCTS.MOBILIZATION.short, // 'MOB'
    CONSTRUCTS.GROWTH.short, // 'WAC'
    CONSTRUCTS.PROFITABILITY.short, // 'PRF'
    CONSTRUCTS.PERSONNEL_CHANGE.short, // 'MIT'
  ],
}

/**
 * called by ResultsModule1-5.js
 * @param module, number, module being calculated
 * @param benchModule, object
 * @param answerObject, object, either this NPO's survey or Benchmark of NPO of same field
 * @return object, the calculated results for current module
 */
export const calcModResult = (module, benchModule, answerObject) => {
  const constructs = CONSTRUCTS_FOR_RESULTS[`mod${module}`]
  const result = {}
  for (const construct of constructs) {
    const mean = answerObject[construct].mean
    result[construct] = {
      mean,
      percentile: calcPercentile(mean, benchModule[construct].mean, benchModule[construct].stdDev),
    }
  }
  return result
}
