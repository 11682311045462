import axios from 'axios'
import { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import Footer from '../Footer/Footer'
import styles from './Profile.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// allow a user to view and edit their personal profile
const Profile = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const [userProfile, setUserProfile] = useState({
    id: null,
    firstName: '',
    lastName: '',
    postCode: '',
    city: '',
    country: '',
    email: '',
  })

  useEffect(() => {
    if (user.userID)
      axios
        .get(`${API_URL}/api/users/me`, authHeaders())
        .then(res => {
          const data = res.data
          setUserProfile({
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName || '',
            postCode: data.postCode || '',
            city: data.city || '',
            country: data.country || '',
            email: data.email,
          })
        })
        .catch(error => handleStrapiAPIError(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userID])

  const onFormSubmit = (event) => {
    event.preventDefault()
    if (!userProfile.firstName) {
      toast.error('Vorname ist ein Pflichtfeld.')
      return
    }
    axios
      .put(
        `${API_URL}/api/users/${userProfile.id}`,
        {
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          city: userProfile.city,
          postCode: userProfile.postCode,
          country: userProfile.country,
        },
        authHeaders(),
      )
      .then(() => toast.success(jt.toast?.profileUpdated || 'Your profile has been updated!'))
      .catch(error => handleStrapiAPIError(error))
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>
          {user.firstName} {userProfile.lastName}
        </h1>
      </div>
      <Container className={styles.mainContent}>
        <div className={styles.title}>
          {jt.header?.editMyProfile || "Edit my Profile"}
        </div>
        <Form onSubmit={onFormSubmit} className="text-start">
          <Row className="g-5">
            <Col xs={6}>
              <Form.Group>
                <Form.Label className={styles.label}>{jt.placeholder?.firstName || "First name"}*</Form.Label>
                {/* This div is to prevent Bootstrap's .form-control class from overriding our .formEntry class. */}
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.firstName || "First name"}
                    value={userProfile.firstName}
                    onChange={e => setUserProfile({ ...userProfile, firstName: e.target.value, })}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className={styles.label}>{jt.placeholder?.city || "City"}</Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.city || "City"}
                    value={userProfile.city}
                    onChange={e => setUserProfile({ ...userProfile, city: e.target.value })}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className={styles.label}>{jt.placeholder?.country || "Country"}</Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.country || "Country"}
                    value={userProfile.country}
                    onChange={e => setUserProfile({ ...userProfile, country: e.target.value })}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <button type="submit" className={styles.orangeBtn}>
                {jt.label?.saveChanges || "Save Changes"}
              </button>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className={styles.label}>{jt.placeholder?.lastName || "Last name"}</Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.lastName || "Last name"}
                    value={userProfile.lastName}
                    onChange={e => setUserProfile({ ...userProfile, lastName: e.target.value, })}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className={styles.label}>{jt.placeholder?.postCode || "Post Code"}</Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.postCode || "Post Code"}
                    value={userProfile.postCode}
                    onChange={e => setUserProfile({ ...userProfile, postCode: e.target.value, })}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className={styles.label}>{jt.placeholder?.email || "Email address"}</Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.email || "Email address"}
                    value={userProfile.email}
                    disabled
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Container>

      <Footer />
    </div>
  )
}

export default Profile
