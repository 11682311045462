import React from 'react';
import { useSelector } from 'react-redux'
import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = () => {
  const jt = useSelector(state => state.jsonText.jsonText)

  return ( <div>
    {/*<hr className={styles.horizontalLine}></hr>*/}
    <div className={styles.termsAndPrivacy}>
      {jt.block?.acceptConditions || "By registering or logging in you are agreeing to our"}
      <br/>
      <a href="TermsAndconditions link here">{jt.label?.conditionsOfUse || "Conditions of Use"}</a>
      {' '}{jt.label?.and || "and"}{' '}
      <a href="PrivacyStatement link here">{jt.label?.privacyPolicy || "Privacy Policy"}</a>
      {jt.block?.acceptConditionsLastWord || ""}.
    </div>
    {/*<hr className={styles.horizontalLine}></hr>*/}
  </div> )

}

export default TermsAndConditions;
