import { Container, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReactComponent as EditIcon } from '../../assets/icons/edit-Icon.svg'
import Footer from '../Footer/Footer'
import HorizontalItem from '../SurveyFull/HorizontalItem'
import styles from './Demo.module.scss'

const demoAnswerLength = 10

/** demo explanation page
 * invoked by Hello.js (now deprecated) and Welcome.js through routing
 * user can proceed to either take the demo survey or see their demo results
 * or, they can go register
 */
const Demo = () => {
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)
  const previousAnswers = JSON.parse(localStorage.getItem('DemoSurvey'))
  let demoAnswers = 0
  for (const answer in previousAnswers) {
    if (previousAnswers[answer]) demoAnswers++
  }
  const percentComplete =
    Math.round((demoAnswers / demoAnswerLength) * 100) + '%'

  const demoSurveyItem = {
    title: jt.header?.demoSurvey || 'Demo Survey',
    module: jt.header?.demo || 'Demo',
    surveyLink: '/demo/survey',
    resultsLink: '/demo/results',
    minutes: '3',
  }

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {jt.block?.resultsVisibleOnRegistering || 'Actual results will be visible upon registering'}
    </Tooltip>
  )

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>{jt.header?.demoSurvey || 'Demo Survey'}</h1>
        <p>
          {jt.block?.demoSurveyDescription || 'Try out this simple version to see how helpful this tool can be.'}
        </p>
      </div>

      <Container className={styles.mainContent}>
        <ListGroup className={styles.list}>
          <div className={styles.orgLabel}>
            {jt.label?.yourOrganization || "Your Organization"}
          </div>
          <div className={styles.orgName}>
            {jt.label?.myNpo || "My NPO"}
          </div>
          <div className={styles.editDiv}>
            <OverlayTrigger
              placement='left'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <EditIcon className={styles.editIcon} alt='edit icon' />
            </OverlayTrigger>
          </div>
          <hr className={styles.hr} />

          <HorizontalItem
            index={0} // this should be the equivalent of Module 1 as called by SurveyFull.js
            item={demoSurveyItem}
            surveyStatus={{ module1: percentComplete }}
          />

        </ListGroup>

        <div className={styles.bottomContainer}>
          <h2 className={styles.bottomTitle}>
            {jt.header?.readyForFullVersion || "Ready for the full version?"}
          </h2>
          <p className={styles.bottomText}>
            {jt.block?.overallSurveyInfo || 'The full survey consists of one main module and 4 auxiliary modules.  Please fill them all out to access your full results.'}
          </p>
          <button
            className={styles.bottomBtn}
            onClick={() => navigate('/register')}
          >
            {jt.label?.registerAndStart || "Register and get started!"}
          </button>
        </div>
      </Container>
      <Footer />
    </div>
  )
}

export default Demo
