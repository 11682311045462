import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Col, ListGroup, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import { Radar, Bar } from 'react-chartjs-2'
import html2canvas from 'html2canvas'
import { getResByModArr } from '../../../utils/getResByModArr'
import { BAR_OPTIONS, barLegendMargin, RADAR_OPTIONS } from '../../../utils/reactChartConfig'
import styles from './ChartAndNave.module.scss'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

/**
 * Called by Results.js and all ResultsModule1-5.js
 * Renders the chart on the left and the navigation list on the right.
 * It also renders the charts directly below that where necessary.
 * @param chartData, object { first: {<data to create the first chart>}, second: {}... }
 * @param chartLabels, object, { first: <'label 1'>, second: <'label 2'>, third: <'label 3'> }
 * @param type, string, either 'radar' or 'bar'
 * @param setChartImage, state setter function, to set the chart image for the pdf-file
 * @return {JSX.Element}
 */
const ChartAndNav = ({ chartData, chartLabels, type, setChartImages }) => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const chart1Ref = useRef(null)
  const chart2Ref = useRef(null)
  const chart3Ref = useRef(null)

  useEffect(() => {
    // create the image of the first chart for the downloadable pdf file,
    // if the data has arrived and there is a setChartImage function present
    if (chartData && chart1Ref.current && setChartImages) {
      genChartImage(chart1Ref, 'first')
    }
    // eslint-disable-next-line
  }, [chart1Ref.current])

  useEffect(() => {
    if (chartData && chart2Ref.current && setChartImages) {
      genChartImage(chart2Ref, 'second')
    }
    // eslint-disable-next-line
  }, [chart2Ref.current])

  useEffect(() => {
    if (chartData && chart3Ref.current && setChartImages) {
      genChartImage(chart3Ref, 'third')
    }
    // eslint-disable-next-line
  }, [chart3Ref.current])

  const genChartImage = (ref, ordinal) => {
    // something deep down in the chart generation needs time to complete
    // before the scales are correctly set
    setTimeout(() => html2canvas(ref.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      const imgWidth = 160 // A4 width = 210 mm; then subtract 1 inch=25mm from both sides
      const imgHeight = imgWidth * (canvas.height / canvas.width)
      setChartImages(prev => ({
        ...prev,
        [ordinal]: { imgData, imgWidth, imgHeight },
      }))
    }), 700)
  }

  const firstChartOptions = type === 'radar' ?
    JSON.parse(JSON.stringify(RADAR_OPTIONS)) // make a deep copy
    : JSON.parse(JSON.stringify(BAR_OPTIONS))
  firstChartOptions.plugins.title.text = chartLabels?.first

  const secondChartOptions = type === 'radar' ?
    JSON.parse(JSON.stringify(RADAR_OPTIONS))
    : JSON.parse(JSON.stringify(BAR_OPTIONS))
  secondChartOptions.plugins.title.text = chartLabels?.second

  const thirdChartOptions = type === 'radar' ?
    JSON.parse(JSON.stringify(RADAR_OPTIONS))
    : JSON.parse(JSON.stringify(BAR_OPTIONS))
  thirdChartOptions.plugins.title.text = chartLabels?.third

  // decrease legend font size on smaller screens
  if (window.innerWidth < 992) {
    firstChartOptions.plugins.legend.labels.font.size = 10
    secondChartOptions.plugins.legend.labels.font.size = 10
    thirdChartOptions.plugins.legend.labels.font.size = 10
  } else if (window.innerWidth < 1200) {
    firstChartOptions.plugins.legend.labels.font.size = 14
    secondChartOptions.plugins.legend.labels.font.size = 14
    thirdChartOptions.plugins.legend.labels.font.size = 14
  }

  const resultsByModArr = getResByModArr(jt.header)
  // console.log('resultsByModArr:',resultsByModArr)

  return (
    <div>
      <Row>
        <Col xs={6} className={styles.leftCol}>
          {chartData?.first ? (
            <div ref={chart1Ref}>
              {type === 'radar' ?
                <Radar data={chartData.first} options={firstChartOptions} className={styles.radar} />
                :
                <Bar data={chartData.first} options={firstChartOptions} className={styles.bar}
                     plugins={[barLegendMargin]} />
              }
            </div>
          ) : (
            <div className={styles.radar}>
              <p className={styles.noDataTitle}>
                {jt.block?.yourNpoHasNotYetCompletedASurvey ||
                  'Your NPO has not yet completed a full survey and therefore no results can be shown.\nIf in doubt, see the Survey Administrator of your NPO.'}
              </p>
            </div>
          )}
        </Col>
        <Col xs={6} className={styles.rightCol}>
          <div className={styles.yourResults}>
            {jt.header?.yourResults || 'Your Results'}
          </div>
          <ListGroup>
            <LinkContainer to={resultsByModArr[1].resultsLink} key={'module 1'}>
              <ListGroup.Item action className={styles.linkItem}
                              bsPrefix={'chartNavMod1Item'}
              >
                <div className={styles.moduleTitle}>
                  {resultsByModArr[1].module && <span>{resultsByModArr[1].module}{': '}</span>}
                  <br />
                  {resultsByModArr[1].title.replace('-', '')}
                </div>
              </ListGroup.Item>
            </LinkContainer>
            <Row className={styles.verticalSection}>
              {resultsByModArr.map((mod, idx) => {
                if (idx < 2) return null // module 0 is not included; module 1 is rendered horizontally above
                // we need to use key={idx} so that react will not duplicate after getting all the correct header names
                return (
                  <LinkContainer to={mod.resultsLink} key={idx}>
                    <ListGroup.Item action className={styles.vLinkItem}
                                    bsPrefix={`chartNavMod${idx}Item`}
                    >
                      <div className={styles.vModuleTitle}>
                        {mod.module && <span>{mod.module}{': '}</span>}
                        <br />
                        {mod.title.replace('-', '')}
                      </div>
                    </ListGroup.Item>
                  </LinkContainer>
                )
              })}
            </Row>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          {chartData?.second &&
            <div ref={chart2Ref}>
              {type === 'radar' ?
                <Radar data={chartData.second} options={secondChartOptions} className={styles.radar} />
                :
                <Bar data={chartData.second} options={secondChartOptions} className={styles.bar}
                     plugins={[barLegendMargin]} />
              }
            </div>
          }
        </Col>
        <Col>
          {chartData?.third &&
            <div ref={chart3Ref}>
              {type === 'radar' ?
                <Radar data={chartData.third} options={thirdChartOptions} className={styles.radar} />
                :
                <Bar data={chartData.third} options={thirdChartOptions} className={styles.bar}
                     plugins={[barLegendMargin]} />
              }
            </div>
          }
        </Col>
      </Row>
    </div>
  )
}

export default ChartAndNav
