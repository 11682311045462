import { useEffect, useState } from "react";
import QuestionForm from "../common/QuestionForm/QuestionForm";
import { giveAnswer } from '../../utils/giveAnswer'
import styles from "./Module2.module.scss"

// render the SVL section of Module 2 (Step 3) of the full survey; called by Module2.js
const Module2SVL = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}) // future object like { "SVL1": 1, "SVL2": 0, ...}

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    /* set up initialAnswerObj: { AGR0: null, AGR1: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'SVL')
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module2.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[previousAnswers])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      return <div key={question.questionID}>
        <QuestionForm
          question1={question.question1}
          question2={question.question2}
          questionIndex={idx}
          questionFormat={question.format}
          answerLabels={question.answerLabels || 'default'}
          currentAnswer={answerObj[question.questionID]}
          giveAnswer={(val, questionIndex) => giveAnswer(
            'SVL', val, questionIndex, null, questions,
            answerObj, setAnswerObj, updateAnswers, updateProgress,
          )}
        />
        <hr className={styles.horizontalLine}></hr>
      </div>
    })}
  </div>)
}

export default Module2SVL;
