import { useSelector } from 'react-redux'
import Footer from '../Footer/Footer'
import styles from './DataPolicy.module.scss'

const DataPolicy = () => {
  const jt = useSelector(state => state.jsonText.jsonText)

  return (
    <div>
      <div className={styles.container}>

        <h1 className={styles.title}>
          {jt.privacy?.title || 'Privacy Policy'}
        </h1>

        <div className={styles.mainSection}>
          <h2 className={styles.sectionTitle}>
            {jt.privacy?.intro || 'Introduction'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.introText || 'We place a high value on protecting your data.'}
          </p>

          <h2 className={styles.sectionTitle}>
            {jt.privacy?.dataCollected || 'Data Collected'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.dataList || '- Name\n- Email\n- Name and Address of your NPO\n- Operational Data of your NPO'}
          </p>

          <h2 className={styles.sectionTitle}>
            {jt.privacy?.howWeUseYourData || 'How we use your data'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.howWeUseYourDataText || 'We use this data to provide NPOs with helpful feedback and also for further research.'}
          </p>

          <h2 className={styles.sectionTitle}>
            {jt.privacy?.dataSharing || 'Data sharing'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.dataSharingText || 'We do not share your data with third parties unless we are obliged by law to do so.'}
          </p>

          <h2 className={styles.sectionTitle}>
            {jt.privacy?.userRights || 'User Rights'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.userRightsText || 'All users have the right to access, correct and delete their personal data.'}
            {' '}
            <span onClick={() => window.open(`mailto:${jt.label?.vmiEmail || 'info@vmi.ch'}`)}>
              {jt.label?.vmiEmail || 'info@vmi.ch'}
            </span>
          </p>

          <h2 className={styles.sectionTitle}>
            {jt.privacy?.securityMeasures || 'Security Measures'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.securityMeasuresText || 'All coworkers and service providers are obliged to confidentiality, and all standard technical means have been taken to protect your data.'}
          </p>

          <h2 className={styles.sectionTitle}>
            {jt.privacy?.useOfCookies || 'Use of Cookies'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.useOfCookiesText || 'We do not use cookies as such./nHowever, small amounts of data are stored in the browser Local Storage.'}
          </p>

          <h2 className={styles.sectionTitle}>
            {jt.privacy?.contactInformation || 'Contact Information'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.contactInformationText || 'For any further questions or concerns you can contact us by email at:'}
            {' '}
            <span onClick={() => window.open(`mailto:${jt.label?.vmiEmail || 'info@vmi.ch'}`)}>
              {jt.label?.vmiEmail || 'info@vmi.ch'}
            </span>
          </p>

          <h2 className={styles.sectionTitle}>
            {jt.privacy?.updates || 'Updates'}
          </h2>
          <p className={styles.text}>
            {jt.privacy?.updatesText || 'These guidelines are reviewed and updated regularly.  All updates will be posted on this page.'}
          </p>

        </div>

      </div>

      <Footer />
    </div>
  )
}

export default DataPolicy
