import React from "react";
import styles from './ProgressBarContainer.module.scss'

// TUTORIAL: https://dev.to/ramonak/react-how-to-create-a-custom-progress-bar-component-in-5-minutes-2lcl

const ProgressBar = ({ progress }) => {

  const progressLabel = progress > 2 && `${progress}%`;

  return (
    <div className={styles.containerStyles}>
      <div className={styles.fillerStyles} style={{width: `${progress}%`,}}>
        <div className={styles.labelStyles}>
          <span className={styles.labelInnerStyles}>{progressLabel}</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
