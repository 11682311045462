
/**
 * for side questions using SideTgglBtnForm.js such as IPQ questions in Module2IPQ.js
 * counts all the questions of the current section that have no sub-questions still set to null
 * @param answerObj object, contains all the questions answered thus far for the current section
 * @return answeredQCount number of fully answered questions
 */
export const countSubQAns = (answerObj) => {
  let answeredQCount = 0;
  for (const questKey in answerObj) {
    let subQuestionsAnswered = true;
    for (const subQuest in answerObj[questKey]) {
      if (answerObj[questKey][subQuest] === null) {
        subQuestionsAnswered = false
        break
      }
    }
    if (subQuestionsAnswered) answeredQCount++;
  }
  return answeredQCount
}
