
import { useEffect, useState } from "react";
import QuestionForm from "../common/QuestionForm/QuestionForm";
import { giveAnswer } from '../../utils/giveAnswer'
import styles from "./Module4.module.scss"

// render the KVE2 and KVE3 sections of Module 4 (Step 5) of the full survey; called by Module4.js
const Module4KVE23 = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}); // future object like { "KVE2a": 6, "KVE2b": 3, ...}

  useEffect(()=>{
    /* set up initialAnswerObj: { "KVE2a": null, "KVE2b": null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'KVE23')
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module4.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      return <div key={question.questionID}>
        <QuestionForm
          question1={question.question1}
          question2={question.question2}
          questionIndex={idx}
          questionFormat={question.format}
          answerLabels={question.answerLabels || 'default'}
          currentAnswer={answerObj[question.questionID]}
          giveAnswer={(val, questionIndex) => giveAnswer(
            'KVE23', val, questionIndex, null, questions,
            answerObj, setAnswerObj, updateAnswers, updateProgress,
          )}
        />
        <hr className={styles.horizontalLine}></hr>
      </div>
    })}
  </div>)
}

export default Module4KVE23;
