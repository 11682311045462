import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import BookConsultationCTA from '../common/BookConsultationCTA/BookConsultationCTA'
import ChartAndNav from './ChartAndNav/ChartAndNav'
import Footer from '../Footer/Footer'
import ResultsHeader from './ResultsHeader/ResultsHeader'
import ResultsTable from './ResultsTable/ResultsTable'
import ResultsText from './ResultsText/ResultsText'
import { calcModResult } from './ResultsUtils/calcModResults'
import { getChartDataMod5 } from './ResultsUtils/getChartDataMod5'
import { getFeedbackTexts } from '../../utils/getFeedbackTexts'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { showNoCategoryBenchInfo } from '../../utils/getMiscToastMsg'
import { CONSTRUCTS, MIN_SAMPLE_SIZE } from '../../utils/constants'
import { formatDate } from '../../utils/formatDate'

const API_URL = process.env.REACT_APP_API_URL
const CONSTRUCTS_M5 = [  // the order here pertains only to the text rendering below the table
  CONSTRUCTS.SOCIAL_MISSION,
  CONSTRUCTS.MOBILIZATION,
  CONSTRUCTS.GROWTH,
  CONSTRUCTS.PROFITABILITY,
  CONSTRUCTS.PERSONNEL_CHANGE,
]

// render the Module 5 (Step 6) survey results; called by Results.js
const ResultsModule5 = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const [benchMod5All, setBenchMod5All] = useState(null)
  const [benchMod5Cat, setBenchMod5Cat] = useState(null)
  const [mod5AllResults, setMod5AllResults] = useState(null)
  const [mod5CatResults, setMod5CatResults] = useState(null)
  const [mod5BenchCatResults, setMod5BenchCatResults] = useState(null)  // NPO category compared with all NPOs
  const [mod5FeedbackTexts, setMod5FeedbackTexts] = useState({})
  const [chartImages, setChartImages] = useState({})
  const noCatBenchInfoHasBeenShown = useRef(null)

  useEffect(() => {
    // get latest benchmark
    if (user.strapiJwt && jt.toast) {
      if (!benchMod5All) {
        axios
          .get(`${API_URL}/api/benchmarks` +
            `?filters[module][$eq]=5` +
            `&filters[orgCategory][$eq]=all`,
            authHeaders(),
          )
          .then(res => {
            const benchmarkModuleAll = res.data.data[0].attributes.benchmark
            setBenchMod5All(benchmarkModuleAll)
          })
          .catch(error => handleStrapiAPIError(error))
      }
      if (!benchMod5Cat) { // only run this once
        if (user.orgCategory) {
          axios
            .get(`${API_URL}/api/benchmarks` +
              `?filters[module][$eq]=5` +
              `&filters[orgCategory][$eq]=${user.orgCategory}`,
              authHeaders(),
            )
            .then(res => {
              const benchMod5CatData = res.data.data[0].attributes.benchmark
              // console.log('benchMod5CatData:', benchMod5CatData)
              if (benchMod5CatData.sample_size >= MIN_SAMPLE_SIZE) {
                setBenchMod5Cat(benchMod5CatData)
              } else {
                if (noCatBenchInfoHasBeenShown.current !== true) {
                  showNoCategoryBenchInfo(jt.toast)
                  noCatBenchInfoHasBeenShown.current = true
                }
                setBenchMod5Cat('Category not available')
              }
            })
            .catch(error => handleStrapiAPIError(error))
        } else setBenchMod5Cat('Category not available')
      }
      // get the latest answers for this user if they have fully completed this module
      if (benchMod5All && benchMod5Cat) {
        axios
          .get(
            `${API_URL}/api/npo-surveys` +
            `?filters[organizationID][$eq]=${user.orgID}` +
            `&filters[module][$eq]=5` +
            `&sort=createdAt:desc`,
            authHeaders(),
          )
          .then(res => {
            if (res.data.data.length === 0) return
            const answerObject = res.data.data[0].attributes.npoAnswers
            const resultsAll = calcModResult(5, benchMod5All, answerObject)
            const resultsCat = benchMod5Cat === 'Category not available' ? null
              : calcModResult(5, benchMod5Cat, answerObject)
            const resultsBenchCat = benchMod5Cat === 'Category not available' ? null
              : calcModResult(5, benchMod5All, benchMod5Cat)
            setMod5AllResults(resultsAll)
            setMod5CatResults(resultsCat)
            setMod5BenchCatResults(resultsBenchCat)
            const resultsForFB = resultsCat ? resultsCat : resultsAll
            setMod5FeedbackTexts(getFeedbackTexts(jt.results, resultsForFB, CONSTRUCTS_M5))
          })
          .catch(error => handleStrapiAPIError(error))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jt, user, benchMod5All, benchMod5Cat])

  const chartData = mod5AllResults && benchMod5All ?
    {
      first: getChartDataMod5(
        jt.label,
        benchMod5All,
        mod5BenchCatResults,
        mod5AllResults,
        mod5CatResults,
        'summary',
      ),
    }
    : null

  const chartLabels = {
    first: jt.label?.mod5Overview || 'Module 5 Overview',
  }

  const pdfFileName = formatDate(new Date(), 'YYYY.MM.DD') + '_' +
    (jt.label?.module5ResultsFileName || 'Module_5_Results') + '.pdf'

  return (
    <div>
      <ResultsHeader
        module={'module5'}
        chartsLoaded={!!chartImages.first?.imgData}
        chartImages={chartImages}
        benchModAll={benchMod5All}
        benchModCat={benchMod5Cat}
        modAllResults={mod5AllResults}
        modCatResults={mod5CatResults}
        constructs={CONSTRUCTS_M5}
        feedbackTexts={mod5FeedbackTexts}
        pdfFileName={pdfFileName}
      />

      <Container>
        <ChartAndNav
          chartData={chartData}
          chartLabels={chartLabels}
          type={'radar'}
          setChartImages={setChartImages}
        />
        {chartData &&
          <ResultsTable
            jtLabel={jt.label || {}}
            benchModAll={benchMod5All}
            benchModCat={benchMod5Cat}
            modAllResults={mod5AllResults}
            modCatResults={mod5CatResults}
            constructs={CONSTRUCTS_M5}
          />
        }
        <ResultsText
          module={'module5'}
          constructs={CONSTRUCTS_M5}
          modAllResults={mod5AllResults}
          modCatResults={mod5CatResults}
          feedbackTexts={mod5FeedbackTexts}
        />

        <BookConsultationCTA />
      </Container>
      <Footer />
    </div>
  )
}

export default ResultsModule5
