import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Col} from 'react-bootstrap'
import { toast } from 'react-toastify'
import { checkEmailFormat } from '../../utils/checkEmailFormat'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import BasicFormContainer from '../common/BasicFormContainer/BasicFormContainer'
import EntryFormButton from '../common/EntryFormButton/EntryFormButton'
import IconInputField from '../common/IconInputField/IconInputField'
import { getLinkSentMsg } from '../../utils/getMiscToastMsg'
import styles from './ChangePassword.module.scss'

const API_URL = process.env.REACT_APP_API_URL

const ChangePassword = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const [email, setEmail] = useState('')
  const [emailMsg, setEmailMsg] = useState('')
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true)

  // let navigate = useNavigate();

  useEffect(() => {
    // check email format before allowing changes to be submitted
    if (email !== '' && !checkEmailFormat(email)) {
      setEmailMsg(jt.block?.enterEmail || "Please enter your email address")
      setIsSubmitBtnDisabled(true)
    } else if (email === '') {
      setIsSubmitBtnDisabled(true)
    } else {
      setEmailMsg('')
      setIsSubmitBtnDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  const onFormSubmit = (event) => {
    event.preventDefault()
    axios
      .post(`${API_URL}/api/auth/forgot-password`, { email })
      .then(() => toast.success(getLinkSentMsg(jt.toast)))
      .catch((error) => handleStrapiAPIError(error))
  }

  return (
    <div className={styles.mainContent}>
      <h1>
        {jt.label?.changePassword || "Change Password"}?
      </h1>
      <Col xs={{span: 4, offset: 4}} >
        <BasicFormContainer>
        <div style={{ textAlign: 'center' }} className={styles.subtitle}>
          {jt.block?.changePasswordByExternalLink || "This is presently only possible via an external link."}
        </div>
        <Form onSubmit={onFormSubmit} className="container my-4">
          <IconInputField
            value={email}
            onChange={e => setEmail(e.target.value.toLowerCase())}
            type="text"
            placeholder={email || jt.placeholder?.email || "Email address"}
            isInvalid={emailMsg}
            inputMessage={emailMsg}
          />

          <EntryFormButton
            buttonText={jt.label?.sendMeTheLink || "Send me the link!"}
            disabled={isSubmitBtnDisabled}
          />
        </Form>
      </BasicFormContainer>
      </Col>
    </div>
  )
}

export default ChangePassword
