import { useEffect, useState } from "react";
import QuestionForm from "../common/QuestionForm/QuestionForm";
import QuestionFormRadio from '../common/QuestionFormRadio/QuestionFormRadio'
import { adaptNewAnswerObjForKON, giveAnswer } from '../../utils/giveAnswer'
import styles from './Module3.module.scss'

// render the KON section of Module 3 (Step 4) of the full survey; called by Module3.js
const Module3KON = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}); // future object like { "KON0": "CEO...", "KON1a": 0, ...}

  useEffect(() => {
    /* set up initialAnswerObj: { AGR0: null, AGR1: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // update the KON question length
      const numKonQs = adaptNewAnswerObjForKON({ ...previousAnswers }, questions).newNumberOfQs
      // also update progress bar
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'KON', numKonQs)
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module3.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[previousAnswers])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      const AnsToKON0 = answerObj[questions[0].questionID];
      // Depending on answer to KON0, hide KONaQs, KONbQs or both
      if ((idx % 2 === 1) && AnsToKON0 === questions[0].answerArray[0]) {
        return null  // hide the KONaQs: 'KON1a', 'KON2a', 'KON3a' at odd indexes
      }
      if ((idx > 0 && idx % 2 === 0) && AnsToKON0 === questions[0].answerArray[1]) {
        return null  // hide the KONbQs: 'KON1b', 'KON2b', 'KON3b' at even indexes
      }
      if (idx >= 1 && AnsToKON0 === questions[0].answerArray[2]) {
        return null // hide all the follow-up questions
      }
      return <div key={question.questionID}>
        {idx === 0 ? <div className='mb-4'>
            <QuestionFormRadio
              questionIndex={idx}
              question={question.question1}
              questionFormat={question.format}
              answers={question.answerArray}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'KON', val, questionIndex, null, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
          :
          <div>
            <QuestionForm
              questionIndex={idx}
              question1={question.question1}
              question2={question.question2}
              questionFormat={question.format}
              answerLabels={question.answerLabels || 'default'}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'KON', val, questionIndex, null, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
        }
      </div>
    })}
  </div>)
}

export default Module3KON;
