import axios from 'axios'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Form, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { toast } from 'react-toastify'
import TermsAndConditions from '../common/TermsAndConditions/TermsAndConditions'
import Footer from '../Footer/Footer'
import { MIN_PASS_LENGTH } from '../../utils/constants'
import { checkEmailFormat } from '../../utils/checkEmailFormat'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import {
  getInvalidEmailMsg,
  getMissingFieldsMsg,
  getPwMismatchMsg,
  getPwTooShortMsg,
} from '../../utils/getMiscToastMsg'
import styles from './Register.module.scss'

const API_URL = process.env.REACT_APP_API_URL

const Register = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  })
  const [repeatPassword, setRepeatPassword] = useState('')

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const onFormSubmit = (event) => {
    event.preventDefault()
    if (
      !user.firstName ||
      !user.lastName ||
      !user.email ||
      !user.password ||
      !repeatPassword
    ) {
      toast.error(getMissingFieldsMsg(jt.toast))
      return
    }
    if (!checkEmailFormat(user.email)) {
      toast.error(getInvalidEmailMsg(jt.toast))
      return
    }
    if (user.password.length < MIN_PASS_LENGTH) {
      toast.error(getPwTooShortMsg(jt.toast))
      return
    }
    if (user.password !== repeatPassword) {
      toast.error(getPwMismatchMsg(jt.toast))
      return
    }
    const surveyStatus = {
      module0: null,
      module1: null,
      module2: null,
      module3: null,
      module4: null,
      module5: null,
      lastUpdate: null,
    }
    axios
      .post(`${API_URL}/api/auth/local/register`, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        username: user.email, // TODO: find a better workaround for handling username
        password: user.password,
        surveyStatus,
      })
      .then(() => toast.success(jt.toast?.registerSuccess ||
        "Your registration was successful!\nPlease check your email inbox for the confirmation link.")
      )
      .catch((error) => handleStrapiAPIError(error))
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>
          {jt.label?.registerNow || "Register Now"}
        </h1>
        <p>
          {jt.block?.registerIntructions || "Please fill out the obligatory fields and submit!"}
        </p>

        <Form onSubmit={onFormSubmit}>
          <Row className="g-5">
            <Col xs={5}>
              <Form.Control
                type="text"
                placeholder={jt.placeholder?.firstName || "First name"}
                value={user.firstName}
                onChange={e => setUser({ ...user, firstName: e.target.value })}
                className={styles.formEntry}
              />
              <Form.Control
                type="password"
                placeholder={jt.placeholder?.password || "Password"}
                value={user.password}
                onChange={e => setUser({ ...user, password: e.target.value })}
                className={styles.formEntry}
              />
              <Form.Control
                type="text"
                placeholder={jt.placeholder?.yourEmail || "Your email"}
                value={user.email}
                onChange={e => setUser({ ...user, email: e.target.value.toLowerCase() })}
                className={styles.formEntry}
              />
            </Col>
            <Col xs={5}>
              <Form.Control
                type="text"
                placeholder={jt.placeholder?.lastName || "Last name"}
                value={user.lastName}
                onChange={e => setUser({ ...user, lastName: e.target.value })}
                className={styles.formEntry}
              />
              <Form.Control
                type="password"
                placeholder={jt.placeholder?.repeatPassword || "Repeat password"}
                value={repeatPassword}
                onChange={e => setRepeatPassword(e.target.value)}
                className={styles.formEntry}
              />
            </Col>
          </Row>
          <div className={styles.buttons}>
            <button type="submit" className={styles.headerBtn}>
              {jt.label?.createMyAccount || "Create my Account!"}
            </button>
          </div>
        </Form>
      </div>

      <TermsAndConditions />

      <div className={styles.bottomBlock}>
        <p className={styles.boldLine}>{jt.header?.alreadyAMember || "Already a Member"}?</p>
        <p className={styles.secondLine}>
          {jt.block?.membersLogin || "Log in and fill out the survey to see how you compare"}!
        </p>
        <LinkContainer to={'/login'}>
          <button className={styles.bottomBtn}>
            {jt.label?.goToLogin || "Go to login"}
          </button>
        </LinkContainer>
      </div>

      <Footer />
    </div>
  )
}

export default Register
