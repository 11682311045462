import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import Footer from '../Footer/Footer'
import LogosHeader from '../common/LogosHeader/LogosHeader'
import ResearchBadge from '../common/ResearchBadge/ResearchBadge'
import styles from './Welcome.module.scss'

// const grayBg = CONSTANTS.colors.grayCardBackground // '#F2F2F2' gray background for even-index module cards
// const whiteBg = CONSTANTS.colors.white // '#fffff' white background for uneven-index module cards

// welcome screen at route '/'
const Welcome = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  let loggedIn = user.strapiJwt ? true : false
  const moduleArray = []
  for (let i = 0; i <= 5; i++) {
    moduleArray.push({
      module: jt.header?.[`module${i}`] || `Module ${i}`,
      title: jt.header?.[`module${i}Title`] || `Module ${i}`,
      description: jt.block?.[`module${i}Description`] || `Module ${i}`,
    })
  }
  // move Module 0 to the end of the array
  moduleArray.push(moduleArray.shift())
  // console.log('moduleArray:', moduleArray)
  const module0Index = 5
  const module1Index = 0

  return (
    <div>
      <div className={styles.pictureBackground}>
        <ResearchBadge jtLabel={jt.label || {}} />
        <h1>{jt.label?.npoBenchmark || 'NPO Benchmark'}</h1>
        <p>
          {jt.block?.benchmarkPresentation || 'We are a group of specialists from Freiburg University in Switzerland'}
        </p>
        <div className={styles.buttons}>
          <Row className='align-items-center'>
            <Col md="auto">
              <LinkContainer to={loggedIn ? '/survey' : '/register'}>
                <button className={styles.headerBtn}>
                  {jt.label?.startFullSurvey || "Start full survey"}
                </button>
              </LinkContainer>
            </Col>
            <Col>
              <LinkContainer to={'/demo'}>
                <button className={styles.secondBtn}>{jt.label?.startDemo || 'Start Demo'}</button>
              </LinkContainer>
            </Col>
          </Row>
        </div>
      </div>

      <LogosHeader jtLabel={jt.label || {}} />

      <div className={styles.titleBlock}>
        <div className={styles.title}>
          {jt.header?.practicalInsights || 'Practical Insights for your NPO'}
        </div>
        <div className={styles.subtitle}>
          {jt.block?.takingPartWillHelpYou || 'Taking part in our survey will help you accomplish your goals.'}
        </div>
      </div>

      <div className={styles.moduleContainer}>
        <div className={styles.mod1Item}>
          <h6 className={styles.module}>{moduleArray[module1Index].module}</h6>
          <h2 className={styles.moduleTitle}>{moduleArray[module1Index].title}</h2>
          <p className={styles.moduleDescription}>{moduleArray[module1Index].description}</p>
        </div>

        <Row className={styles.verticalSection}>
          {moduleArray.map((mod, index) => {
            if (index === module1Index || index === module0Index) return null  // skip modules 1 and 0
            return (
              <div className={styles.verticalItem} key={index}>
                <h6 className={styles.module}>{mod.module}</h6>
                <h2 className={styles.moduleTitle}>{mod.title}</h2>
                <p className={styles.moduleDescription}>{mod.description}</p>
              </div>
            )
          })}
        </Row>

        <div className={styles.mod0Item}>
          <h6 className={styles.module}>{moduleArray[module0Index].module}</h6>
          <h2 className={styles.moduleTitle}>{moduleArray[module0Index].title}</h2>
          <p className={styles.moduleDescription}>{moduleArray[module0Index].description}</p>
        </div>

      </div>

      {/*<div className={styles.moduleCardContainer}>*/}
      {/*  {moduleArray.map((mod, idx) => {*/}
      {/*    return (*/}
      {/*      <div*/}
      {/*        key={idx}*/}
      {/*        className={styles.moduleCard}*/}
      {/*        style={{*/}
      {/*          backgroundColor: [0, 3, 4].includes(idx) ? grayBg : whiteBg,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <h2 className={styles.moduleTitle}>{mod.title}</h2>*/}
      {/*        <p className={styles.moduleDescription}>{mod.description}</p>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</div>*/}

      <div className={styles.bottomBlock}>
        <p className={styles.boldLine}>
          {jt.block?.benchmarkYieldsResults || 'NPOs who use the Benchmark achieve better results.'}
        </p>
        <p className={styles.secondLine}>
          {jt.block?.beginYourAssessment || 'Start your survey and begin the assessment of your NPO.'}
        </p>
        <LinkContainer to={'/register'}>
          <button className={styles.bottomBtn}>
            {jt.label?.registerNow || "Register now"}
          </button>
        </LinkContainer>
      </div>
      <Footer />
    </div>
  )
}

export default Welcome
