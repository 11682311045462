import { LinkContainer } from 'react-router-bootstrap'
import { useSelector } from 'react-redux'
import styles from './RegisterNowCTA.module.scss'

/**
 * Creates the 'Register now!' call to action component at the bottom of the page
 * Called by ForgotPassword, Login, OrgAnon, OrgCreate, OrgSelect and ResetPassword.js
 */
const RegisterNowCTA = () => {
  const jt = useSelector(state => state.jsonText.jsonText)

  return (
    <div className={styles.bottomBlock}>
      <p className={styles.boldLine}>{jt.header?.registerNow || 'Register now!'}</p>
      <p className={styles.secondLine}>{jt.block?.registerNow || 'Register now.'}</p>
      <LinkContainer to={'/register'}>
        <button className={styles.registerBtn}>{jt.label?.registerNow || 'Register now'}</button>
      </LinkContainer>
    </div>
  )
}
export default RegisterNowCTA
