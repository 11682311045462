import { toast } from 'react-toastify'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'

export const answerSavedMsg = (showNavBtn) => {
  toast.success(
    <MsgAndNavBtn showNavBtn={showNavBtn} />,
    { autoClose: 4000 },
  )
}

const MsgAndNavBtn = ({ closeToast, showNavBtn }) => {
  const jt = useSelector(state => state.jsonText.jsonText)
  return <div>
    <div style={{ marginBottom: '10px' }}>
      {jt.toast?.answerSaveSuccess || 'Answers successfully saved'}
    </div>
    {showNavBtn &&
      <Button
        variant={'secondary'}
        size={'sm'}
        onClick={() => {
          window.location.href = '/survey'
          closeToast()
        }}
      >
        {jt.label?.backToMainSurveyPage || 'Back to main survey page'}
      </Button>
    }
  </div>
};
