import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  answers: []
};

export const answerSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    addAnswers: (state, action) => {
      state.answers.push(action.payload)
    },
    changeAnswers: (state, action) => {
      state.answers = state.answers.map( answerSet => {
        if (answerSet.module === action.payload.module) {
          return action.payload
        }
        return answerSet
      })
    },
    deleteAnswers: (state, action) => {
      state.answers = state.answers.filter( answerSet =>
        answerSet.module !== action.payload.module
      )
    },
  }
});

export const {addAnswers, changeAnswers, deleteAnswers} = answerSlice.actions;
export default answerSlice.reducer;
