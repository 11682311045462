import React from 'react'
import { Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer'
import kultiviererLogo from '../../../assets/images/Logo-Kultivierer.png'
import vmiLogo from '../../../assets/images/Logo-VMI.png'
import { pdfStyles } from './pdfStyles'
import { KULTIVIERER_URL, VMI_URL } from '../../../utils/constants'

const styles = StyleSheet.create({
  contactContainer: {
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftContainer: {
    // textAlign: 'right',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  line: {
    ...pdfStyles.line,
    marginTop: 20,
  },
  logoKultivierer: {
    marginTop: 5,
    marginRight: 10,
    height: 40,
    // width: 40,
  },
  logoVmi: {
    marginTop: 15,
    marginRight: 10,
    height: 40,
    width: 60,
  },
  rightContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 12,
  },
  section: pdfStyles.section,
  sectionVMI: {
    marginTop: 20,
  },
  titleMedium: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 20,
  },
})

const PdfContact = ({ jt, module }) => {
  const pageBreak = (module === 'module1' || module === 'module2') ? true : false
  return (
    <View style={styles.section} break={pageBreak}>
      {!pageBreak && <View style={styles.line} />}
      <Text style={styles.titleMedium}>
        {jt.header?.forFurtherInfoOrCoachingContact || 'For further information or a coaching appointment, please contact:'}
      </Text>
      <View style={styles.contactContainer}>
        <View style={styles.leftContainer}>
          <Link src={KULTIVIERER_URL}>
            <Image src={kultiviererLogo} style={styles.logoKultivierer} />
          </Link>
          <Link src={VMI_URL}>
            <Image src={vmiLogo} style={styles.logoVmi} />
          </Link>
        </View>
        <View style={styles.rightContainer}>
          <View>
            <Text>
              <Link src={KULTIVIERER_URL}>
                {jt.header?.kultiviererTitle || 'Kultivierer GmbH – für Menschen & Organisationen.'}
              </Link>
            </Text>
            <Text>
              {jt.label?.jonasBaumannFuchs || 'Jonas Baumann-Fuchs'}
            </Text>
            <Text>
              {jt.label?.jonasEmail || 'info@kultivierer.ch'}
            </Text>
          </View>
          <View style={styles.sectionVMI}>
            <Text>
              <Link src={VMI_URL}>
                {jt.header?.vmiTitle || 'Institut für Verbands-, Stiftungs- und Genossenschaftsmanagement (VMI).'}
              </Link>
            </Text>
            <Text>
              {jt.label?.markusGmuer || 'Markus Gmuer'}
            </Text>
            <Text>
              {jt.label?.markusEmail || 'info@vmi.ch'}
            </Text>
            <Text>
              {jt.label?.linkVmiFurtherEducation || 'www.vmi.ch/de/weiterbildung'}
            </Text>
          </View>
        </View>

      </View>
    </View>
  )
}


export default PdfContact
