import { CONSTRUCTS } from '../../../utils/constants'
import { getDataForChart } from '../../../utils/reactChartConfig'
import { onlyPreserveValidData } from './onlyPreserveValidData'

/**
 * called by ResultsModule4.js
 * @param benchMod4All, object, benchmark of all organization categories
 * @param benchMod4Cat, object, benchmark of user's organization category
 * @param mod4AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod4CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param type, string, either 'summary' (overall result) or the section name (not yet used)
 * @return object, the chart data for Module 4
 */
export const getChartDataMod4 = (jtLabels, benchMod4All, benchMod4Cat, mod4AllResults, mod4CatResults, type) => {
  let chartLabels, benchAllData, benchCatData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.COMPLEXITY.name] || 'Complexity'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.FUNDING_OPPORTUNITY.name] || 'Funding Opportunities'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.HOSTILITY.name] || 'Hostility'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.LATITUDE.name] || 'Latitude'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod4All.KMP.stdDev === 0 ? 100 : 50,
        benchMod4All.MUN.stdDev === 0 ? 100 : 50,
        benchMod4All.HST.stdDev === 0 ? 100 : 50,
        benchMod4All.FRE.stdDev === 0 ? 100 : 50,
      ]
      benchCatData = [
        benchMod4Cat?.KMP.percentile,
        benchMod4Cat?.MUN.percentile,
        benchMod4Cat?.HST.percentile,
        benchMod4Cat?.FRE.percentile,
      ]
      allResultsData = [
        mod4AllResults.KMP.percentile,
        mod4AllResults.MUN.percentile,
        mod4AllResults.HST.percentile,
        mod4AllResults.FRE.percentile,
      ]
      catResultsData = [
        mod4CatResults?.KMP.percentile,
        mod4CatResults?.MUN.percentile,
        mod4CatResults?.HST.percentile,
        mod4CatResults?.FRE.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }
  // if the category benchmark or category results are undefined, null or 0,
  // set the data to null so that they will not be included in chart
  benchCatData = onlyPreserveValidData(benchCatData)
  // if there is no benchmark for this category, the results based on this are irrelevant, also.
  catResultsData = benchCatData ? onlyPreserveValidData(catResultsData) : null
  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatData, allResultsData, catResultsData)
}
