import { Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip } from 'chart.js'
import { Radar } from 'react-chartjs-2'
import { Col, Container, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { addAnswers, deleteAnswers } from '../../features/answers/answerSlice'
import { useNavigate } from 'react-router-dom'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import BookConsultationCTA from '../common/BookConsultationCTA/BookConsultationCTA'
import Footer from '../Footer/Footer'
import { CONSTRUCTS } from '../../utils/constants'
import {
  BENCH_ALL_BG,
  BENCH_ALL_BORDER,
  BORDER_THICKNESS,
  NPO_VS_ALL_BG,
  NPO_VS_ALL_BORDER,
} from '../../utils/reactChartConfig'
import styles from './ResultsDemo.module.scss'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

const RADAR_OPTIONS = {
  responsive: true,
  maintainAspectRatio: true,
  scale: {
    min: 0,
    max: 6,
    ticks: {
      stepSize: 1
    }
  }
}

const API_URL = process.env.REACT_APP_API_URL

// render the demo survey results
const ResultsDemo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const answers = useSelector(state => state.answers.answers)
  const [BD_PRO, setBD_PRO] = useState(null)
  const [BD_INN, setBD_INN] = useState(null)
  const [BD_RSK, setBD_RSK] = useState(null)
  const [BD_AUT, setBD_AUT] = useState(null)
  const [BD_AGR, setBD_AGR] = useState(null)

  useEffect(() => {
    /* if answers do not exist in Redux (page reload) get them from local storage
       and store them in Redux. */
    if (!answers.length) {
      const answerObj = JSON.parse(localStorage.getItem('demo') || '""')
      if (answerObj) {
        const answerPayload = {
          module: 'demo',
          answers: answerObj,
        }
        dispatch(addAnswers(answerPayload))
      }
    }
    axios
      .get(`${API_URL}/api/benchmark-demos`)
      .then(res => {
        const benchmarkDemo = res.data.data[0].attributes.benchmarkDemo
        setBD_PRO(benchmarkDemo.BD_PRO.mean)
        setBD_INN(benchmarkDemo.BD_INN.mean)
        setBD_RSK(benchmarkDemo.BD_RSK.mean)
        setBD_AUT(benchmarkDemo.BD_AUT.mean)
        setBD_AGR(benchmarkDemo.BD_AGR.mean)
      })
      .catch(error => handleStrapiAPIError(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let data = {}
  let demoAnswers = null
  if (answers.length) {
    demoAnswers = answers.find(
      (answerSet) => answerSet.module === 'demo',
    )?.answers
    if (demoAnswers) {
      const PRO =
        (demoAnswers.PRO2 + demoAnswers.PRO3 + demoAnswers.PRO5) / 3
      const INN = (demoAnswers.INN1 + demoAnswers.INN2) / 2
      const RSK =
        (demoAnswers.RSK3 + demoAnswers.RSK5b + demoAnswers.RSK7b) / 3
      const AUT = demoAnswers.AUT1
      const AGR = demoAnswers.AGR5
      data = {
        labels: [
          `${jt.label?.[CONSTRUCTS.AGGRESSIVENESS.name] || 'Aggressiveness'}`.toUpperCase(),
          `${jt.label?.[CONSTRUCTS.AUTONOMY.name] || 'Autonomy'}`.toUpperCase(),
          `${jt.label?.[CONSTRUCTS.INNOVATION.name] || 'Innovation'}`.toUpperCase(),
          `${jt.label?.[CONSTRUCTS.PROACTIVITY.name] || 'Proactivity'}`.toUpperCase(),
          `${jt.label?.[CONSTRUCTS.RISK_TAKING.name] || 'Risk-taking'}`.toUpperCase(),
        ],
        datasets: [
          {
            label: jt.label?.yourResults || 'Your Results',
            data: [AGR, AUT, INN, PRO, RSK],
            backgroundColor: NPO_VS_ALL_BG,
            borderColor: NPO_VS_ALL_BORDER,
            borderWidth: BORDER_THICKNESS,
          },
          {
            label: jt.label?.demoBenchmark || 'Demo Benchmark',
            data: [BD_AGR, BD_AUT, BD_INN, BD_PRO, BD_RSK],
            backgroundColor: BENCH_ALL_BG,
            borderColor: BENCH_ALL_BORDER,
            borderWidth: BORDER_THICKNESS,
          },
        ]
      }
    }
  }

  const onFullSurveyBtn = () => {
    // on leaving page, clean up localStorage and Redux
    localStorage.removeItem('demo')
    const answerPayload = {
      module: 'demo',
      answers: demoAnswers,
    }
    dispatch(deleteAnswers(answerPayload))
    user.userID ? navigate('/survey') : navigate('/login')
  }

  return (
    <div>
      <Container className={styles.mainContainer}>
        {demoAnswers ? (
          <>
            <Row>
              <Col xs={12} className={styles.headerContainer}>
                <h1>
                  {jt.header?.demoResults || 'Demo survey results'}
                </h1>
                <p className={styles.subheader}>
                  {jt.block?.demoResultsSubheader ||
                    'Hier you can see a preview of how how your NPO performs in regard to ' +
                    'Proactivity, Innovation, Risk-Taking and Aggressiveness,'}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Radar
                  data={data}
                  options={RADAR_OPTIONS}
                  className={styles.radar}
                />
              </Col>
              <Col xs={6} className={styles.rightCol}>
                <BookConsultationCTA demo={true} />
              </Col>
            </Row>
            <br />
            <h2>
              {jt.header?.takeFullSurvey || 'Start taking the full survey now'}!
            </h2>
            <button
              className={styles.bottomBtn}
              onClick={onFullSurveyBtn}
            >
              {user.userID ? (
                <span>{jt.label?.goToFullSurvey || 'Go to the full survey now'}!</span>
              ) : (
                <span>{jt.label?.loginAndGoToFullSurvey || 'Log in and then go to the full survey'}!</span>
              )}
            </button>

          </>) : (
          <div>
            <h1>
              {jt.header?.demoResults || 'Demo survey results'}
            </h1>
            <p className={styles.subheader}>
              {jt.block?.demoResultsInstructions ||
                'To see your results, first fill out the survey.\nDo not forget to SAVE your progress'
              }!
            </p>
            <LinkContainer to={'/demo/survey'}>
              <button className={styles.bottomBtn}>
                {jt.label?.startDemoSurvey || 'Start demo survey now'}
              </button>
            </LinkContainer>
          </div>
        )}
      </Container>
      <Footer/>
    </div>
  )
}

export default ResultsDemo
