import { useEffect, useState } from "react";
import QuestionForm from "../common/QuestionForm/QuestionForm";
import QuestionFormRadio from '../common/QuestionFormRadio/QuestionFormRadio'
import { AGROptionalQs, giveAnswer } from '../../utils/giveAnswer'
import styles from './Module1.module.scss'

// render the AGR section of Module 1 (Step 2) of the full survey; called by Module1.js
const Module1AGR = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}); // future object like { "AGR0": 1, "AGR1": 0, ...}

  useEffect(()=>{
    /* set up initialAnswerObj: { AGR0: null, AGR1: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar, if AGR0 has been answered 'none' ('keine'), reduce questions from 7 to 4
      const numAgrQs = previousAnswers.AGR0 === questions[0].answerArray[0] ?
        questions.length - AGROptionalQs.length
        : questions.length
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'AGR', numAgrQs)
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module1.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[previousAnswers])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      // if first question has been answered 'keine', hide questions AGR1-3
      if (idx >= 1 && idx <= 3 && answerObj[questions[0].questionID] === questions[0].answerArray[0]) {
        return null
      }
      return <div key={question.questionID}>
        {idx === 0 ?
          <QuestionFormRadio
            questionIndex={idx}
            question={question.question1}
            questionFormat={question.format}
            answers={question.answerArray}
            currentAnswer={answerObj[question.questionID]}
            giveAnswer={(val, questionIndex) => giveAnswer(
              'AGR', val, questionIndex, null, questions, answerObj, setAnswerObj, updateAnswers, updateProgress
            )}
          />
          :
          <div>
            <hr className={styles.horizontalLine}></hr>
            <QuestionForm
              questionIndex={idx}
              question1={question.question1}
              question2={question.question2}
              questionFormat={question.format}
              answerLabels={question.answerLabels || 'default'}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'AGR', val, questionIndex, null, questions, answerObj, setAnswerObj, updateAnswers, updateProgress
              )}
            />
          </div>
        }

      </div>
    })}
    <hr className={styles.horizontalLine}></hr>
  </div>)
}

export default Module1AGR;
