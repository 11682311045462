import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { CONSTRUCTS } from '../../../utils/constants'
import { pdfStyles } from './pdfStyles'

const styles = StyleSheet.create({
  paragraph: pdfStyles.paragraph,
  paragraphGray: {
    fontSize: 12,
    marginBottom: 5,
    backgroundColor: '#f2f2f2',
  },
  percentBlock: {
    flexDirection: 'row',
    marginBottom: 3,
  },
  percentLabel: {
    fontSize: 12,
  },
  percentRank: {
    fontSize: 12,
    fontWeight: 600,
  },
  section: pdfStyles.section,
  sectionGray: {
    backgroundColor: '#f2f2f2',
  },
  sectionPercent: {
    marginTop: 8,
  },
  titleMedium: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 15,
    marginBottom: 5,
  },
})

const MODULE_CONSTRUCT_SHORTS = [ // use to avoid repeating Module descriptions
  CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION.short,
]
// render constructs, explanations and evaluations
const PdfConstructs = ({ jt, constructs, modAllResults, modCatResults, feedbackTexts }) => (
  <View style={styles.section}>
    {constructs.map(construct => {
      // skip the module description already handled above
      if (MODULE_CONSTRUCT_SHORTS.includes(construct.short)) return null
      return (
        <View key={construct.name}>
          <Text style={styles.titleMedium}>
            {jt.label?.[construct.name] || 'construct name loading...'}
          </Text>

          <View style={styles.sectionGray}>
            <Text style={styles.paragraph}>
              {jt.block?.[`${construct.name}Definition`] || 'Construct definition loading...'}
            </Text>
            <Text style={styles.paragraph}>
              {jt.block?.[`${construct.name}Usefulness`] || 'Discussion of this construct loading...'}
            </Text>
          </View>

          <View style={styles.sectionPercent}>
            <View style={styles.percentBlock}>
              <Text style={styles.percentLabel}>
                {jt.label?.yourPercentileAllNPOs || 'Your percentile in relation to all NPOs'}
                {': '}
              </Text>
              <Text style={styles.percentRank}>
                {modAllResults[construct.short].percentile}%
              </Text>
            </View>
            <View style={styles.percentBlock}>
              <Text style={styles.percentLabel}>
                {jt.label?.yourPercentileNPOsSameField || 'Your percentile in relation to NPOs in the same field'}
                {': '}
              </Text>
              <Text style={styles.percentRank}>
                {modCatResults?.[construct.short].percentile ?? '-'}%
              </Text>
            </View>
          </View>

          <Text style={styles.paragraph}>
            {feedbackTexts[construct.short] || `This result indicates that you ...loading...`}
          </Text>
        </View>
      )
    })}
  </View>
)


export default PdfConstructs
