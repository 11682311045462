import React from 'react'
import { Table } from 'react-bootstrap'
import { roundToPlace } from '../../../utils/statisticCalc'
import { CONSTRUCTS } from '../../../utils/constants'
import styles from './ResultsTable.module.scss'

const modules = [ // module name for summary at end of table
  CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION.name,
]
const sections = [ // main sub-modules or sections
  CONSTRUCTS.ACTIVATION.name,
  CONSTRUCTS.FUTURE_ORIENTATION.name,
]
const preSections = [  // construct right before the above section construct to darken border-bottom-color
  CONSTRUCTS.AGGRESSIVENESS.name,
  CONSTRUCTS.RISK_TAKING.name,
]

/**
 * Renders the table of the results from each Results page
 * @param jtLabel, object, 'label' portion of the jsonText object from Redux
 * @param benchModAll, object
 * @param benchModCat, object
 * @param modAllResults, object
 * @param modCatResults, object
 * @param constructs, [object] { name: <name>, short: <three-letter-abbreviation> }
 * @return {JSX.Element}
 */
function ResultsTable({ jtLabel, benchModAll, benchModCat, modAllResults, modCatResults, constructs }) {

  const getTrClassName = (constructName) => {
    if (modules.includes(constructName)) return styles.module
    if (sections.includes(constructName)) return styles.section
    if (preSections.includes(constructName)) return styles.preSection
    return styles.tableData
  }

  return (
    <Table bordered striped className={styles.table}>
      <thead className={styles.tableHead}>
      <tr>
        <th rowSpan={2}>
          {jtLabel.construct || 'Construct'}
        </th>
        <th colSpan={2}>
          {jtLabel.benchmarkAllNPOs || 'Benchmark of all NPOs'}
        </th>
        {modCatResults &&
          <th colSpan={2}>
            {jtLabel.benchmarkNPOsOfSameField || 'Benchmark of NPOs in same field'}
          </th>
        }
        <th colSpan={modCatResults ? 3 : 2}>
          {jtLabel.yourNpoResults || 'Your NPO Results'}
        </th>
      </tr>
      <tr className={styles.tableHeadBottomRow}>
        <th>{jtLabel.mean || 'Mean'}</th>
        <th>{jtLabel.standardDeviation || 'Std. Dev.'}</th>
        {modCatResults && <th>{jtLabel.mean || 'Mean'}</th>}
        {modCatResults && <th>{jtLabel.standardDeviation || 'Std. Dev.'}</th>}
        <th>{jtLabel.mean || 'Mean'}</th>
        <th>{jtLabel.yourPercentileAllNPOs || '% Rank - All NPOs'}</th>
        {modCatResults && <th>{jtLabel.yourPercentileNPOsSameField || '% Rank - NPOs Same Field'}</th>}
      </tr>
      </thead>
      <tbody className={styles.tableBody}>
      {modAllResults && Object.keys(modAllResults).map(key => {
        // console.log('Object.keys(modAllResults):', Object.keys(modAllResults))
        const construct = constructs.find(construct => construct.short === key)
        return <tr key={key} className={getTrClassName(construct.name)}>
          <td>{jtLabel[construct.name] || 'Waiting to get Construct...'}</td>
          <td>{benchModAll && roundToPlace(benchModAll[construct.short].mean, 1)}</td>
          <td>{benchModAll && roundToPlace(benchModAll[construct.short].stdDev, 1)}</td>
          {modCatResults && <td>{benchModCat && roundToPlace(benchModCat[construct.short].mean, 1)}</td>}
          {modCatResults && <td>{benchModCat && roundToPlace(benchModCat[construct.short].stdDev, 1)}</td>}
          <td>{modAllResults && roundToPlace(modAllResults[construct.short].mean, 1)}</td>
          <td>{modAllResults && roundToPlace(modAllResults[construct.short].percentile, 1)}</td>
          {modCatResults && <td>{roundToPlace(modCatResults[construct.short].percentile, 1)}</td>}
        </tr>
      })}
      </tbody>
    </Table>
  )
}

export default ResultsTable
