import { MIN_MONTHS_BETWEEN_SURVEYS } from './constants'
import { formatDate } from './formatDate'

/**
 * returns the date of the last valid survey
 * that is, today's date minus 11 months (or whatever the current MIN_MONTHS_BETWEEN_SURVEYS is)
 * @return {string} in format 'YYYY-MM-DD' (for use in Strapi-API filter)
 */
export const getDateOfLastValidSurvey = () => {
  const lastValidDate = new Date()
  lastValidDate.setMonth(lastValidDate.getMonth() - MIN_MONTHS_BETWEEN_SURVEYS)
  return lastValidDate.toISOString().split('T')[0] // formats to YYYY-MM-DD
}

/**
 * returns the date when the next survey can be taken
 * that is, the last completed survey date plus 11 months (or whatever the current MIN_MONTHS_BETWEEN_SURVEYS is)
 * @param dateOfLastCompletedSurvey, string, format 'DD.MM.YYYY'
 * @return {string} if date is still in the future: in format 'DD.MM.YYYY', else ''
 */
export const getDateOfNextAllowedSurvey = (dateOfLastCompletedSurvey) => {
  // Split the dateString by dots and convert to day, month, year
  const [day, month, year] = dateOfLastCompletedSurvey.split('.').map(Number)

  // Create a Date object using the extracted values (note: month is 0-based in JS)
  const nextDate = new Date(year, month - 1, day)

  // add 11 months
  nextDate.setMonth(nextDate.getMonth() + MIN_MONTHS_BETWEEN_SURVEYS)

  // Get the current date without time
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Set the time to midnight to only compare dates

  return nextDate > today ? formatDate(nextDate) : ''
}
