import { updateLanguage } from '../features/user/userSlice'
import { DEFAULT_LANGUAGE } from './constants'

/**
 * called by Header.js, HeaderProfile.js
 * @param user, JSON object from Redux
 * @param dispatch, function to enable updating the Redux user object with the localStorage language
 * @return string, the current user language as a lowercase two-letter code
 */
export const getCurrentLanguage = (user, dispatch) => {
  let lang
  if (user.language) lang = user.language // redux takes priority
  else {
    const localStorageLang = localStorage.getItem('language') // otherwise get from localstorage
    if (localStorageLang) {
      lang = localStorageLang
      dispatch(updateLanguage({ language: lang }))  // update Redux user with this new language
    } else lang = DEFAULT_LANGUAGE  // as a final option, use the default language
  }
  return lang
}
