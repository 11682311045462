import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css' // TODO: change this to import from scss file. (add in one of our scss files or import into scss as we do it with _variables.scss)
import About from './components/About/About'
import ChangePassword from './components/ChangePassword/ChangePassword'
import Contact from './components/Contact/Contact'
import DataPolicy from './components/DataPolicy/DataPolicy'
import Demo from './components/Demo/Demo'
import EditOrg from './components/EditOrg/EditOrg'
import EmailConfirmed from './components/EmailConfirmed/Emailconfirmed'
import ForgotPassword from './components/ForgotPassword/ForgotPassword'
import Header from './components/Header/Header'
import LegalNotice from './components/LegalNotice/LegalNotice'
import Login from './components/Login/Login'
import Module0 from './components/Module0/Module0'
import Module1 from './components/Module1/Module1'
import Module2 from './components/Module2/Module2'
import Module3 from './components/Module3/Module3'
import Module4 from './components/Module4/Module4'
import Module5 from './components/Module5/Module5'
import News from './components/News/News'
import OrgAnon from './components/Organization/OrgAnon'
import OrgCreate from './components/Organization/OrgCreate'
import OrgSelect from './components/Organization/OrgSelect'
import Profile from './components/Profile/Profile'
import Register from './components/Register/Register'
import ResetPassword from './components/ResetPassword/ResetPassword'
import ResultsDemo from './components/ResultsDemo/ResultsDemo'
import ResultsModule1 from './components/Results/ResultsModule1'
import ResultsModule2 from './components/Results/ResultsModule2'
import ResultsModule3 from './components/Results/ResultsModule3'
import ResultsModule4 from './components/Results/ResultsModule4'
import ResultsModule5 from './components/Results/ResultsModule5'
import SurveyDemo from './components/SurveyDemo/SurveyDemo'
import SurveyFull from './components/SurveyFull/SurveyFull'
import Welcome from './components/Welcome/Welcome'
import WelcomeBack from './components/Welcome/WelcomeBack'
import WebFont from 'webfontloader'
import './App.scss'

WebFont.load({
  google: {
    families: [
      'Barlow: 200,300,400,600',
      // 'Source Sans Pro: 300,400,700',
      'Work Sans:300,400,600', // for the ClickMaster contact form
    ],
  },
})

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/about" element={<About />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/data-policy" element={<DataPolicy />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/demo/survey" element={<SurveyDemo />} />
          <Route path="/demo/results" element={<ResultsDemo />} />
          <Route path="/email-confirmed" element={<EmailConfirmed />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/legal' element={<LegalNotice />} />
          <Route path='/login' element={<Login />} />
          <Route path='/news' element={<News />} />
          <Route path='/organization-edit' element={<EditOrg />} />
          <Route path='/organization-anon' element={<OrgAnon />} />
          <Route path='/organization-create' element={<OrgCreate />} />
          <Route path='/organization-select' element={<OrgSelect />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/register' element={<Register />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          {/*'Overall results' page obsolete since 26.6.24 as per Jonas' email of 20.6.24 Point E*/}
          {/*<Route path="/results" element={<Results />} />*/}
          <Route path='/results' element={<ResultsModule1 />} />
          {/*<Route path="/results/module1" element={<ResultsModule1 />} />*/}
          <Route path='/results/module2' element={<ResultsModule2 />} />
          <Route path='/results/module3' element={<ResultsModule3 />} />
          <Route path='/results/module4' element={<ResultsModule4 />} />
          <Route path='/results/module5' element={<ResultsModule5 />} />
          <Route path='/survey' element={<SurveyFull />} />
          <Route path='/survey/module0' element={<Module0 />} />
          <Route path='/survey/module1' element={<Module1 />} />
          <Route path='/survey/module2' element={<Module2 />} />
          <Route path='/survey/module3' element={<Module3 />} />
          <Route path='/survey/module4' element={<Module4 />} />
          <Route path="/survey/module5" element={<Module5 />} />
          <Route path="/welcome-back" element={<WelcomeBack />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        theme="dark"
        position="bottom-center"
        className={'toast-message'}
      />
    </div>
  )
}

export default App
