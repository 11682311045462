import { useSelector } from 'react-redux'
import { Col, Container, Image, Row } from 'react-bootstrap'
import BVMLogo from '../../assets/images/Logo-BVM.png'
import kultiviererLogo from '../../assets/images/Logo-Kultivierer.png'
import VMILogo from '../../assets/images/Logo-VMI.png'
import Footer from '../Footer/Footer'
import LogosHeader from '../common/LogosHeader/LogosHeader'
import ResearchBadge from '../common/ResearchBadge/ResearchBadge'
import { BVM_URL, KULTIVIERER_URL, VMI_URL } from '../../utils/constants'
import styles from './About.module.scss'

const About = () => {
  const jt = useSelector(state => state.jsonText.jsonText)

  const sponsorArr = [
    {
      src: VMILogo,
      style: styles.image,
      alt: 'VMI Logo',
      url: VMI_URL,
      about: jt.block?.aboutVMI || 'The VMI researches and teaches specifically on NPO management.',
    },
    {
      src: kultiviererLogo,
      style: styles.image,
      alt: 'Kultivierer Logo',
      url: KULTIVIERER_URL,
      about: jt.block?.aboutKultivierer || 'Jonas Baumann-Fuchs is an organizational consultant and psychotherapist.',
    },
    {
      src: BVMLogo,
      style: styles.bvmImage,
      alt: 'BVM Logo',
      url: BVM_URL,
      about: jt.block?.aboutBVM || 'BVM supports NPOs with coaching, transfer of knowledge and practical implementation.',
    },
  ]

  return (
    <div>
      <div className={styles.pictureBackground}>
        <ResearchBadge jtLabel={jt.label || {}} />
        <h1>
          {jt.label?.aboutUs || 'About Us'}
        </h1>
      </div>

      <LogosHeader jtLabel={jt.label || {}} />

      <Container className={styles.mainContent}>
        {/*<div className={styles.title}>Container Title</div>*/}
        <p>
          {jt.block?.aboutUsFirstLine || 'The idea behind this platform is a research project by Jonas Baumann-Fuchs,'}
          {' '}
          <span onClick={() => window.open(KULTIVIERER_URL)}>
            {jt.label?.kultiviererGmbH || 'Kultivierer GmbH'}
          </span>
          .<br />
          {jt.block?.aboutUsMainText || 'In his masters thesis...'}
        </p>

        {sponsorArr.map(sponsor => {
          return (
            <Row className={'align-items-center'} key={sponsor.url}>
              <Col xs={6} md={4} lg={3}>
                <Image
                  src={sponsor.src}
                  className={sponsor.style}
                  alt={sponsor.alt}
                  onClick={() => window.open(sponsor.url)}
                />
              </Col>
              <Col className={styles.rightCol}>
                {sponsor.about}
              </Col>
            </Row>
          )
        })}

        <br />
      </Container>

      <Footer />
    </div>
  )
}

export default About
