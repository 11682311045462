import React from "react";
import { Button } from "react-bootstrap";
import styles from './EntryFormButton.module.scss';

const EntryFormButton = (props) => {
  return ( 
    <Button
      type="submit"
      className={props.small ? styles.smallEntryFormButton : styles.entryFormButton}
      disabled={props.disabled}
    >
      {props.buttonText}
    </Button>
  )

}

export default EntryFormButton;