import axios from 'axios'
import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Footer from '../Footer/Footer'
import RegisterNowCTA from '../common/RegisterNowCTA/RegisterNowCTA'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { REDIRECTION_TIMEOUT } from '../../utils/constants'
import { getOrgConnectSuccessMsg } from '../../utils/getMiscToastMsg'
import styles from './Organization.module.scss'

const API_URL = process.env.REACT_APP_API_URL

/* Allow a user to create a new anonymous organization.
   Called by OrgCreate.js */
const OrgAnon = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  // console.log('user:', user)
  const navigate = useNavigate()
  const [anonymousOrg, setAnonymousOrg] = useState('')
  const [anonymousOrgID, setAnonymousOrgID] = useState(null)

  useEffect(() => {
    // first create an organization with unique name to get latest organization record ID
    if (user.strapiJwt && !anonymousOrg) {
      axios
        .post(
          `${API_URL}/api/organizations`,
          {
            data: {
              name: `Anonymous-User-${user.userID}`,
              userID: 0,
            },
          },
          authHeaders()
        )
        .then((res) => {
          setAnonymousOrg('Anonymous-' + res.data.data.id) // new name to ensure uniqueness
          setAnonymousOrgID(res.data.data.id)
        })
        .catch((error) => handleStrapiAPIError(error))
    }
    // then save organization with new unique name
    if (user.strapiJwt && anonymousOrg) {
      axios
        .put(
          `${API_URL}/api/organizations/${anonymousOrgID}`,
          {
            data: { name: anonymousOrg },
          },
          authHeaders()
        )
        .then()
        .catch((error) => handleStrapiAPIError(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymousOrg, user.strapiJwt])

  const onFormSubmit = async (event) => {
    event.preventDefault()
    // first save the userID in the new anonymous organization
    await axios
      .put(
        `${API_URL}/api/organizations/${anonymousOrgID}`,
        {
          data: { userID: user.userID },
        },
        authHeaders()
      )
      .then()
      .catch((error) => handleStrapiAPIError(error))
    // then add this organization to the user
    axios
      .put(
        `${API_URL}/api/users/${user.userID}`,
        {
          organization: anonymousOrg,
          organizationID: anonymousOrgID,
        },
        authHeaders()
      )
      .then((res) => {
        toast.success(getOrgConnectSuccessMsg(
          anonymousOrg, user.firstName, jt.toast, 'create')
        )
        setTimeout(() => navigate('/welcome-back'), REDIRECTION_TIMEOUT)
      })
      .catch((error) => handleStrapiAPIError(error))
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>
          {jt.header?.createAnonymousOrg || "Create an anonymous organisation..."}
        </h1>
        <p>
          {jt.block?.createAnonOrgInstructions || "If your organization is already registered, please go back to the 'Select Organization' page."}
        </p>

        <Form onSubmit={onFormSubmit}>
          <Row className="g-3">
            <Col xs={6}>
              <Form.Control
                type="text"
                placeholder={jt.placeholder?.yourOrganizationsName || "Your organization's name"}
                readOnly
                value={anonymousOrg}
                className={styles.formEntry}
              />
            </Col>
            <Col xs={5}>
              <button type="submit" className={styles.headerBtn}>
                {jt.label?.confirm || "Confirm"}
              </button>
            </Col>
          </Row>
        </Form>
      </div>

      <RegisterNowCTA />
      <Footer />
    </div>
  )
}

export default OrgAnon
