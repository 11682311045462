/**
 * checks if a chart dataset contains valid data to remove irrelevant data from chart
 * @param data, array of percentiles|null values
 * @return {data|null} returns same array or null
 */
export const onlyPreserveValidData = (data) => {
  let validDataPresent = false
  for (const percentile of data) {
    if (typeof percentile === 'number') validDataPresent = true
  }
  return validDataPresent ? data : null
}
