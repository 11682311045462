import { getDataForChart } from '../../../utils/reactChartConfig'
import { CONSTRUCTS } from '../../../utils/constants'
import { onlyPreserveValidData } from './onlyPreserveValidData'

/**
 * called by ResultsModule5.js
 * @param benchMod5All, object, benchmark of all organization categories
 * @param benchMod5Cat, object, benchmark of user's organization category
 * @param mod5AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod5CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param type, string, either 'summary' (overall result) or the section name (not yet used)
 * @return object, the chart data for Module 5
 */
export const getChartDataMod5 = (jtLabels, benchMod5All, benchMod5Cat, mod5AllResults, mod5CatResults, type) => {
  let chartLabels, benchAllData, benchCatData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.SOCIAL_MISSION.name] || 'Social Mission'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.MOBILIZATION.name] || 'Mobilization'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.GROWTH.name] || 'Growth'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.PROFITABILITY.name] || 'Profitabilty'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.PERSONNEL_CHANGE.name] || 'Personnel Change'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod5All.SAZ.stdDev === 0 ? 100 : 50,
        benchMod5All.MOB.stdDev === 0 ? 100 : 50,
        benchMod5All.WAC.stdDev === 0 ? 100 : 50,
        benchMod5All.PRF.stdDev === 0 ? 100 : 50,
        benchMod5All.MIT.stdDev === 0 ? 100 : 50,
      ]
      benchCatData = [
        benchMod5Cat?.SAZ.percentile,
        benchMod5Cat?.MOB.percentile,
        benchMod5Cat?.WAC.percentile,
        benchMod5Cat?.PRF.percentile,
        benchMod5Cat?.MIT.percentile,
      ]
      allResultsData = [
        mod5AllResults.SAZ.percentile,
        mod5AllResults.MOB.percentile,
        mod5AllResults.WAC.percentile,
        mod5AllResults.PRF.percentile,
        mod5AllResults.MIT.percentile,
      ]
      catResultsData = [
        mod5CatResults?.SAZ.percentile,
        mod5CatResults?.MOB.percentile,
        mod5CatResults?.WAC.percentile,
        mod5CatResults?.PRF.percentile,
        mod5CatResults?.MIT.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }
  // if the category benchmark or category results are undefined, null or 0,
  // set the data to null so that they will not be included in chart
  benchCatData = onlyPreserveValidData(benchCatData)
  // if there is no benchmark for this category, the results based on this are irrelevant, also.
  catResultsData = benchCatData ? onlyPreserveValidData(catResultsData) : null
  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatData, allResultsData, catResultsData)
}
