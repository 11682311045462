
import { useEffect, useState } from "react";
import QuestionForm from "../common/QuestionForm/QuestionForm";
import QuestionFormRadio from '../common/QuestionFormRadio/QuestionFormRadio'
import { giveAnswer, KVE4OptionalQs } from '../../utils/giveAnswer'
import styles from './Module4.module.scss'

// render the KVE4 and KVE5 sections of Module 4 (Step 5) of the full survey; called by Module4.js
const Module4KVE45 = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}) // future object like { "KVE4a": "Ja", "KVE4b": 3, ...}

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    /* set up initialAnswerObj: { "KVE4a": null, "KVE4b": null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar, if KVE4a has been answered 'no' ('Nein'), reduce KVE45 question length from 6 to 2 (KVE4 has 5, KVE5 is only 1)
      const numKVE45Qs = previousAnswers.KVE4a === questions[0].answerArray[1] ?
        questions.length - KVE4OptionalQs.length
        : questions.length
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'KVE45', numKVE45Qs)
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module4.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      // if first question has been answered 'Nein', hide questions KVE4b-e
      if (idx >= 1 && idx <= 4 && answerObj[questions[0].questionID] === questions[0].answerArray[1]) {
        return null
      }
      return <div key={question.questionID}>
        {idx === 0 ?
          <div>
            <QuestionFormRadio
              questionIndex={idx}
              question={question.question1}
              questionFormat={question.format}
              answers={question.answerArray}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'KVE45', val, questionIndex, null, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
          :
          <div>
            <QuestionForm
              questionIndex={idx}
              question1={question.question1}
              question2={question.question2}
              questionFormat={question.format}
              answerLabels={question.answerLabels || 'default'}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'KVE45', val, questionIndex, null, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
        }

      </div>
    })}
  </div>)
}

export default Module4KVE45;
