import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import BookConsultationCTA from '../common/BookConsultationCTA/BookConsultationCTA'
import ChartAndNav from './ChartAndNav/ChartAndNav'
import Footer from '../Footer/Footer'
import ResultsHeader from './ResultsHeader/ResultsHeader'
import ResultsTable from './ResultsTable/ResultsTable'
import ResultsText from './ResultsText/ResultsText'
import { calcModResult } from './ResultsUtils/calcModResults'
import { getChartDataMod1 } from './ResultsUtils/getChartDataMod1'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { showNoCategoryBenchInfo } from '../../utils/getMiscToastMsg'
import { getFeedbackTexts } from '../../utils/getFeedbackTexts'
import { formatDate } from '../../utils/formatDate'
import { CONSTRUCTS, MIN_SAMPLE_SIZE } from '../../utils/constants'

const API_URL = process.env.REACT_APP_API_URL
const CONSTRUCTS_M1 = [ // the order here pertains only to the text rendering below the table
  CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION,
  CONSTRUCTS.FUTURE_ORIENTATION,
  CONSTRUCTS.PROACTIVITY,
  CONSTRUCTS.INNOVATION,
  CONSTRUCTS.RISK_TAKING,
  CONSTRUCTS.ACTIVATION,
  CONSTRUCTS.AUTONOMY,
  CONSTRUCTS.COMMUNITY_MOBILIZATION,
  CONSTRUCTS.COOPERATIVE_MOBILIZATION,
  CONSTRUCTS.AGGRESSIVENESS,
]

// render the Module 1 (Step 2) survey results; called by Results.js
const ResultsModule1 = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const [benchMod1All, setBenchMod1All] = useState(null) // benchmark of all NPOs
  const [benchMod1Cat, setBenchMod1Cat] = useState(null) // benchmark of user's NPO category
  const [mod1AllResults, setMod1AllResults] = useState(null)  // results compared with all NPOs
  const [mod1CatResults, setMod1CatResults] = useState(null)  // results compared with user's NPO category
  const [mod1BenchCatResults, setMod1BenchCatResults] = useState(null)  // NPO category compared with all NPOs
  const [mod1FeedbackTexts, setMod1FeedbackTexts] = useState({})
  const [chartImages, setChartImages] = useState({})
  const noCatBenchInfoHasBeenShown = useRef(null)

  useEffect(() => {
    // get latest benchmark
    if (user.strapiJwt && jt.toast) {
      // console.log('user:', user)
      if (!benchMod1All) { // only run this once
        axios
          .get(
            `${API_URL}/api/benchmarks` +
            `?filters[module][$eq]=1` +
            `&filters[orgCategory][$eq]=all`,
            authHeaders(),
          )
          .then(res => {
            const benchmarkModuleAll = res.data.data[0].attributes.benchmark
            setBenchMod1All(benchmarkModuleAll)
          })
          .catch(error => handleStrapiAPIError(error))
      }
      if (!benchMod1Cat) { // only run this once
        if (user.orgCategory) {
          axios
            .get(
              `${API_URL}/api/benchmarks` +
              `?filters[module][$eq]=1` +
              `&filters[orgCategory][$eq]=${user.orgCategory}`,
              authHeaders(),
            )
            .then(res => {
              const benchMod1CatData = res.data.data[0].attributes.benchmark
              // console.log('benchMod1CatData:', benchMod1CatData)
              if (benchMod1CatData.sample_size >= MIN_SAMPLE_SIZE) {
                setBenchMod1Cat(benchMod1CatData)
              } else {
                if (noCatBenchInfoHasBeenShown.current !== true) {
                  showNoCategoryBenchInfo(jt.toast)
                  noCatBenchInfoHasBeenShown.current = true
                }
                setBenchMod1Cat('Category not available')
              }
            })
            .catch(error => handleStrapiAPIError(error))
        } else setBenchMod1Cat('Category not available')
      }
      // get the latest answers for this user if they have fully completed this module
      if (benchMod1All && benchMod1Cat) {
        axios
          .get(
            `${API_URL}/api/npo-surveys` +
            `?filters[organizationID][$eq]=${user.orgID}` +
            `&filters[module][$eq]=1` +
            `&sort=createdAt:desc`,
            authHeaders(),
          )
          .then(res => {
            if (res.data.data.length === 0) return
            const answerObject = res.data.data[0].attributes.npoAnswers
            const resultsAll = calcModResult(1, benchMod1All, answerObject)
            const resultsCat = benchMod1Cat === 'Category not available' ? null
              : calcModResult(1, benchMod1Cat, answerObject)
            const resultsBenchCat = benchMod1Cat === 'Category not available' ? null
              : calcModResult(1, benchMod1All, benchMod1Cat)
            // console.log('resultsBenchCat:', resultsBenchCat)
            setMod1AllResults(resultsAll)
            setMod1CatResults(resultsCat)
            setMod1BenchCatResults(resultsBenchCat)
            const resultsForFB = resultsCat ? resultsCat : resultsAll
            setMod1FeedbackTexts(getFeedbackTexts(jt.results, resultsForFB, CONSTRUCTS_M1))
          })
          .catch(error => handleStrapiAPIError(error))
      }
    }
    // eslint-disable-next-line
  }, [jt, user, benchMod1All, benchMod1Cat])

  const chartData = mod1AllResults && benchMod1All ?
    {
      first: getChartDataMod1(
        jt.label,
        benchMod1All,
        mod1BenchCatResults,
        mod1AllResults,
        mod1CatResults,
        'summary',
      ),
      second: getChartDataMod1(
        jt.label,
        benchMod1All,
        mod1BenchCatResults,
        mod1AllResults,
        mod1CatResults,
        CONSTRUCTS.FUTURE_ORIENTATION.name,
      ),
      third: getChartDataMod1(
        jt.label,
        benchMod1All,
        mod1BenchCatResults,
        mod1AllResults,
        mod1CatResults,
        CONSTRUCTS.ACTIVATION.name,
      ),
    }
    : null

  const chartLabels = {
    first: jt.label?.mod1Overview || 'Module 1 Overview',
    second: jt.label?.[CONSTRUCTS.FUTURE_ORIENTATION.name] || 'Future Orientation',
    third: jt.label?.[CONSTRUCTS.ACTIVATION.name] || 'Future Orientation',
  }

  const pdfFileName = formatDate(new Date(), 'YYYY.MM.DD') + '_' +
    (jt.label?.module1ResultsFileName || 'Module_1_Results') + '.pdf'

  return (
    <div>
      <ResultsHeader
        module={'module1'}
        chartsLoaded={!!chartImages.third?.imgData}
        chartImages={chartImages}
        benchModAll={benchMod1All}
        benchModCat={benchMod1Cat}
        modAllResults={mod1AllResults}
        modCatResults={mod1CatResults}
        constructs={CONSTRUCTS_M1}
        feedbackTexts={mod1FeedbackTexts}
        pdfFileName={pdfFileName}
      />

      <Container>
        <ChartAndNav
          chartData={chartData}
          chartLabels={chartLabels}
          type={'radar'}
          setChartImages={setChartImages}
        />
        {chartData &&
          <ResultsTable
            jtLabel={jt.label || {}}
            benchModAll={benchMod1All}
            benchModCat={benchMod1Cat}
            modAllResults={mod1AllResults}
            modCatResults={mod1CatResults}
            constructs={CONSTRUCTS_M1}
          />
        }
        <ResultsText
          module={'module1'}
          constructs={CONSTRUCTS_M1}
          modAllResults={mod1AllResults}
          modCatResults={mod1CatResults}
          feedbackTexts={mod1FeedbackTexts}
        />

        <BookConsultationCTA />
      </Container>
      <Footer />
    </div>
  )
}

export default ResultsModule1
