import { useSelector } from 'react-redux'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import styles from './Footer.module.scss'

const Footer = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  return (
    <Navbar className={styles.footerBar}>
      <Container>
        <Nav className='me-auto'>
          <div className={styles.copyright}>
            NPO Benchmark
            <span>
              <span>ⓒ</span>
              Copyright 2024
            </span>
          </div>
        </Nav>
        <Nav align='end'>
          <LinkContainer to={'/data-policy'}>
            <Nav.Link className={styles.linkText}>{jt.label?.dataPolicy || 'Data Policy'}</Nav.Link>
          </LinkContainer>
        </Nav>
        <Nav align='end'>
          <LinkContainer to={'/legal'}>
            <Nav.Link className={styles.linkText}>{jt.label?.legalNotice || 'Legal Notice'}</Nav.Link>
          </LinkContainer>
        </Nav>
        <Nav align='end'>
          <LinkContainer to={'/about'}>
            <Nav.Link className={styles.linkText}>{jt.label?.aboutUs || 'About Us'}</Nav.Link>
          </LinkContainer>
        </Nav>
        <Nav align="end">
          <LinkContainer to={'/contact'}>
            <Nav.Link className={styles.linkText}>{jt.label?.contact || "Contact"}</Nav.Link>
          </LinkContainer>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Footer
