import axios from 'axios'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateSurveyStatus } from '../../features/user/userSlice'
import ProgressBarContainer from '../common/ProgressBarContainer/ProgressBarContainer'
import Module4KVE1 from './Module4KVE1'
import Module4KVE23 from './Module4KVE23'
import Module4KVE45 from './Module4KVE45'
import Module4KVI from './Module4KVI'
import { formatDate } from '../../utils/formatDate'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { answerSavedMsg } from '../../utils/answerSavedMsg'
import { saveAnswersInDB } from '../../utils/saveAnswersInDB'
import { showMissingAnswerWarning, showCategoryNotSetWarning } from '../../utils/getMiscToastMsg'
import { KVE4OptionalQs } from '../../utils/giveAnswer'
import styles from './Module4.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// render survey Module 4 (Step 5); called from SurveyFull.js
const Module4 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)

  const [progress, setProgress] = useState(0) // in %
  const [progressKVI, setProgressKVI] = useState(0) // number of answered KVI questions
  const [progressKVE1, setProgressKVE1] = useState(0) // number of answered KVE1 questions
  const [progressKVE23, setProgressKVE23] = useState(0) // number of answered KVE2 and KVE3 questions
  const [progressKVE45, setProgressKVE45] = useState(0) // number of answered KVE4 and KVE5 questions
  const [currentKVE45Qlength, setCurrentKVE45Qlength] = useState(0) // Reduced number if KVE4a is answered with 'no'

  const [KVIQuestions, setKVIQuestions] = useState(false)
  const [KVE1Questions, setKVE1Questions] = useState(false)
  const [KVE23Questions, setKVE23Questions] = useState(false)
  const [KVE45Questions, setKVE45Questions] = useState(false)
  const [questionsReceived, setQuestionsReceived] = useState(false)
  const [sectionToRender, setSectionToRender] = useState('module4KVI')

  const [KVIPrevAnswers, setKVIPrevAnswers] = useState({})
  const [KVE1PrevAnswers, setKVE1PrevAnswers] = useState({})
  const [KVE23PrevAnswers, setKVE23PrevAnswers] = useState({})
  const [KVE45PrevAnswers, setKVE45PrevAnswers] = useState({})
  const [KVIAnswers, setKVIAnswers] = useState({})
  const [KVE1Answers, setKVE1Answers] = useState({})
  const [KVE23Answers, setKVE23Answers] = useState({})
  const [KVE45Answers, setKVE45Answers] = useState({})

  useEffect(() => {
    if (!user.userID) navigate('/survey')
    if (!questionsReceived) {
      if (user.userID && !user.orgCategory) showCategoryNotSetWarning(jt.toast || {})
      // load questions, but only run this once
      axios
        .get(`${API_URL}/api/survey-questions?filters[module][$eq]=4`)
        .then(res => {
          let module4Questions = res.data.data
          module4Questions = module4Questions.map(item => {
            return {
              questionID: item.attributes.questionID,
              question1: item.attributes.question1,
              question2: item.attributes.question2,
              answerArray: item.attributes.answerArray?.answerArray || null,
              answerLabels: item.attributes.answerLabels?.answerLabels || null,
              format: item.attributes.format,
            }
          })

          const KVI_Qs = module4Questions.filter(item => {
            return item.questionID.substring(0, 3) === 'KVI'
          })
          KVI_Qs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setKVIQuestions(KVI_Qs)

          const KVE1_Qs = module4Questions.filter(item => {
            return item.questionID.substring(0, 4) === 'KVE1'
          })
          KVE1_Qs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setKVE1Questions(KVE1_Qs)

          const KVE23_Qs = module4Questions.filter(item => {
            return (
              item.questionID.substring(0, 4) === 'KVE2' ||
              item.questionID.substring(0, 4) === 'KVE3'
            )
          })
          KVE23_Qs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setKVE23Questions(KVE23_Qs)

          const KVE45_Qs = module4Questions.filter(item => {
            return (
              item.questionID.substring(0, 4) === 'KVE4' ||
              item.questionID.substring(0, 4) === 'KVE5'
            )
          })
          KVE45_Qs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setKVE45Questions(KVE45_Qs)
          setCurrentKVE45Qlength(KVE45_Qs.length)

          setQuestionsReceived(true)
        })
        .catch(error => handleStrapiAPIError(error))

    } else {
      // load previous answers if they exist
      axios
        .get(API_URL + '/api/users/me', authHeaders())
        .then(res => {
          const previousAnswers = res.data.module4 || {}
          // console.log('previousAnswers:', previousAnswers)
          if (Object.keys(previousAnswers).length) {
            let KVIAns = {}
            let KVE1Ans = {}
            let KVE23Ans = {}
            let KVE45Ans = {}
            for (const questKey in previousAnswers) {
              if (questKey.startsWith('KVI')) KVIAns[questKey] = previousAnswers[questKey]
              if (questKey.startsWith('KVE1')) KVE1Ans[questKey] = previousAnswers[questKey]
              if (questKey.startsWith('KVE2') || questKey.startsWith('KVE3'))
                KVE23Ans[questKey] = previousAnswers[questKey]
              if (questKey.startsWith('KVE4') || questKey.startsWith('KVE5'))
                KVE45Ans[questKey] = previousAnswers[questKey]
            }
            // console.log('KVIAns:', KVIAns)
            setKVIPrevAnswers(KVIAns)
            setKVE1PrevAnswers(KVE1Ans)
            setKVE23PrevAnswers(KVE23Ans)
            setKVE45PrevAnswers(KVE45Ans)
            // if KVE4a has been answered 'no' ('Nein'), reduce KVE45 question length from 6 to 2 (KVE4 has 5, KVE5 is only 1)
            if (previousAnswers.KVE4a === KVE45Questions[0].answerArray[1])
              setCurrentKVE45Qlength(KVE45Questions.length - KVE4OptionalQs.length)
            // set each section's progress
            setProgressKVI(previousAnswers.progressKVI ?? 0)
            setProgressKVE1(previousAnswers.progressKVE1 ?? 0)
            setProgressKVE23(previousAnswers.progressKVE23 ?? 0)
            setProgressKVE45(previousAnswers.progressKVE45 ?? 0)
          }
        })
        .catch(error => handleStrapiAPIError(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsReceived])

  const advance = async () => {
    // save progress in DB
    const newSurveyStatus = {
      ...user.surveyStatus,
      module4: `${progress}%`,
      lastUpdate: formatDate(new Date()),
    }
    dispatch(updateSurveyStatus({ surveyStatus: newSurveyStatus }))
    // if new answer objects have been filled, use them; else use previous answers
    const saveKVIAnswers = Object.keys(KVIAnswers).length ? KVIAnswers : KVIPrevAnswers
    const saveKVE1Answers = Object.keys(KVE1Answers).length ? KVE1Answers : KVE1PrevAnswers
    const saveKVE23Answers = Object.keys(KVE23Answers).length ? KVE23Answers : KVE23PrevAnswers
    const saveKVE45Answers = Object.keys(KVE45Answers).length ? KVE45Answers : KVE45PrevAnswers
    const allModule4Answers = { ...saveKVIAnswers, ...saveKVE1Answers, ...saveKVE23Answers, ...saveKVE45Answers }
    const module4Progress = { progressKVI, progressKVE1, progressKVE23, progressKVE45 }

    await axios
      .put(
        `${API_URL}/api/users/${user.userID}`,
        {
          surveyStatus: newSurveyStatus,
          module4: { ...allModule4Answers, ...module4Progress },
        },
        authHeaders(),
      )
      .then(() => answerSavedMsg(sectionToRender !== 'module4KVE45'))
      .catch(error => handleStrapiAPIError(error))
    if (progress === 100) {
      // change all "Ja" answers to 1 and all "Nein" answers to 0
      for (const questKey in allModule4Answers) {
        if (allModule4Answers[questKey] === 'Ja') allModule4Answers[questKey] = 1
        if (allModule4Answers[questKey] === 'Nein') allModule4Answers[questKey] = 0
      }
      saveAnswersInDB(user, '4', allModule4Answers)
    }

    if (sectionToRender === 'module4KVI') {
      if (progressKVI < KVIQuestions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module4KVE1')
    }
    if (sectionToRender === 'module4KVE1') {
      if (progressKVE1 < KVE1Questions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module4KVE23')
    }
    if (sectionToRender === 'module4KVE23') {
      if (progressKVE23 < KVE23Questions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module4KVE45')
    }
    if (sectionToRender === 'module4KVE45') {
      if (progress < 100) showMissingAnswerWarning(jt.toast)
      navigate('/survey')
    }
  }

  const updateProgress = (
    numberOfAnsweredQs,
    questionSection,
    newNumberOfQsInSection
  ) => {
    let totalNumberOfQs
    switch (questionSection) {
      case 'KVI':
        totalNumberOfQs =
          KVIQuestions.length +
          KVE1Questions.length +
          KVE23Questions.length +
          currentKVE45Qlength
        setProgressKVI(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((numberOfAnsweredQs +
              progressKVE1 +
              progressKVE23 +
              progressKVE45) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'KVE1':
        totalNumberOfQs =
          KVIQuestions.length +
          KVE1Questions.length +
          KVE23Questions.length +
          currentKVE45Qlength
        setProgressKVE1(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressKVI +
              numberOfAnsweredQs +
              progressKVE23 +
              progressKVE45) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'KVE23':
        totalNumberOfQs =
          KVIQuestions.length +
          KVE1Questions.length +
          KVE23Questions.length +
          currentKVE45Qlength
        setProgressKVE23(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressKVI + progressKVE1 + numberOfAnsweredQs + progressKVE45) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'KVE45':
        if (!newNumberOfQsInSection)
          newNumberOfQsInSection = KVE45Questions.length
        setCurrentKVE45Qlength(newNumberOfQsInSection)
        totalNumberOfQs =
          KVIQuestions.length +
          KVE1Questions.length +
          KVE23Questions.length +
          newNumberOfQsInSection
        setProgressKVE45(numberOfAnsweredQs)
        const newProgress = Math.round(
          ((progressKVI + progressKVE1 + progressKVE23 + numberOfAnsweredQs) /
            totalNumberOfQs) *
          100
        )
        setProgress(newProgress)
        break
      default:
        console.log(`This questionSection:${questionSection} is unknown!`)
    }
  }

  const getSaveAnsBtnText = () => {
    return jt.label?.saveAnswers || 'Save current answers'
    /*
    let text
    // if current section is finished, show 'save and advance to next page'
    if ((sectionToRender === 'module4KVI' && progressKVI === KVIQuestions.length) ||
      (sectionToRender === 'module4KVE1' && progressKVE1 === KVE1Questions.length) ||
      (sectionToRender === 'module4KVE23' && progressKVE23 === KVE23Questions.length)
    ) text = jt.label?.saveAndAdvance || 'Save and advance'
    // if all questions are answered, show 'save and finish'
    else if (progress === 100) text = jt.label?.saveAndFinish || 'Save and finish'
    // Otherwise, simply show 'save current answers'
    else text = jt.label?.saveAnswers || 'Save current answers'
    return text
    */
  }

  return (
    <div className={styles.surveyContainer}>
      <h6 className={styles.moduleHeader}>
        {jt.header?.module4 || 'Module 4'}
      </h6>
      <h1 className='mb-5'>{jt.header?.module4Title || 'Context and Development'}</h1>
      <br />
      <Container>
        {questionsReceived && sectionToRender === 'module4KVI' && (
          <Module4KVI
            questions={KVIQuestions}
            previousAnswers={KVIPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setKVIAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module4KVE1' && (
          <Module4KVE1
            questions={KVE1Questions}
            previousAnswers={KVE1PrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setKVE1Answers}
          />
        )}
        {questionsReceived && sectionToRender === 'module4KVE23' && (
          <Module4KVE23
            questions={KVE23Questions}
            previousAnswers={KVE23PrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setKVE23Answers}
          />
        )}
        {questionsReceived && sectionToRender === 'module4KVE45' && (
          <Module4KVE45
            questions={KVE45Questions}
            previousAnswers={KVE45PrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setKVE45Answers}
          />
        )}
      </Container>

      <ProgressBarContainer
        btnLabel={getSaveAnsBtnText()}
        progress={progress}
        handleButtonClick={advance}
      />
    </div>
  )
}

export default Module4
