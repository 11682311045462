import axios from 'axios'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateSurveyStatus } from '../../features/user/userSlice'
import ProgressBarContainer from '../common/ProgressBarContainer/ProgressBarContainer'
import Module2IPQ from './Module2IPQ'
import Module2MLQ from './Module2MLQ'
import Module2SVL from './Module2SVL'
import { formatDate } from '../../utils/formatDate'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { answerSavedMsg } from '../../utils/answerSavedMsg'
import { saveAnswersInDB } from '../../utils/saveAnswersInDB'
import { showMissingAnswerWarning, showCategoryNotSetWarning } from '../../utils/getMiscToastMsg'
import styles from './Module2.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// render survey Module 2 (Step 3); called from SurveyFull.js
const Module2 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)

  const [progress, setProgress] = useState(0) // in %

  const [progressMLQ, setProgressMLQ] = useState(0) // number of answered MLQ questions
  const [progressSVL, setProgressSVL] = useState(0)
  const [progressIPQ, setProgressIPQ] = useState(0)
  const [mlqQuestions, setMlqQuestions] = useState(false)
  const [svlQuestions, setSvlQuestions] = useState(false)
  const [ipqQuestions, setIpqQuestions] = useState(false)
  const [questionsReceived, setQuestionsReceived] = useState(false)
  const [sectionToRender, setSectionToRender] = useState('module2MLQ')

  const [mlqPrevAnswers, setMlqPrevAnswers] = useState({})
  const [svlPrevAnswers, setSvlPrevAnswers] = useState({})
  const [ipqPrevAnswers, setIpqPrevAnswers] = useState({})
  const [mlqAnswers, setMlqAnswers] = useState({})
  const [svlAnswers, setSvlAnswers] = useState({})
  const [ipqAnswers, setIpqAnswers] = useState({})

  useEffect(() => {
    if (!user.userID) navigate('/survey')
    if (user.userID && !user.orgCategory) showCategoryNotSetWarning(jt.toast || {})

    // load questions
    axios
      .get(`${API_URL}/api/survey-questions?filters[module][$eq]=2`)
      .then(res => {
        let module2Questions = res.data.data
        module2Questions = module2Questions.map((item) => {
          return {
            questionID: item.attributes.questionID,
            question1: item.attributes.question1,
            question2: item.attributes.question2,
            answerArray: item.attributes.answerArray?.answerArray || null,
            format: item.attributes.format,
            subQuestion1: item.attributes.SubQuestion1,
            subQuestion2: item.attributes.SubQuestion2,
            subQuestion3: item.attributes.SubQuestion3,
            subQuestion4: item.attributes.SubQuestion4,
          }
        })

        const mlqQs = module2Questions.filter((item) => {
          return item.questionID.substring(0, 3) === 'MLQ'
        })
        mlqQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
        setMlqQuestions(mlqQs)

        const svlQs = module2Questions.filter(item => {
          return item.questionID.substring(0, 3) === 'SVL'
        })
        svlQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
        setSvlQuestions(svlQs)

        const ipqQs = module2Questions.filter(item => {
          return item.questionID.substring(0, 3) === 'IPQ'
        })
        ipqQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
        setIpqQuestions(ipqQs)

        setQuestionsReceived(true)
      })
      .catch(error => handleStrapiAPIError(error))

    // load previous answers if they exist
    axios
      .get(API_URL + '/api/users/me', authHeaders())
      .then(res => {
        const previousAnswers = res.data.module2 || {}
        // console.log('previousAnswers:', previousAnswers)
        if (Object.keys(previousAnswers).length) {
          let mlqAns = {}
          let svlAns = {}
          let ipqAns = {}
          for (const questKey in previousAnswers) {
            if (questKey.startsWith('MLQ')) mlqAns[questKey] = previousAnswers[questKey]
            if (questKey.startsWith('SVL')) svlAns[questKey] = previousAnswers[questKey]
            if (questKey.startsWith('IPQ')) ipqAns[questKey] = previousAnswers[questKey]
          }
          // console.log('mlqAns:', mlqAns)
          setMlqPrevAnswers(mlqAns)
          setSvlPrevAnswers(svlAns)
          setIpqPrevAnswers(ipqAns)
          setProgressMLQ(previousAnswers.progressMLQ ?? 0)
          setProgressSVL(previousAnswers.progressSVL ?? 0)
          setProgressIPQ(previousAnswers.progressIPQ ?? 0)
        }
      })
      .catch(error => handleStrapiAPIError(error))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const advance = async () => {
    // save progress in DB
    const newSurveyStatus = {
      ...user.surveyStatus,
      module2: `${progress}%`,
      lastUpdate: formatDate(new Date()),
    }
    dispatch(updateSurveyStatus({ surveyStatus: newSurveyStatus }))
    // if new answer objects have been filled, use them; else use previous answers
    const saveMlqAnswers = Object.keys(mlqAnswers).length ? mlqAnswers : mlqPrevAnswers
    const saveSvlAnswers = Object.keys(svlAnswers).length ? svlAnswers : svlPrevAnswers
    const saveIpqAnswers = Object.keys(ipqAnswers).length ? ipqAnswers : ipqPrevAnswers
    const allModule2Answers = { ...saveMlqAnswers, ...saveSvlAnswers, ...saveIpqAnswers }
    const module2Progress = { progressMLQ, progressSVL, progressIPQ }
    await axios
      .put(
        `${API_URL}/api/users/${user.userID}`,
        {
          surveyStatus: newSurveyStatus,
          module2: { ...allModule2Answers, ...module2Progress },
        },
        authHeaders(),
      )
      .then(() => answerSavedMsg(sectionToRender !== 'module2IPQ'))
      .catch(error => handleStrapiAPIError(error))
    if (progress === 100) {
      /* note: changing all "Ja" answers to 1 and all "Nein" answers to 0
         is not needed in Module 2 */
      saveAnswersInDB(user, '2', allModule2Answers)
    }

    if (sectionToRender === 'module2MLQ') {
      if (progressMLQ < mlqQuestions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module2SVL')
    }
    if (sectionToRender === 'module2SVL') {
      if (progressSVL < svlQuestions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module2IPQ')
    }
    if (sectionToRender === 'module2IPQ') {
      if (progress < 100) showMissingAnswerWarning(jt.toast)
      navigate('/survey')
    }
  }

  const updateProgress = (numberOfAnsweredQs, questionSection) => {
    const totalNumberOfQs =
      mlqQuestions.length + svlQuestions.length + ipqQuestions.length
    switch (questionSection) {
      case 'MLQ':
        setProgressMLQ(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((numberOfAnsweredQs + progressSVL + progressIPQ) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'SVL':
        setProgressSVL(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressMLQ + numberOfAnsweredQs + progressIPQ) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'IPQ':
        setProgressIPQ(numberOfAnsweredQs)
        const newProgress = Math.round(
          ((progressMLQ + progressSVL + numberOfAnsweredQs) / totalNumberOfQs) *
          100,
        )
        setProgress(newProgress)
        break
      default:
        console.log(`This questionSection:${questionSection} is unknown!`)
    }
  }

  const getSaveAnsBtnText = () => {
    return jt.label?.saveAnswers || 'Save current answers'
    /*
    let text
    // if current section is finished, show 'save and advance to next page'
    if ((sectionToRender === 'module2MLQ' && progressMLQ === mlqQuestions.length) ||
      (sectionToRender === 'module2SVL' && progressSVL === svlQuestions.length)
    ) text = jt.label?.saveAndAdvance || 'Save and advance'
    // if all questions are answered, show 'save and finish'
    else if (progress === 100) text = jt.label?.saveAndFinish || 'Save and finish'
    // Otherwise, simply show 'save current answers'
    else text = jt.label?.saveAnswers || 'Save current answers'
    return text
    */
  }

  return (
    <div className={styles.surveyContainer}>
      <h6 className={styles.moduleHeader}>
        {jt.header?.module2 || 'Module 2'}
      </h6>
      <h1 className='mb-5'>{jt.header?.module2Title || 'Leadership'}</h1>
      <br />
      <Container>
        {questionsReceived && sectionToRender === 'module2MLQ' && (
          <Module2MLQ
            questions={mlqQuestions}
            previousAnswers={mlqPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setMlqAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module2SVL' && (
          <Module2SVL
            questions={svlQuestions}
            previousAnswers={svlPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setSvlAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module2IPQ' && (
          <Module2IPQ
            questions={ipqQuestions}
            previousAnswers={ipqPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setIpqAnswers}
          />
        )}
      </Container>

      <ProgressBarContainer
        btnLabel={getSaveAnsBtnText()}
        progress={progress}
        handleButtonClick={advance}
      />
    </div>
  )
}

export default Module2
