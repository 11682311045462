import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import BookConsultationCTA from '../common/BookConsultationCTA/BookConsultationCTA'
import ChartAndNav from './ChartAndNav/ChartAndNav'
import Footer from '../Footer/Footer'
import ResultsHeader from './ResultsHeader/ResultsHeader'
import ResultsTable from './ResultsTable/ResultsTable'
import ResultsText from './ResultsText/ResultsText'
import { calcModResult } from './ResultsUtils/calcModResults'
import { getChartDataMod4 } from './ResultsUtils/getChartDataMod4'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { showNoCategoryBenchInfo } from '../../utils/getMiscToastMsg'
import { CONSTRUCTS, MIN_SAMPLE_SIZE } from '../../utils/constants'
import { getFeedbackTexts } from '../../utils/getFeedbackTexts'
import { formatDate } from '../../utils/formatDate'

const API_URL = process.env.REACT_APP_API_URL
const CONSTRUCTS_M4 = [ // the order here pertains only to the text rendering below the table
  CONSTRUCTS.COMPLEXITY,
  CONSTRUCTS.FUNDING_OPPORTUNITY,
  CONSTRUCTS.HOSTILITY,
  CONSTRUCTS.LATITUDE,
]

// render the Module 4 (Step 5) survey results; called by Results.js
const ResultsModule4 = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const [benchMod4All, setBenchMod4All] = useState(null)
  const [benchMod4Cat, setBenchMod4Cat] = useState(null)
  const [mod4AllResults, setMod4AllResults] = useState(null)
  const [mod4CatResults, setMod4CatResults] = useState(null)
  const [mod4BenchCatResults, setMod4BenchCatResults] = useState(null)  // NPO category compared with all NPOs
  const [mod4FeedbackTexts, setMod4FeedbackTexts] = useState({})
  const [chartImages, setChartImages] = useState({})
  const noCatBenchInfoHasBeenShown = useRef(null)

  useEffect(() => {
    // get latest benchmark
    if (user.strapiJwt && jt.toast) {
      if (!benchMod4All) { // only run this once
        axios
          .get(`${API_URL}/api/benchmarks` +
            `?filters[module][$eq]=4` +
            `&filters[orgCategory][$eq]=all`,
            authHeaders(),
          )
          .then(res => {
            const benchmarkModuleAll = res.data.data[0].attributes.benchmark
            setBenchMod4All(benchmarkModuleAll)
          })
          .catch(error => handleStrapiAPIError(error))
      }
      if (!benchMod4Cat) { // only run this once
        if (user.orgCategory) {
          axios
            .get(`${API_URL}/api/benchmarks` +
              `?filters[module][$eq]=4` +
              `&filters[orgCategory][$eq]=${user.orgCategory}`,
              authHeaders(),
            )
            .then(res => {
              const benchMod4CatData = res.data.data[0].attributes.benchmark
              // console.log('benchMod4CatData:', benchMod4CatData)
              if (benchMod4CatData.sample_size >= MIN_SAMPLE_SIZE) {
                setBenchMod4Cat(benchMod4CatData)
              } else {
                if (noCatBenchInfoHasBeenShown.current !== true) {
                  showNoCategoryBenchInfo(jt.toast)
                  noCatBenchInfoHasBeenShown.current = true
                }
                setBenchMod4Cat('Category not available')
              }
            })
            .catch(error => handleStrapiAPIError(error))
        } else setBenchMod4Cat('Category not available')
      }
      // get the latest answers for this user if they have fully completed this module
      if (benchMod4All && benchMod4Cat) {
        axios
          .get(
            `${API_URL}/api/npo-surveys` +
            `?filters[organizationID][$eq]=${user.orgID}` +
            `&filters[module][$eq]=4` +
            `&sort=createdAt:desc`,
            authHeaders(),
          )
          .then(res => {
            if (res.data.data.length === 0) return
            const answerObject = res.data.data[0].attributes.npoAnswers
            const resultsAll = calcModResult(4, benchMod4All, answerObject)
            const resultsCat = benchMod4Cat === 'Category not available' ? null
              : calcModResult(4, benchMod4Cat, answerObject)
            const resultsBenchCat = benchMod4Cat === 'Category not available' ? null
              : calcModResult(4, benchMod4All, benchMod4Cat)
            setMod4AllResults(resultsAll)
            setMod4CatResults(resultsCat)
            setMod4BenchCatResults(resultsBenchCat)
            const resultsForFB = resultsCat ? resultsCat : resultsAll
            setMod4FeedbackTexts(getFeedbackTexts(jt.results, resultsForFB, CONSTRUCTS_M4))
          })
          .catch(error => handleStrapiAPIError(error))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jt, user, benchMod4All, benchMod4Cat])

  const chartData = mod4AllResults && benchMod4All ?
    {
      first: getChartDataMod4(
        jt.label,
        benchMod4All,
        mod4BenchCatResults,
        mod4AllResults,
        mod4CatResults,
        'summary',
      ),
    }
    : null

  const chartLabels = {
    first: jt.label?.mod4Overview || 'Module 4 Overview',
  }

  const pdfFileName = formatDate(new Date(), 'YYYY.MM.DD') + '_' +
    (jt.label?.module4ResultsFileName || 'Module_4_Results') + '.pdf'

  return (
    <div>
      <ResultsHeader
        module={'module4'}
        chartsLoaded={!!chartImages.first?.imgData}
        chartImages={chartImages}
        benchModAll={benchMod4All}
        benchModCat={benchMod4Cat}
        modAllResults={mod4AllResults}
        modCatResults={mod4CatResults}
        constructs={CONSTRUCTS_M4}
        feedbackTexts={mod4FeedbackTexts}
        pdfFileName={pdfFileName}
      />

      <Container>
        <ChartAndNav
          chartData={chartData}
          chartLabels={chartLabels}
          type={'radar'}
          setChartImages={setChartImages}
        />
        {chartData &&
          <ResultsTable
            jtLabel={jt.label || {}}
            benchModAll={benchMod4All}
            benchModCat={benchMod4Cat}
            modAllResults={mod4AllResults}
            modCatResults={mod4CatResults}
            constructs={CONSTRUCTS_M4}
          />
        }
        <ResultsText
          module={'module4'}
          constructs={CONSTRUCTS_M4}
          modAllResults={mod4AllResults}
          modCatResults={mod4CatResults}
          feedbackTexts={mod4FeedbackTexts}
        />

        <BookConsultationCTA />
      </Container>
      <Footer />
    </div>
  )
}

export default ResultsModule4
