import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Image, Row } from 'react-bootstrap'
import BVMLogo from '../../assets/images/Logo-BVM.png'
import kultiviererLogo from '../../assets/images/Logo-Kultivierer.png'
import VMILogo from '../../assets/images/Logo-VMI.png'
import Footer from '../Footer/Footer'
import LogosHeader from '../common/LogosHeader/LogosHeader'
import { BVM_URL, KULTIVIERER_URL, VMI_URL } from '../../utils/constants'
import styles from './Contact.module.scss'

const CLICKMASTER_CONTACT_FORM = {  // projects created at https://editor.clickmaster.app/
  de: '663b3a8ed4a120645abaca58',
  en: '663b4a64d4a120645abacbb7',
}
CLICKMASTER_CONTACT_FORM.default = CLICKMASTER_CONTACT_FORM.de

const Contact = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)

  const clickMasterProjectNumber = CLICKMASTER_CONTACT_FORM[user.language] || CLICKMASTER_CONTACT_FORM.default

  // use this to create and later remove the <script> element that runs the
  // clickmaster widget for the embedded contact form
  useEffect(() => {
    // Create a <script> element
    const script = document.createElement('script')
    // Set the src attribute to the URL of the script
    script.src = 'https://clickmaster.app/script.js'
    // Add the script element to the document body
    document.body.appendChild(script)
    // Clean up function to remove the script element when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
  }, []);

  const sponsorArr = [
    {
      src: VMILogo,
      style: styles.image,
      alt: 'VMI Logo',
      url: VMI_URL,
      title: jt.header?.vmiTitle || 'Institut für Verbands-, Stiftungs- und Genossenschaftsmanagement (VMI).',
      person: jt.label?.markusGmuer || 'Markus Gmuer',
      email: jt.label?.markusEmail || 'info@vmi.ch',
    },
    {
      src: kultiviererLogo,
      style: styles.image,
      alt: 'Kultivierer Logo',
      url: KULTIVIERER_URL,
      title: jt.header?.kultiviererTitle || 'Kultivierer GmbH – für Menschen & Organisationen.',
      person: jt.label?.jonasBaumannFuchs || 'Jonas Baumann-Fuchs',
      email: jt.label?.jonasEmail || 'info@kultivierer.ch',
    },
    {
      src: BVMLogo,
      style: styles.bvmImage,
      alt: 'BVM Logo',
      url: BVM_URL,
      title: jt.header?.bvmTitle || 'B´VM AG',
      person: jt.label?.marcoBuser || 'Marco Buser',
      email: jt.label?.marcoEmail || 'marco.buser@bvmberatung.net',
    },
  ]

  return <div>
    <div className={styles.pictureBackground}>
      <h1>
        {jt.label?.contact || 'Contact'}
      </h1>
    </div>

    <LogosHeader jtLabel={jt.label || {}} />

    <Container className={styles.mainContent}>

      <div className={styles.title}>{jt.header?.contactHeader || 'How may we help you'}?</div>
      <p>
        {jt.block?.contactFormDescription || 'Please leave us your details, and we will get back to you ASAP!'}
      </p>

      <div className={styles.contactForm}>
        {/*The following line inserts the contact form from the clickmaster widget*/}
        <div className='clickmaster-widget' data-project={clickMasterProjectNumber}></div>
      </div>

      <div className={styles.secondTitle}>
        {jt.header?.orContactDirectly || 'Or, contact us directly'}:
      </div>

      {sponsorArr.map(sponsor => {
        return (
          <Row className={'align-items-center  mx-5'} key={sponsor.url}>
            <Col xs={6} md={4} lg={3} className={'d-flex justify-content-end'}>
              <Image
                src={sponsor.src}
                className={sponsor.style}
                alt={sponsor.alt}
                onClick={() => window.open(sponsor.url)}
              />
            </Col>
            <Col className={styles.rightCol}>
              <div>{sponsor.title}</div>
              <div className={styles.contactPerson}>
                {sponsor.person}
              </div>
              <div className={styles.emailContainer}>
                <span
                  className={styles.contactEmail}
                  onClick={() => window.open(`mailto:${sponsor.email}`)}
                >
                  {sponsor.email}
                </span>
              </div>
            </Col>
          </Row>
        )
      })}

      <br />
    </Container>

    <Footer />
  </div>
}

export default Contact
