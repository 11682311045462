import axios from 'axios'
import { useEffect, useState } from 'react'
import {
  Container,
  Form,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addOrgToUser, updateSurveyStatus, updateUserOrgCategory } from '../../features/user/userSlice'
import Module0FollowQ from './Module0FollowQ'
import ProgressBarContainer from '../common/ProgressBarContainer/ProgressBarContainer'
import { authHeaders } from '../../utils/authHeaders'
import { formatDate } from '../../utils/formatDate'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { answerSavedMsg } from '../../utils/answerSavedMsg'
import { CONSTANTS } from '../../utils/constants'
import styles from './Module0.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// render the survey Module 0 'Basic Organizational Info'; called from SurveyFull.js
const Module0 = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)

  const [lastUpdate, setLastUpdate] = useState(null)
  const [questionsReceived, setQuestionsReceived] = useState(false)
  const [KVI1a1Q, setKVI1a1Q] = useState('') // Q for question
  const [KVI1a1A, setKVI1a1A] = useState('') // A for answer
  const [KVI1a2Q, setKVI1a2Q] = useState('')
  const [KVI1a2A, setKVI1a2A] = useState('')
  const [KVI1bQ, setKVI1bQ] = useState('')
  const [KVI1bA, setKVI1bA] = useState('')
  const [KVO1aQ, setKVO1aQ] = useState(null)
  const [KVO1aQOptions, setKVO1aQOptions] = useState([]) // radio options
  const [KVO1aA, setKVO1aA] = useState(null)

  const [KVO1aFollowQID, setKVO1aFollowQID] = useState('')
  const [KVO1aFollowQ, setKVO1aFollowQ] = useState('')
  const [KVO1aFollowQOptions, setKVO1aFollowQOptions] = useState([])
  const [KVO1aFollowQA, setKVO1aFollowQA] = useState(null)
  const [KVO1aFollowQPrevAnswers, setKVO1aFollowQPrevAnswers] = useState([])
  const [KVO1aFollowQPrevQuestionID, setKVO1aFollowQPrevQuestionID] = useState(
    ''
  )

  const [KVO1a01Q, setKVO1a01Q] = useState('')
  const [KVO1a01QOptions, setKVO1a01QOptions] = useState([])
  const [KVO1a02Q, setKVO1a02Q] = useState('')
  const [KVO1a02QOptions, setKVO1a02QOptions] = useState([])
  const [KVO1a03Q, setKVO1a03Q] = useState('')
  const [KVO1a03QOptions, setKVO1a03QOptions] = useState([])
  const [KVO1a04Q, setKVO1a04Q] = useState(null)
  const [KVO1a04QOptions, setKVO1a04QOptions] = useState(null)
  const [KVO1a05Q, setKVO1a05Q] = useState('')
  const [KVO1a05QOptions, setKVO1a05QOptions] = useState([])
  const [KVO1a06Q, setKVO1a06Q] = useState(null)
  const [KVO1a06QOptions, setKVO1a06QOptions] = useState(null)
  const [KVO1a07Q, setKVO1a07Q] = useState('')
  const [KVO1a07QOptions, setKVO1a07QOptions] = useState([])
  const [KVO1a08Q, setKVO1a08Q] = useState('')
  const [KVO1a08QOptions, setKVO1a08QOptions] = useState([])
  const [KVO1a09Q, setKVO1a09Q] = useState('')
  const [KVO1a09QOptions, setKVO1a09QOptions] = useState([])
  const [KVO1a10Q, setKVO1a10Q] = useState(null)
  const [KVO1a10QOptions, setKVO1a10QOptions] = useState(null)
  const [KVO1a11Q, setKVO1a11Q] = useState('')
  const [KVO1a11QOptions, setKVO1a11QOptions] = useState([])
  const [KVO1a12Q, setKVO1a12Q] = useState('')
  const [KVO1a12QOptions, setKVO1a12QOptions] = useState([])

  const [KVO2Q, setKVO2Q] = useState(null)
  const [KVO2QOptions, setKVO2QOptions] = useState([])
  const [KVO2A, setKVO2A] = useState(null)
  const [KVO3Q, setKVO3Q] = useState(null)
  const [KVO3QOptions, setKVO3QOptions] = useState([])
  const [KVO3A, setKVO3A] = useState(null)
  const [KVO4Q, setKVO4Q] = useState(null)
  const [KVO4QOptions, setKVO4QOptions] = useState([]) // checkbox options
  const [KVO4A, setKVO4A] = useState([]) // array of true/false for each option
  const [KVO4AnsArray, setKVO4AnsArray] = useState([]) // array of selected answer strings

  const [KVO5Q, setKVO5Q] = useState('')
  const [KVO5SubQ1, setKVO5SubQ1] = useState('')
  const [KVO5SubQ1A, setKVO5SubQ1A] = useState('')
  const [KVO5SubQ2, setKVO5SubQ2] = useState('')
  const [KVO5SubQ2A, setKVO5SubQ2A] = useState('')
  const [KVO5SubQ3, setKVO5SubQ3] = useState('')
  const [KVO5SubQ3A, setKVO5SubQ3A] = useState('')
  const [KVO5SubQ4, setKVO5SubQ4] = useState('')
  const [KVO5SubQ4A, setKVO5SubQ4A] = useState('')
  const [KVO5SubQ4AText, setKVO5SubQ4AText] = useState('')
  const [KVO5Total, setKVO5Total] = useState(0) // total percent of income

  const [KVP1Q, setKVP1Q] = useState('')
  const [KVP1A, setKVP1A] = useState('')
  const [KVP2Q, setKVP2Q] = useState('')
  const [KVP2A, setKVP2A] = useState('')
  const [KVP3Q, setKVP3Q] = useState('')
  const [KVP3QOptions, setKVP3QOptions] = useState([])
  const [KVP3A, setKVP3A] = useState(null)

  // const [infoSavedMsg, setInfoSavedMsg] = useState('')
  const [percentComplete, setPercentComplete] = useState(0)

  const activeButtonStyling = {
    borderColor: CONSTANTS.colors.orange,
    backgroundColor: CONSTANTS.colors.orange,
  }
  const inactiveButtonStyling = {
    borderColor: CONSTANTS.colors.orange,
    backgroundColor: 'white',
  }

  function setButtonStyling(i, question) {
    return i === question ? activeButtonStyling : inactiveButtonStyling
  }

  useEffect(() => {
    // first verify that user is author of organization
    if (user.isOrgCreator) {
      // load questions
      axios
        .get(`${API_URL}/api/basic-info-questions`, authHeaders())
        .then(res => {
          const basicQuestions = res.data.data
          basicQuestions.forEach(question => {
            switch (question.attributes.questionID) {
              case 'KVI1a1':
                setKVI1a1Q(question.attributes.question)
                break
              case 'KVI1a2':
                setKVI1a2Q(question.attributes.question)
                break
              case 'KVI1b':
                setKVI1bQ(question.attributes.question)
                break
              case 'KVO1a':
                setKVO1aQ(question.attributes.question)
                setKVO1aQOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a01':
                setKVO1a01Q(question.attributes.question)
                setKVO1a01QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a02':
                setKVO1a02Q(question.attributes.question)
                setKVO1a02QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a03':
                setKVO1a03Q(question.attributes.question)
                setKVO1a03QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a04':
                setKVO1a04Q(question.attributes.question)
                setKVO1a04QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a05':
                setKVO1a05Q(question.attributes.question)
                setKVO1a05QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a06':
                setKVO1a06Q(question.attributes.question)
                setKVO1a06QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a07':
                setKVO1a07Q(question.attributes.question)
                setKVO1a07QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a08':
                setKVO1a08Q(question.attributes.question)
                setKVO1a08QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a09':
                setKVO1a09Q(question.attributes.question)
                setKVO1a09QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a10':
                setKVO1a10Q(question.attributes.question)
                setKVO1a10QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a11':
                setKVO1a11Q(question.attributes.question)
                setKVO1a11QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO1a12':
                setKVO1a12Q(question.attributes.question)
                setKVO1a12QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO2':
                setKVO2Q(question.attributes.question)
                setKVO2QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO3':
                setKVO3Q(question.attributes.question)
                setKVO3QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO4':
                setKVO4Q(question.attributes.question)
                setKVO4QOptions(question.attributes.answerArray.answerArray)
                break
              case 'KVO5':
                setKVO5Q(question.attributes.question)
                setKVO5SubQ1(question.attributes.subQuestion1)
                setKVO5SubQ2(question.attributes.subQuestion2)
                setKVO5SubQ3(question.attributes.subQuestion3)
                setKVO5SubQ4(question.attributes.subQuestion4)
                break
              case 'KVP1':
                setKVP1Q(question.attributes.question)
                break
              case 'KVP2':
                setKVP2Q(question.attributes.question)
                break
              case 'KVP3':
                setKVP3Q(question.attributes.question)
                setKVP3QOptions(question.attributes.answerArray.answerArray)
                break
              default:
                console.log(
                  `Question code:${question.attributes.questionID} is unknown!`,
                )
            }
          })
          setQuestionsReceived(true)
        })
        .catch(error => handleStrapiAPIError(error))

      // load previously stored answers
      if (questionsReceived && user.orgID)
        axios
          .get(`${API_URL}/api/organizations/${user.orgID}`, authHeaders())
          .then(res => {
            const { userID, basicInfo, updatedAt } = res.data.data?.attributes
            if (userID !== user.userID) navigate('/survey')
            setKVI1a1A(basicInfo?.KVI1a1?.answer || '')
            setKVI1a2A(basicInfo?.KVI1a2?.answer || '')
            setKVI1bA(basicInfo?.KVI1b.answer || '')
            setKVO1aA(basicInfo?.KVO1a.answerKey.slice(-2) - 1 ?? null) // accept 0 value
            setKVO1aFollowQPrevAnswers(
              (basicInfo && basicInfo[basicInfo.KVO1a.answerKey].answer) || [],
            )
            setKVO1aFollowQPrevQuestionID(basicInfo?.KVO1a.answerKey || '')
            setKVO2A(
              basicInfo?.KVO2.answer
                ? KVO2QOptions.indexOf(basicInfo.KVO2.answer)
                : null
            )
            setKVO3A(
              basicInfo?.KVO3.answer
                ? KVO3QOptions.indexOf(basicInfo.KVO3.answer)
                : null
            )
            const initArray = []
            KVO4QOptions.forEach((option) => {
              basicInfo?.KVO4.answer.includes(option)
                ? initArray.push(true)
                : initArray.push(false)
            })
            setKVO4A(initArray)
            const answers = []
            initArray.forEach((answer, idx) => {
              if (answer) answers.push(KVO4QOptions[idx])
            })
            setKVO4AnsArray(answers)
            setKVO5SubQ1A(basicInfo?.KVO5.answer1 || '')
            setKVO5SubQ2A(basicInfo?.KVO5.answer2 || '')
            setKVO5SubQ3A(basicInfo?.KVO5.answer3 || '')
            setKVO5SubQ4A(basicInfo?.KVO5.answer4 || '')
            setKVO5SubQ4AText(basicInfo?.KVO5.answer4Text || '')
            setKVP1A(basicInfo?.KVP1.answer || '')
            setKVP2A(basicInfo?.KVP2.answer || '')
            setKVP3A(
              basicInfo?.KVP3.answer
                ? KVP3QOptions.indexOf(basicInfo.KVP3.answer)
                : null,
            )
            setLastUpdate(formatDate(updatedAt))
          })
          .catch(error => handleStrapiAPIError(error))
    // if user is not flagged as organization creator, check if user is creator of any organization
    } else if (user.userID) {
      axios
        .get(
          `${API_URL}/api/organizations?filters[userID][$eq]=${user.userID}`,
          authHeaders(),
        )
        .then(res => {
          const data = res.data.data[0]
          if (data) {
            dispatch(
              addOrgToUser({
                orgID: data.id,
                orgName: data.attributes.name,
                isOrgCreator: true,
                orgCategory: data.attributes.orgCategory,
              }),
            )
            // if user is not creator of any organization, go back to survey
          } else {
            navigate('/survey')
            toast.warning(jt.toast?.onyOrgAuthorCanAccess || 'Only the organization author can access this page',
              { autoClose: 8000 })
          }
        })
        .catch(error => handleStrapiAPIError(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsReceived, user.orgName])

  useEffect(() => {
    // set the follow-up question depending on the radio choice.
    switch (KVO1aA) {
      case 0:
        setKVO1aFollowQID('KVO1a01')
        setKVO1aFollowQ(KVO1a01Q)
        setKVO1aFollowQOptions(KVO1a01QOptions)
        break
      case 1:
        setKVO1aFollowQID('KVO1a02')
        setKVO1aFollowQ(KVO1a02Q)
        setKVO1aFollowQOptions(KVO1a02QOptions)
        break
      case 2:
        setKVO1aFollowQID('KVO1a03')
        setKVO1aFollowQ(KVO1a03Q)
        setKVO1aFollowQOptions(KVO1a03QOptions)
        break
      case 3:
        setKVO1aFollowQID('KVO1a04')
        setKVO1aFollowQ(KVO1a04Q)
        setKVO1aFollowQOptions(KVO1a04QOptions)
        break
      case 4:
        setKVO1aFollowQID('KVO1a05')
        setKVO1aFollowQ(KVO1a05Q)
        setKVO1aFollowQOptions(KVO1a05QOptions)
        break
      case 5:
        setKVO1aFollowQID('KVO1a06')
        setKVO1aFollowQ(KVO1a06Q)
        setKVO1aFollowQOptions(KVO1a06QOptions)
        break
      case 6:
        setKVO1aFollowQID('KVO1a07')
        setKVO1aFollowQ(KVO1a07Q)
        setKVO1aFollowQOptions(KVO1a07QOptions)
        break
      case 7:
        setKVO1aFollowQID('KVO1a08')
        setKVO1aFollowQ(KVO1a08Q)
        setKVO1aFollowQOptions(KVO1a08QOptions)
        break
      case 8:
        setKVO1aFollowQID('KVO1a09')
        setKVO1aFollowQ(KVO1a09Q)
        setKVO1aFollowQOptions(KVO1a09QOptions)
        break
      case 9:
        setKVO1aFollowQID('KVO1a10')
        setKVO1aFollowQ(KVO1a10Q)
        setKVO1aFollowQOptions(KVO1a10QOptions)
        break
      case 10:
        setKVO1aFollowQID('KVO1a11')
        setKVO1aFollowQ(KVO1a11Q)
        setKVO1aFollowQOptions(KVO1a11QOptions)
        break
      case 11:
        setKVO1aFollowQID('KVO1a12')
        setKVO1aFollowQ(KVO1a12Q)
        setKVO1aFollowQOptions(KVO1a12QOptions)
        break
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [KVO1aA])

  useEffect(() => {
    // update total income percentages
    setKVO5Total(
      Number(KVO5SubQ1A) +
        Number(KVO5SubQ2A) +
        Number(KVO5SubQ3A) +
        Number(KVO5SubQ4A)
    )
  }, [KVO5SubQ1A, KVO5SubQ2A, KVO5SubQ3A, KVO5SubQ4A])

  useEffect(() => {
    let completedQs = 0
    KVI1a1A && completedQs++
    KVI1a2A && completedQs++
    KVI1bA && completedQs++
    KVO1aA !== null && completedQs++
    KVO1aFollowQA?.length && completedQs++
    KVO2A !== null && completedQs++
    KVO3A !== null && completedQs++
    KVO4A.length && completedQs++
    KVO5SubQ1A && completedQs++
    KVO5SubQ2A && completedQs++
    KVO5SubQ3A && completedQs++
    KVO5SubQ4A && completedQs++
    KVP1A && completedQs++
    KVP2A && completedQs++
    KVP3A !== null && completedQs++
    setPercentComplete(Math.round((completedQs / 15) * 100))
  }, [
    KVI1a1A,
    KVI1a2A,
    KVI1bA,
    KVO1aA,
    KVO1aFollowQA,
    KVO2A,
    KVO3A,
    KVO4A,
    KVO5SubQ1A,
    KVO5SubQ2A,
    KVO5SubQ3A,
    KVO5SubQ4A,
    KVP1A,
    KVP2A,
    KVP3A,
  ])

  const setFollowQAnswer = (answerArray) => {
    setKVO1aFollowQA(answerArray)
  }

  const onKVO4RadioClick = (idx) => {
    const newAnswerArray = KVO4A
    newAnswerArray[idx] = !newAnswerArray[idx]
    const answers = []
    newAnswerArray.forEach((answer, idx) => {
      if (answer) answers.push(KVO4QOptions[idx])
    })
    setKVO4A(newAnswerArray)
    setKVO4AnsArray(answers)
  }

  const onFormSubmit = async (event) => {
    event.preventDefault()
    const basicInfo = {
      KVI1a1: {
        question: KVI1a1Q,
        answer: KVI1a1A,
      },
      KVI1a2: {
        question: KVI1a2Q,
        answer: KVI1a2A,
      },
      KVI1b: {
        question: KVI1bQ,
        answer: KVI1bA,
      },
      KVO1a: {
        question: KVO1aQ,
        answer: KVO1aQOptions[KVO1aA],
        answerKey: KVO1aFollowQID,
      },
      [KVO1aFollowQID]: {
        question: KVO1aFollowQ,
        answer: KVO1aFollowQA,
      },
      KVO2: {
        question: KVO2Q,
        answer: KVO2QOptions[KVO2A],
      },
      KVO3: {
        question: KVO3Q,
        answer: KVO3QOptions[KVO3A],
      },
      KVO4: {
        question: KVO4Q,
        answer: KVO4AnsArray,
      },
      KVO5: {
        question: KVO5Q,
        subQuestion1: KVO5SubQ1,
        answer1: KVO5SubQ1A,
        subQuestion2: KVO5SubQ2,
        answer2: KVO5SubQ2A,
        subQuestion3: KVO5SubQ3,
        answer3: KVO5SubQ3A,
        subQuestion4: KVO5SubQ4,
        answer4: KVO5SubQ4A,
        answer4Text: KVO5SubQ4AText,
      },
      KVP1: {
        question: KVP1Q,
        answer: KVP1A,
      },
      KVP2: {
        question: KVP2Q,
        answer: KVP2A,
      },
      KVP3: {
        question: KVP3Q,
        answer: KVP3QOptions[KVP3A],
      },
    }
    await axios // ensure user.orgCategory is updated in Redux before navigating to '/survey'
      .put(
        `${API_URL}/api/organizations/${user.orgID}`,
        {
          data: { basicInfo, orgCategory: KVO1aQOptions[KVO1aA] },
        },
        authHeaders(),
      )
      .then(() => {
        // setInfoSavedMsg(
        //   percentComplete === 100 ? 'info complete' : 'info incomplete'
        // )
        dispatch(updateUserOrgCategory({ orgCategory: KVO1aQOptions[KVO1aA] }))
        percentComplete < 100 && answerSavedMsg()  // otherwise, navigate to '/survey' (see code below)
      })
      .catch(error => handleStrapiAPIError(error))

    // update user.surveyStatus in DB
    const newSurveyStatus = {
      ...user.surveyStatus,
      module0: `${percentComplete}%`,
    }
    dispatch(updateSurveyStatus({ surveyStatus: newSurveyStatus }))
    axios
      .put(
        `${API_URL}/api/users/${user.userID}`,
        {
          orgCategory: KVO1aQOptions[KVO1aA],
          surveyStatus: newSurveyStatus,
        },
        authHeaders(),
      )
      .then()
      .catch(error => handleStrapiAPIError(error))
    //
    percentComplete === 100 && navigate('/survey')
  }

  const answerFontSize = { fontSize: "1.2rem" }

  const saveAnsBtnText = percentComplete < 100 ?  // since percentComplete is a state variable, this code will run on each update
    jt.label?.saveAnswers || 'Save answers'
    : jt.label?.saveAndFinish || 'Save and finish'

  return (
    <Container className={styles.mainContainer}>
      <h2 className={styles.mainHeader}>
        {jt.header?.module0Title || "Basic Information of Your Organization (NPO)"}
      </h2>
      <p className={styles.description}>
        {jt.block?.beginWithFollowingInfo || "Please begin by entering the following information:"}
      </p>
      <div>
        {jt.label?.npo || "NPO"}{': '}
        <span className={styles.bold}>{user.orgName}</span>
      </div>
      <div className={styles.small}>
        {jt.label?.lastUpdate || "Last update"}{': '}
        <span className={styles.bold}>{lastUpdate}</span>
      </div>
      {questionsReceived && (
        <Form onSubmit={onFormSubmit}>
          <div className={styles.question}>{KVI1a1Q}</div>
          <Form.Control
            value={KVI1a1A}
            onChange={e => setKVI1a1A(e.target.value)}
            style={answerFontSize}
          />
          <div className={styles.question}>{KVI1a2Q}</div>
          <Form.Control
            value={KVI1a2A}
            onChange={e => setKVI1a2A(e.target.value)}
            style={answerFontSize}
          />
          <div className={styles.question}>{KVI1bQ}</div>
          <Form.Control
            value={KVI1bA}
            onChange={e => setKVI1bA(e.target.value)}
            style={answerFontSize}
          />
          <div className={styles.question}>{KVO1aQ}</div>
          <ToggleButtonGroup
            type="radio"
            value={KVO1aA}
            // className="mt-1"
            name={`answer-radios-KVO1a`} // name must be shared by all radio buttons in group
            // size="sm"                 // and be distinct from other buttongroups
            onChange={val => setKVO1aA(val)}
            className={styles.toggleButtons}
          >
            {KVO1aQOptions.map((answer, i) => {
              return (
                // <div className={styles.toggleContainer}>
                <ToggleButton
                  key={answer}
                  id={`tbg-btn-KVO1a${i + 1}`} // unique id is required in a ToggleButtonGroup
                  value={i} // this id must also be unique in the HTML document
                  className={styles.radios}
                  style={setButtonStyling(i, KVO1aA)}
                >
                  <span className={styles.radioAnswer}>
                    {jt.organizationCategory?.[answer] || answer}
                  </span>
                  <br />
                </ToggleButton>
                // </div>
              )
            })}
          </ToggleButtonGroup>
          {KVO1aA !== null && (
            <Module0FollowQ
              questionID={KVO1aFollowQID}
              question={KVO1aFollowQ}
              questionOptions={KVO1aFollowQOptions}
              setFollowQAnswer={setFollowQAnswer}
              previousAnswers={KVO1aFollowQPrevAnswers}
              previousAnswerQuestionID={KVO1aFollowQPrevQuestionID}
            />
          )}
          <div className={styles.question}>{KVO2Q}</div>
          <ToggleButtonGroup
            type="radio"
            value={KVO2A}
            name={`answer-radios-KVO2`} // name must be shared by all radio buttons in group
            onChange={val => setKVO2A(val)}
            className={styles.toggleButtons}
          >
            {KVO2QOptions.map((answer, i) => {
              return (
                <ToggleButton
                  key={answer}
                  id={`tbg-btn-KVO2${i + 1}`} // unique id is required in a ToggleButtonGroup
                  value={i} // this id must also be unique in the HTML document
                  className={styles.radios}
                  // the only way to overwrite bootstrap styling
                  style={setButtonStyling(i, KVO2A)}
                >
                  <span className={styles.radioAnswer}>{answer}</span>
                </ToggleButton>
              )
            })}
          </ToggleButtonGroup>
          <div className={styles.question}>{KVO3Q}</div>
          <ToggleButtonGroup
            type="radio"
            value={KVO3A}
            // className="mt-1"
            name={`answer-radios-KVO3`} // name must be shared by all radio buttons in group
            onChange={val => setKVO3A(val)}
            className={styles.toggleButtons}
          >
            {KVO3QOptions.map((answer, i) => {
              return (
                <ToggleButton
                  key={answer}
                  id={`tbg-btn-KVO3${i + 1}`} // unique id is required in a ToggleButtonGroup
                  value={i} // this id must also be unique in the HTML document
                  className={styles.radios}
                  // the only way to overwrite bootstrap styling
                  style={setButtonStyling(i, KVO3A)}
                >
                  <span className={styles.radioAnswer}>{answer}</span>
                </ToggleButton>
              )
            })}
          </ToggleButtonGroup>
          <div className={styles.question}>{KVO4Q}</div>
          <ToggleButtonGroup
            type="checkbox"
            value={KVO4A}
            name={`answer-checkbox-KVO4`} // name must be shared by all radio buttons in group
            className={styles.toggleButtonsNoCol}
          >
            {KVO4QOptions.map((answer, i) => {
              return (
                <ToggleButton
                  key={answer}
                  id={`tbg-btn-KVO4${i + 1}`} // unique id is required in a ToggleButtonGroup
                  value={i} // this id must also be unique in the HTML document
                  onClick={() => onKVO4RadioClick(i)}
                  className={styles.radios}
                  // the only way to overwrite bootstrap styling
                  style={KVO4A[i] ? activeButtonStyling : inactiveButtonStyling}
                >
                  <span className={styles.radioAnswerNoCol}>{answer}</span>
                </ToggleButton>
              )
            })}
          </ToggleButtonGroup>
          <div className={styles.question}>{KVO5Q}</div>
          <Row className={styles.subQuestion}>
            {KVO5SubQ1}
            <Form.Control
              value={KVO5SubQ1A}
              onChange={e => setKVO5SubQ1A(e.target.value)}
              size="sm"
              className={styles.subQuestionAnswer}
              style={answerFontSize}
            />
            %
          </Row>
          <Row className={styles.subQuestion}>
            {KVO5SubQ2}
            <Form.Control
              value={KVO5SubQ2A}
              onChange={e => setKVO5SubQ2A(e.target.value)}
              size="sm"
              className={styles.subQuestionAnswer}
              style={answerFontSize}
            />
            %
          </Row>
          <Row className={styles.subQuestion}>
            {KVO5SubQ3}
            <Form.Control
              value={KVO5SubQ3A}
              onChange={e => setKVO5SubQ3A(e.target.value)}
              size="sm"
              className={styles.subQuestionAnswer}
              style={answerFontSize}
            />
            %
          </Row>
          <Row className={styles.subQuestion}>
            {KVO5SubQ4}
            <Form.Control
              value={KVO5SubQ4A}
              onChange={e => setKVO5SubQ4A(e.target.value)}
              size="sm"
              className={styles.subQuestionAnswer}
              style={answerFontSize}
            />
            %
            <Form.Control
              value={KVO5SubQ4AText}
              onChange={e => setKVO5SubQ4AText(e.target.value)}
              size="sm"
              className={styles.subQuestionAnswerText}
              style={answerFontSize}
            />
          </Row>
          <div className={styles.incomeTotal}>
            <span>{jt.label?.total || "Total"}{': '}</span>
            <span
              className={
                KVO5Total > 100 || isNaN(KVO5Total) ? styles.incomeNOK : ''
              }
            >
              {!isNaN(KVO5Total) && KVO5Total}%{' '}
            </span>
            {KVO5Total < 100 &&
              <span className={styles.stillMissing}>
                ({100 - KVO5Total}{'% '}{jt.label?.stillMissing || "are still missing"})
              </span>
            }
            {KVO5Total > 100 &&
              <span>
                ({jt.label?.max100 || "a maximum of 100%, please"})
              </span>
            }
            {isNaN(KVO5Total) &&
              <span>
                ({jt.label?.onlyNumbers || "only numbers, please"})
              </span>
            }
          </div>
          <div className={styles.question}>{KVP1Q}</div>
          <Form.Control
            value={KVP1A}
            onChange={e => setKVP1A(e.target.value)}
            style={answerFontSize}
          />
          <div className={styles.question}>{KVP2Q}</div>
          <Form.Control
            value={KVP2A}
            onChange={(e) => setKVP2A(e.target.value)}
            style={answerFontSize}
          />
          <div className={styles.question}>{KVP3Q}</div>
          <ToggleButtonGroup
            type="radio"
            value={KVP3A}
            // className="mt-1"
            name={`answer-radios-KVP3`} // name must be shared by all radio buttons in group
            // size="sm"                              // and be distinct from other buttongroups
            onChange={val => setKVP3A(val)}
            className={styles.toggleButtons}
          >
            {KVP3QOptions.map((answer, i) => {
              return (
                <ToggleButton
                  key={answer}
                  id={`tbg-btn-KVP3${i + 1}`} // unique id is required in a ToggleButtonGroup
                  value={i} // this id must also be unique in the HTML document
                  className={styles.radios}
                  // the only way to overwrite bootstrap styling
                  style={setButtonStyling(i, KVP3A)}
                >
                  <span className={styles.radioAnswer}>{answer}</span>
                </ToggleButton>
              )
            })}
          </ToggleButtonGroup>
          {/*<Button*/}
          {/*  onClick={onFormSubmit}*/}
          {/*  type="submit"*/}
          {/*  variant="warning"*/}
          {/*  className={styles.submitBtn}*/}
          {/*  style={answerFontSize}*/}
          {/*>*/}
          {/*  Antworten speichern!*/}
          {/*</Button>*/}
          <br/><br/><br/>
          <ProgressBarContainer
            btnLabel={saveAnsBtnText}
            progress={percentComplete}
            handleButtonClick={onFormSubmit}
          />
        </Form>
      )}
      {/*{infoSavedMsg && (*/}
      {/*  <Button*/}
      {/*    onClick={() => navigate('/survey')}*/}
      {/*    type="submit"*/}
      {/*    variant="secondary"*/}
      {/*    className={styles.infoSavedBtn}*/}
      {/*    style={answerFontSize}*/}
      {/*  >*/}
      {/*    {infoSavedMsg === 'info complete' ? (*/}
      {/*      <div>*/}
      {/*        Information vollständig und abgespeichert!*/}
      {/*        <br />*/}
      {/*        Weiter zu Modul 1?*/}
      {/*      </div>*/}
      {/*    ) : (*/}
      {/*      <div>*/}
      {/*        Information gespeichert aber nicht vollständig.*/}
      {/*        <br />*/}
      {/*        Weiter zu Modul 1 trotzdem?*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </Button>*/}
      {/*)}*/}

    </Container>
  )
}

export default Module0
