import axios from 'axios'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateSurveyStatus } from '../../features/user/userSlice'
import ProgressBarContainer from '../common/ProgressBarContainer/ProgressBarContainer'
import Module5MOD from './Module5MOD'
import Module5PER1 from './Module5PER1'
import Module5PER23 from './Module5PER23'
import Module5PER4 from './Module5PER4'
import Module5PER5af from './Module5PER5af'
import Module5PER5gm from './Module5PER5gm'
import { formatDate } from '../../utils/formatDate'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { authHeaders } from '../../utils/authHeaders'
import { answerSavedMsg } from '../../utils/answerSavedMsg'
import { saveAnswersInDB } from '../../utils/saveAnswersInDB'
import { showMissingAnswerWarning, showCategoryNotSetWarning } from '../../utils/getMiscToastMsg'
import styles from './Module5.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// render survey Module 5 (Step 6); called from SurveyFull.js
const Module5 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  // console.log('user:', user)
  const [progress, setProgress] = useState(0) // in %
  const [progressMOD, setProgressMOD] = useState(0) // number of answered MOD questions
  const [progressPER1, setProgressPER1] = useState(0)
  const [progressPER23, setProgressPER23] = useState(0)
  const [progressPER4, setProgressPER4] = useState(0)
  const [progressPER5af, setProgressPER5af] = useState(0)
  const [progressPER5gm, setProgressPER5gm] = useState(0)

  const [modQuestions, setModQuestions] = useState(false)
  const [per1Questions, setPer1Questions] = useState(false)
  const [per23Questions, setPer23Questions] = useState(false)
  const [per4Questions, setPer4Questions] = useState(false)
  const [per5afQuestions, setPer5afQuestions] = useState(false)
  const [per5gmQuestions, setPer5gmQuestions] = useState(false)
  const [questionsReceived, setQuestionsReceived] = useState(false)
  const [sectionToRender, setSectionToRender] = useState('module5MOD')

  const [MODPrevAnswers, setMODPrevAnswers] = useState({})
  const [PER1PrevAnswers, setPER1PrevAnswers] = useState({})
  const [PER23PrevAnswers, setPER23PrevAnswers] = useState({})
  const [PER4PrevAnswers, setPER4PrevAnswers] = useState({})
  const [PER5afPrevAnswers, setPER5afPrevAnswers] = useState({})
  const [PER5gmPrevAnswers, setPER5gmPrevAnswers] = useState({})
  const [MODAnswers, setMODAnswers] = useState({})
  const [PER1Answers, setPER1Answers] = useState({})
  const [PER23Answers, setPER23Answers] = useState({})
  const [PER4Answers, setPER4Answers] = useState({})
  const [PER5afAnswers, setPER5afAnswers] = useState({})
  const [PER5gmAnswers, setPER5gmAnswers] = useState({})

  useEffect(() => {
    if (!user.userID) navigate('/survey')
    if (user.userID && !user.orgCategory) showCategoryNotSetWarning(jt.toast || {})

    // load questions
    axios
      .get(`${API_URL}/api/survey-questions?filters[module][$eq]=5`)
      .then(res => {
        let module5Questions = res.data.data
        module5Questions = module5Questions.map(item => {
          return {
            questionID: item.attributes.questionID,
            question1: item.attributes.question1,
            question2: item.attributes.question2,
            answerArray: item.attributes.answerArray?.answerArray || null,
            answerLabels: item.attributes.answerLabels?.answerLabels || null,
            format: item.attributes.format,
            subQuestion1: item.attributes.SubQuestion1,
            subQuestion2: item.attributes.SubQuestion2,
          }
        })

        const modQs = module5Questions.filter(item => {
          return item.questionID.substring(0, 3) === 'MOD'
        })
        modQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
        setModQuestions(modQs)

        const per1Qs = module5Questions.filter(item => {
          return item.questionID.substring(0, 4) === 'PER1'
        })
        per1Qs.sort((a, b) => a.questionID.localeCompare(b.questionID))
        setPer1Questions(per1Qs)

        const per23Qs = module5Questions.filter(item => {
          return (
            item.questionID.substring(0, 4) === 'PER2' ||
            item.questionID.substring(0, 4) === 'PER3'
          )
        })
        per23Qs.sort((a, b) => a.questionID.localeCompare(b.questionID))
        setPer23Questions(per23Qs)

        const per4Qs = module5Questions.filter(item => {
          return item.questionID.substring(0, 4) === 'PER4'
        })
        per4Qs.sort((a, b) => a.questionID.localeCompare(b.questionID))
        setPer4Questions(per4Qs)

        const per5Qs = module5Questions.filter(item => {
          return item.questionID.substring(0, 4) === 'PER5'
        })
        per5Qs.sort((a, b) => a.questionID.localeCompare(b.questionID))
        setPer5afQuestions(per5Qs.slice(0, 6))
        setPer5gmQuestions(per5Qs.slice(6))

        setQuestionsReceived(true)
      })
      .catch(error => handleStrapiAPIError(error))

    // load previous answers if they exist
    axios
      .get(API_URL + '/api/users/me', authHeaders())
      .then(res => {
        const previousAnswers = res.data.module5 || {}
        // console.log('previousAnswers:', previousAnswers)
        if (Object.keys(previousAnswers).length) {
          let MODAns = {}
          let PER1Ans = {}
          let PER23Ans = {}
          let PER4Ans = {}
          let PER5afAns = {}
          let PER5gmAns = {}
          for (const questKey in previousAnswers) {
            if (questKey.startsWith('MOD')) MODAns[questKey] = previousAnswers[questKey]
            if (questKey.startsWith('PER1')) PER1Ans[questKey] = previousAnswers[questKey]
            if (questKey.startsWith('PER2') || questKey.startsWith('PER3'))
              PER23Ans[questKey] = previousAnswers[questKey]
            if (questKey.startsWith('PER4')) PER4Ans[questKey] = previousAnswers[questKey]
            if (questKey.startsWith('PER5') && /[a-f]/.test(questKey[4]))
              PER5afAns[questKey] = previousAnswers[questKey]
            if (questKey.startsWith('PER5') && /[g-m]/.test(questKey[4]))
              PER5gmAns[questKey] = previousAnswers[questKey]
          }
          setMODPrevAnswers(MODAns)
          setPER1PrevAnswers(PER1Ans)
          setPER23PrevAnswers(PER23Ans)
          setPER4PrevAnswers(PER4Ans)
          setPER5afPrevAnswers(PER5afAns)
          setPER5gmPrevAnswers(PER5gmAns)
          // get the saved progress for each section, also
          setProgressMOD(previousAnswers.progressMOD ?? 0)
          setProgressPER1(previousAnswers.progressPER1 ?? 0)
          setProgressPER23(previousAnswers.progressPER23 ?? 0)
          setProgressPER4(previousAnswers.progressPER4 ?? 0)
          setProgressPER5af(previousAnswers.progressPER5af ?? 0)
          setProgressPER5gm(previousAnswers.progressPER5gm ?? 0)
        }
      })
      .catch(error => handleStrapiAPIError(error))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const advance = async () => {
    // save progress in DB
    const newSurveyStatus = {
      ...user.surveyStatus,
      module5: `${progress}%`,
      lastUpdate: formatDate(new Date()),
    }
    dispatch(updateSurveyStatus({ surveyStatus: newSurveyStatus }))
    // if new answer objects have been filled, use them; else use previous answers
    const saveMODAnswers = Object.keys(MODAnswers).length ? MODAnswers : MODPrevAnswers
    const savePER1Answers = Object.keys(PER1Answers).length ? PER1Answers : PER1PrevAnswers
    const savePER23Answers = Object.keys(PER23Answers).length ? PER23Answers : PER23PrevAnswers
    const savePER4Answers = Object.keys(PER4Answers).length ? PER4Answers : PER4PrevAnswers
    const savePER5afAnswers = Object.keys(PER5afAnswers).length ? PER5afAnswers : PER5afPrevAnswers
    const savePER5gmAnswers = Object.keys(PER5gmAnswers).length ? PER5gmAnswers : PER5gmPrevAnswers
    const allModule5Answers = {
      ...saveMODAnswers, ...savePER1Answers, ...savePER23Answers,
      ...savePER4Answers, ...savePER5afAnswers, ...savePER5gmAnswers,
    }
    const module5Progress = {
      progressMOD, progressPER1, progressPER23, progressPER4,
      progressPER5af, progressPER5gm,
    }
    await axios
      .put(
        `${API_URL}/api/users/${user.userID}`,
        {
          surveyStatus: newSurveyStatus,
          module5: { ...allModule5Answers, ...module5Progress },
        },
        authHeaders(),
      )
      .then(() => answerSavedMsg(sectionToRender !== 'module5PER5gm'))
      .catch(error => handleStrapiAPIError(error))
    if (progress === 100) {
      /* note: changing all "Ja" answers to 1 and all "Nein" answers to 0
         is not needed in Module 5 */
      saveAnswersInDB(user, '5', allModule5Answers)
    }

    if (sectionToRender === 'module5MOD') {
      if (progressMOD < modQuestions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module5PER1')
    }
    if (sectionToRender === 'module5PER1') {
      if (progressPER1 < per1Questions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module5PER23')
    }
    if (sectionToRender === 'module5PER23') {
      if (progressPER23 < per23Questions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module5PER4')
    }
    if (sectionToRender === 'module5PER4') {
      if (progressPER4 < per4Questions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module5PER5af')
    }
    if (sectionToRender === 'module5PER5af') {
      if (progressPER5af < per5afQuestions.length) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module5PER5gm')
    }
    if (sectionToRender === 'module5PER5gm') {
      if (progress < 100) showMissingAnswerWarning(jt.toast)
      navigate('/survey')
    }
  }

  const updateProgress = (numberOfAnsweredQs, questionSection) => {
    const totalNumberOfQs =
      modQuestions.length +
      per1Questions.length +
      per23Questions.length +
      per4Questions.length +
      per5afQuestions.length +
      per5gmQuestions.length
    switch (questionSection) {
      case 'MOD':
        setProgressMOD(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((numberOfAnsweredQs +
              progressPER1 +
              progressPER23 +
              progressPER4 +
              progressPER5af +
              progressPER5gm) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'PER1':
        setProgressPER1(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressMOD +
              numberOfAnsweredQs +
              progressPER23 +
              progressPER4 +
              progressPER5af +
              progressPER5gm) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'PER23':
        setProgressPER23(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressMOD +
              progressPER1 +
              numberOfAnsweredQs +
              progressPER4 +
              progressPER5af +
              progressPER5gm) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'PER4':
        setProgressPER4(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressMOD +
              progressPER1 +
              progressPER23 +
              numberOfAnsweredQs +
              progressPER5af +
              progressPER5gm) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'PER5af':
        setProgressPER5af(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((progressMOD +
              progressPER1 +
              progressPER23 +
              progressPER4 +
              numberOfAnsweredQs +
              progressPER5gm) /
              totalNumberOfQs) *
              100
          )
        )
        break
      case 'PER5gm':
        setProgressPER5gm(numberOfAnsweredQs)
        const newProgress = Math.round(
          ((progressMOD +
            progressPER1 +
            progressPER23 +
            progressPER4 +
            progressPER5af +
            numberOfAnsweredQs) /
            totalNumberOfQs) *
          100
        )
        setProgress(newProgress)
        break
      default:
        console.log(`This questionSection:${questionSection} is unknown!`)
    }
  }

  const getSaveAnsBtnText = () => {
    return jt.label?.saveAnswers || 'Save current answers'
    /*
    let text
    // if current section is finished, show 'save and advance to next page'
    if ((sectionToRender === 'module5MOD' && progressMOD === modQuestions.length) ||
      (sectionToRender === 'module5PER1' && progressPER1 === per1Questions.length) ||
      (sectionToRender === 'module5PER23' && progressPER23 === per23Questions.length) ||
      (sectionToRender === 'module5PER4' && progressPER4 === per4Questions.length) ||
      (sectionToRender === 'module5PER5af' && progressPER5af === per5afQuestions.length)
    ) text = jt.label?.saveAndAdvance || 'Save and advance'
    // if all questions are answered, show 'save and finish'
    else if (progress === 100) text = jt.label?.saveAndFinish || 'Save and finish'
    // Otherwise, simply show 'save current answers'
    else text = jt.label?.saveAnswers || 'Save current answers'
    return text
    */
  }

  return (
    <div className={styles.surveyContainer}>
      <h6 className={styles.moduleHeader}>
        {jt.header?.module5 || 'Module 5'}
      </h6>
      <h1 className='mb-5'>{jt.header?.module5Title || 'Performance'}</h1>
      <br />
      <Container>
        {questionsReceived && sectionToRender === 'module5MOD' && (
          <Module5MOD
            questions={modQuestions}
            previousAnswers={MODPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setMODAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module5PER1' && (
          <Module5PER1
            questions={per1Questions}
            previousAnswers={PER1PrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setPER1Answers}
          />
        )}
        {questionsReceived && sectionToRender === 'module5PER23' && (
          <Module5PER23
            questions={per23Questions}
            previousAnswers={PER23PrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setPER23Answers}
          />
        )}
        {questionsReceived &&
          sectionToRender === 'module5PER4' &&
          questionsReceived && (
            <Module5PER4
              questions={per4Questions}
              previousAnswers={PER4PrevAnswers}
              updateProgress={updateProgress}
              updateAnswers={setPER4Answers}
            />
          )}
        {questionsReceived && sectionToRender === 'module5PER5af' && (
          <Module5PER5af
            questions={per5afQuestions}
            previousAnswers={PER5afPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setPER5afAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module5PER5gm' && (
          <Module5PER5gm
            questions={per5gmQuestions}
            previousAnswers={PER5gmPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setPER5gmAnswers}
          />
        )}
      </Container>

      <ProgressBarContainer
        btnLabel={getSaveAnsBtnText()}
        progress={progress}
        handleButtonClick={advance}
      />
    </div>
  )
}

export default Module5
