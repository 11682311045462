
import { useEffect, useState } from "react"
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import QuestionFormRadio from '../common/QuestionFormRadio/QuestionFormRadio'
import { GEM_OR_KOP_SHORT_LENGTH, giveAnswer } from '../../utils/giveAnswer'
import { CONSTANTS } from '../../utils/constants'
import styles from './Module1.module.scss'

// render the GEM section of Module 1 (Step 2) of the full survey; called by Module1.js
const Module1GEM = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}) // future object like { "GEM0": 1, "GEM6": [], ...}
  const [GEM6A, setGEM6A] = useState([])  // array of true/false for each option

  // console.log('questions:', questions)
  const GEM6QOptions = questions.filter(q => q.questionID === 'GEM6')[0].answerArray  // checkbox options

  useEffect(() => {
    /* set up initialAnswerObj: { GEM0: null, GEM1: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    // set up the array for handling the GEM6 checkbox answers
    const initArray = new Array(GEM6QOptions.length).fill(false)
    setGEM6A(initArray)
    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update GEM6A, if not null
      const GEM6Array = previousAnswers.GEM6
      if (Array.isArray(GEM6Array)) {
        GEM6Array.forEach(item => {
          initArray[GEM6QOptions.indexOf(item)] = true
        })
        setGEM6A(prev => initArray)
      }
      // also update progress bar, if GEM0 has been answered 'no' ('Nein'), reduce questions from 7 to 1
      const numGemQs = previousAnswers.GEM0 === questions[0].answerArray[1] ?
        GEM_OR_KOP_SHORT_LENGTH
        : questions.length
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'GEM', numGemQs)
    }
    setAnswerObj(initialAnswerObj)
    updateAnswers(initialAnswerObj) // send answers to Module1.js for saving in DB

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousAnswers])

  useEffect(() => {
    // if answers to GEM6 are set to null by checking No/Nein to GEM0,
    // also update the GEM6 answer array to false
    if (answerObj.GEM6 === null) {
      setGEM6A(new Array(GEM6QOptions.length).fill(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerObj.GEM6])

  const onGEM6CheckboxClick = (idx) => {
    const newAnswerArray = [...GEM6A]  // create new array
    newAnswerArray[idx] = !newAnswerArray[idx]
    // if final option 'Nur Mitarbeitende' was checked, uncheck all the rest and vice-versa
    if (idx !== newAnswerArray.length - 1 && newAnswerArray[idx] === true) {
      newAnswerArray[newAnswerArray.length - 1] = false
    }
    if (idx === newAnswerArray.length - 1 && newAnswerArray[idx] === true) {
      newAnswerArray.forEach((item, i) => {
        if (i !== newAnswerArray.length - 1) newAnswerArray[i] = false
      })
    }
    setGEM6A(newAnswerArray)
    const answers = []
    newAnswerArray.forEach((answer, idx) => {
      if (answer) answers.push(GEM6QOptions[idx])
    })
    const newGEMAnsObj = { ...answerObj }
    const GEM6 = questions[questions.length - 1].questionID // should be 'GEM6'
    newGEMAnsObj[GEM6] = answers
    setAnswerObj(newGEMAnsObj)
    updateAnswers(newGEMAnsObj) // send answers to Module1.js for saving in DB
    // calculate number of answered questions
    const numAnsweredQs = Object.values(newGEMAnsObj).filter(answer => answer !== null).length
    // finally, update progress bar
    updateProgress(numAnsweredQs, 'GEM')
  }

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      // if first question has been answered 'no', hide the rest of the questions
      if (idx >= 1 && answerObj[questions[0].questionID] === questions[0].answerArray[1]) return null
      return <div key={question.questionID}>
        {question.questionID !== 'GEM6' ?
          <div>
            <QuestionFormRadio
              questionIndex={idx}
              question={question.question1}
              questionFormat={question.format}
              answers={question.answerArray}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'GEM', val, questionIndex, null, questions, answerObj,
                setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
          :
          <div>
            <div className={styles.question}>{question.question1}</div>
            <ToggleButtonGroup
              type='checkbox'
              value={GEM6A}
              name={`answer-checkbox-Q${question.questionID}`}  // name must be shared by all radio buttons in group
              // size="sm"                              // and be distinct from other buttongroups
              // onChange={(val) => setKVO4A(val)}
              className={styles.toggleButtons6}
            >
              {question.answerArray.map((answer, i) => {
                return (
                  <ToggleButton
                    key={answer}
                    id={`tbg-btn-GEM6${i + 1}`} // unique id is required in a ToggleButtonGroup
                    value={i}                       // this id must also be unique in the HTML document
                    onClick={() => onGEM6CheckboxClick(i)}
                    className={styles.radios}
                    // the only way to overwrite bootstrap styling
                    style={GEM6A[i] ?
                      {
                        borderColor: CONSTANTS.colors.benchLogoOrange,
                        borderRadius: '50%',
                        margin: '10px',
                        padding: '2px',
                        backgroundColor: CONSTANTS.colors.benchLogoOrange,
                      }
                      :
                      {
                        borderColor: CONSTANTS.colors.benchLogoOrange,
                        borderRadius: '50%',
                        margin: '10px',
                        padding: '2px',
                        backgroundColor: CONSTANTS.colors.white,
                      }
                    }
                  >
                    <span className={styles.radioAnswer}>{answer}</span>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
            <hr className={styles.horizontalLine}></hr>
          </div>
        }
      </div>
    })}
  </div>)
}

export default Module1GEM;
