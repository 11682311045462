import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Col, ListGroup } from 'react-bootstrap'
import { ReactComponent as EditIcon } from '../../assets/icons/edit-Icon.svg'
import { ReactComponent as RocketIcon } from '../../assets/icons/rocket-Icon.svg'
import { ReactComponent as StatisticsIcon } from '../../assets/icons/statistics-Icon.svg'
import { CONSTANTS } from '../../utils/constants'
import { gotoLink, gotoSurveyLink } from './SurveyFullUtils'
import styles from './SurveyFull.module.scss'

const MODULE_0_INDEX = 5
const MODULE_1_INDEX = 0
/**
 * Renders the horizontal link items for Modules 0 and 1
 * Called by SurveyFull.js and Demo.js.
 * @param index, number, 0 = Module 1; 5 = Module 0
 * @param item, object
 * @param surveyStatus, object
 * @return {JSX.Element}
 */
const HorizontalItem = ({ index, item, surveyStatus, orgSurveysDone, noEdit }) => {
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)
  const currentSurveyStatus = index === MODULE_0_INDEX ?
    surveyStatus[`module0`] : surveyStatus[`module1`]
  // if screen width > 992 set the column size for the edit icon to 1; else 2
  const editColSize = window.innerWidth > 992 ? 1 : 2
  return (
    <LinkContainer
      to={gotoLink(index, item, surveyStatus)}
      className={styles.linkContainer}
      style={{
        border: `1px solid ${index === MODULE_1_INDEX ? CONSTANTS.colors.EOGesamtmodellRed : CONSTANTS.colors.lightGrayLines}`,
        color: index === MODULE_1_INDEX ? CONSTANTS.colors.EOGesamtmodellRed : 'inherit',
      }}
    >
      <ListGroup.Item action className='d-flex align-items-center p-4'>
        <Col xs={2}>
          <h1 className={styles.surveyStatus}>
            {currentSurveyStatus || '0%'}
          </h1>
          <h6 className={styles.filledOut}>
            {jt.header?.filledOut || 'Filled out'}
          </h6>
        </Col>
        <Col xs={index === MODULE_1_INDEX && currentSurveyStatus === '100%' ? (8 - editColSize) : 8}>
          <h6 style={{ opacity: 0.6 }}>
            {item.module.toLocaleUpperCase()}
            <span className='mx-3'>|</span>
            {jt.label?.approx || 'Approx.'} {item.minutes} {jt.label?.minutes || 'Minutes'}
          </h6>
          <h2 style={{ textTransform: 'none' }}>{item.title}</h2>
          {index === MODULE_1_INDEX && orgSurveysDone ?
            <div className={styles.surveysFinished}>
              {jt.label?.surveysFinished || 'Surveys finished'}{': '} {orgSurveysDone.module1}
            </div>
            : <h5 className={styles.subTitle}>{item.subtitle}</h5>
          }
        </Col>
        {index === MODULE_0_INDEX && (
          <Col xs={2} className={'text-center'}>
            <EditIcon
              className={styles.smallEditIcon}
              alt='edit icon'
              style={{
                fill: surveyStatus.module0 === '100%'
                  ? CONSTANTS.colors.green // $green-icon
                  : CONSTANTS.colors.orange, // $orange-button
              }}
            />
            <div
              className={styles.smallEditText}
              style={{
                color: surveyStatus.module0 === '100%'
                  ? CONSTANTS.colors.green // $green-icon
                  : CONSTANTS.colors.orange, // $orange-button
              }}
            >
              {jt.label?.edit || 'Edit'}
            </div>
          </Col>
        )}
        {index === MODULE_1_INDEX && currentSurveyStatus !== '100%' && !noEdit && (
          <Col xs={2} className={'text-center'}>
            <RocketIcon className={styles.rocketIcon} />
            {(!currentSurveyStatus ||
              currentSurveyStatus === '0%') ? (
                <div className={styles.startNowText}>
                  {jt.label?.startNow || 'Start now'}
                </div>
              )
              :
              (
                <div className={styles.continueText}>
                  {jt.label?.continue || 'Continue'}
                </div>
              )
            }
          </Col>
        )}
        {index === MODULE_1_INDEX && (currentSurveyStatus === '100%' && !noEdit) && (
          <Col
            xs={editColSize}
            className={styles.editFinishedModule}
            onClick={e => gotoSurveyLink(e, item, navigate)}
          >
            <EditIcon className={styles.tinyEditIcon} alt='edit icon' />
            <div className={styles.tinyEditText}>
              {jt.label?.edit || 'Edit'}
            </div>
          </Col>
        )}
        {index === MODULE_1_INDEX && (currentSurveyStatus === '100%' || noEdit) && (
          <Col
            xs={2}
            className={'text-center'}
          >
            <StatisticsIcon className={styles.statisticsIcon} />
            <div className={styles.statisticsText}>
              {jt.label?.results || 'Results'}
            </div>
          </Col>
        )}
      </ListGroup.Item>
    </LinkContainer>
  )
}

export default HorizontalItem
