/**
 * reuse multiple toast messages
 * see also answerSavedMsg.js (also invokes Toast directly) and
 * handleStrapiAPIError.js (also invokes Toast directly)
 */

import { toast } from 'react-toastify'
import { MIN_PASS_LENGTH } from './constants'

/**
 * called by Register.js, Login.js and ForgotPassword.js
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {string} the complete error message string
 */
export const getInvalidEmailMsg = (jtToast) => {
  return jtToast?.invalidEmail || "Your email address is invalid.\nPlease check and try again."
}

/**
 * called by Login.js and ForgotPassword.js
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {string} the complete error message string
 */
export const getMissingEmailMsg = (jtToast) => {
  return jtToast?.missingEmail || "Your email address is missing.\nPlease check and try again."
}

/**
 * called by Register.js, Login.js and ResetPassword.js
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {string} the complete error message string
 */
export const getPwTooShortMsg = (jtToast) => {
  const firstPart = jtToast?.minPasswordLength || "The minimum password length is"
  const secondPart = jtToast?.pleaseCheckAndTryAgain || "Please check and try again."
  return firstPart + ' ' + MIN_PASS_LENGTH + '.\n' + secondPart
}

/**
 * called by Register.js and ResetPassword.js
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {string} the complete error message string
 */
export const getPwMismatchMsg = (jtToast) => {
  return jtToast?.pwMismatchError || "Password and repeated password do not match.\nPlease check and try again."
}

/**
 * called by Register.js and ResetPassword.js
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {string} the complete error message string
 */
export const getMissingFieldsMsg = (jtToast) => {
  return jtToast?.missingFieldsError || "One or more input fields are missing.\nPlease check and try again."
}

/**
 * called by ChangePassword.js and ForgotPassword.js
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {string} the complete success message string
 */
export const getLinkSentMsg = (jtToast) => {
  return jtToast?.passwordResetLinkSent || "Your password reset link hast been sent!"
}

/**
 * forms the string for the toast.success message after successfully connecting
 * the user to an organization
 * @param orgName, string, the name of the organization
 * @param username, string, the user's first name
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @param type, string, either 'create' or 'select'
 * @return {string} the complete success string
 */
export const getOrgConnectSuccessMsg = (orgName, username, jtToast, type) => {
  const firstPart = type === 'create' ?
    jtToast?.hasBeenCreatedAndConnected || 'has been created and connected to'
    : jtToast?.hasBeenConnectedTo || 'has been connected to'
  const secondPart = jtToast?.usersProfileConnected || '\'s profile'
  const redirect = jtToast?.youWillBeRedirected || 'You will be redirected shortly...'
  return orgName + ' ' + firstPart + ' ' + username + secondPart + '!\n' + redirect
}

/**
 * returns a toast warning message that not all questions were answered and
 * therefore the benchmark comparison cannot be run
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {toast} the toast object
 */
export const showMissingAnswerWarning = (jtToast = {}) => {
  toast.warning(
    jtToast.atLeastOneAnswerMissing ||
    'At least one answer is missing.\nAll questions must be answered for the benchmark comparison to run.',
  )
}

/**
 * returns a toast warning message that the user is not linked to an organization and must select one
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {toast} the toast object
 */
export const showMissingOrgError = (jtToast) => {
  toast.error(
    jtToast.missingOrg || 'You are not currently linked to an organization.\nPlease select yours here or create a new one:',
    { autoClose: 10000 })
}

/**
 * returns a toast warning message that the organization category is missing
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {toast} the toast object
 */
export const showCategoryNotSetWarning = (jtToast) => {
  toast.warning(
    jtToast.orgCategoryNotSet || 'No category for your organization has been set',
    { autoClose: 8000 })
}

/**
 * returns a toast info message that the benchmark for the organization category has not yet been established
 * @param jtToast, JSON, the toast part of the JsonText object in the current language
 * @return {toast} the toast object
 */
export const showNoCategoryBenchInfo = (jtToast = {}) => {
  toast.info(
    jtToast.noBenchmarkExistsForYourCategory || 'The benchmark for your organization category has not yet been established',
  )
}
