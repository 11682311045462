import { useSelector } from 'react-redux'
import { Col, Container, Image, Row } from 'react-bootstrap'
import VMILogo from '../../assets/images/Logo-VMI.png'
import Footer from '../Footer/Footer'
import LogosHeader from '../common/LogosHeader/LogosHeader'
import ResearchBadge from '../common/ResearchBadge/ResearchBadge'
import { VMI_GOOGLE_MAP_URL, VMI_URL } from '../../utils/constants'
import styles from './LegalNotice.module.scss'

const LegalNotice = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  return <div>
    <div className={styles.pictureBackground}>
      <ResearchBadge jtLabel={jt.label || {}} />
      <h1>
        {jt.label?.legalNotice || 'Legal Notice'}
      </h1>
    </div>

    <LogosHeader jtLabel={jt.label || {}} />

    <Container className={styles.mainContent}>
      <div className={styles.title}>{jt.header?.websiteOperator || 'Website Operator'}</div>
      <Row className={'align-items-center  mx-5'}>
        <Col xs={6} md={4} lg={3} className={'d-flex justify-content-end'}>
          <Image
            src={VMILogo}
            className={styles.image}
            alt={'VMI Logo'}
            onClick={() => window.open(VMI_URL)}
          />
        </Col>
        <Col className={styles.rightCol}>
          <div>{jt.header?.vmiTitle || 'Institut für Verbands-, Stiftungs- und Genossenschaftsmanagement (VMI)'}</div>
          <div className={styles.elementContainer}>
            <span
              className={styles.element}
              onClick={() => window.open(VMI_GOOGLE_MAP_URL)}
            >
              {jt.label?.vmiAddressLine1 || 'Boulevard de Pérolles 90'}
            </span>
          </div>
          <div className={styles.elementContainer}>
            <span
              className={styles.element}
              onClick={() => window.open(VMI_GOOGLE_MAP_URL)}
            >
              {jt.label?.vmiAddressLine2 || '1700 Freiburg'}
            </span>
          </div>
          <div className={styles.elementContainer}>
            <span
              className={styles.element}
              onClick={() => window.open(`mailto:${jt.label?.vmiEmail || 'info@vmi.ch'}`)}
            >
              {jt.label?.vmiEmail || 'info@vmi.ch'}
            </span>
          </div>
          <div className={styles.elementContainer}>
            <span
              className={styles.element}
              onClick={() => window.open(`tel:${jt.label?.vmiTel || '+41 26 300 84 00'}`)}
            >
              {jt.label?.vmiTel || '+41 26 300 84 00'}
            </span>
          </div>
        </Col>
      </Row>

      <br />
    </Container>

    <Footer />
  </div>
}

export default LegalNotice
