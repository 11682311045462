import { configureStore } from "@reduxjs/toolkit";
import answersReducer from "../features/answers/answerSlice";
import jsonTextReducer from "../features/jsonText/jsonTextSlice";
import userReducer from "../features/user/userSlice";

export const store = configureStore({
  reducer: {
    answers: answersReducer,
    jsonText: jsonTextReducer,
    user: userReducer
  }
})
