import { countSubQAns } from './countSubQAns'

// if answer to GEM0 or KOP0 is 'No' ('Nein'), all GEM or KOP follow-up questions are suppressed and reset to null
export const GEM_OR_KOP_SHORT_LENGTH = 1

// if answer to PRO9 is 'No' ('Nein'), IVB (GEM7-10 + PRO10-14) questions are suppressed and reset to null
export const IVB_SHORT_LENGTH = 1

// if answer to AGR0 is 'Keine', these follow-up questions are suppressed and reset to null
export const AGROptionalQs = ['AGR1', 'AGR2', 'AGR3']

// Big Document, p.7: depending on answer to KON0, these follow-up questions are suppressed and reset to null
const KONaQs = ['KON1a', 'KON2a', 'KON3a']
const KONbQs = ['KON1b', 'KON2b', 'KON3b']

// Big Document, p.10: if answer to KVE4a is 'Nein', these follow-up questions are suppressed and reset to null
export const KVE4OptionalQs = ['KVE4b', 'KVE4c', 'KVE4d', 'KVE4e']
const SECTIONS_WITH_SUB_QUESTIONS = ['IPQ', 'PER4', 'PER5af', 'PER5gm']

/**
 * handles answering questions for Modules 1 to 5
 * @param section string, 'GEM', 'IVB', 'KOP', 'AGR', 'Other', 'MLQ', 'SVL', 'IPQ', 'KON',
 * 'KVI', 'KVE1', 'KVE23', 'KVE45', 'MOD', 'PER1', 'PER23', 'PER4', 'PER5af' or 'PER5gm'
 * @param val number, is the answer value
 * @param questionIndex number, is the index of the question in the questions array of the current section
 * @param subQuestion string|null, the subQuestion of the current question
 * @param questions [ objects ], is the questions array of the current section
 * @param answerObj object, contains all the questions answered thus far for the current section
 * @param setAnswerObj function, is the state setter for the answerObj from the current section
 * @param updateAnswers function, is the state setter for answer object at Module level (e.g. gemAnswers object in Module1.js)
 * @param updateProgress function, is a callback to the updateProgress function at Module level.
 */
export const giveAnswer = (
  section,
  val,
  questionIndex,
  subQuestion,
  questions,
  answerObj,
  setAnswerObj,
  updateAnswers,
  updateProgress
) => {
  let newAnswerObj = { ...answerObj } // create new object
  const questionID = questions[questionIndex].questionID

  if (section === 'IPQ' && (val === -1 || val === null)) {
    // this means the IPQ offSwitch was clicked, either ON or OFF respectively
    for (const subQuestion in newAnswerObj[questionID]) {
      newAnswerObj[questionID][subQuestion] = val
    }
  } else if (SECTIONS_WITH_SUB_QUESTIONS.includes(section)) {
    // these sections refer to questions with sub-questions to the side
    newAnswerObj[questionID][subQuestion] = val
  } else {
    // the rest are normal questions with the circles beneath them
    newAnswerObj[questionID] = val
  }

  let newNumberOfQs // this number may need to be reset for GEM, KOP or AGR questions
  if (section === 'GEM' || section === 'KOP') {
    // if answer to first question is 'no', reduce number of GEM/KOP questions
    // and reset the other GEM/KOP questions to null
    if (newAnswerObj[questions[0].questionID] === questions[0].answerArray[1]) {
      newNumberOfQs = GEM_OR_KOP_SHORT_LENGTH
      for (const questKey in newAnswerObj) {
        if (questKey === questions[0].questionID) continue
        newAnswerObj[questKey] = null
      }
    }
  }
  if (section === 'IVB') {
    // if answer to first (IVB0) question is 'no', reduce number of IVB questions (IVB0 + GEM7-10 + PRO10-14 = Interessenverbände)
    // and reset the other IVB questions to null
    if (newAnswerObj[questions[0].questionID] === questions[0].answerArray[1]) {
      newNumberOfQs = IVB_SHORT_LENGTH
      for (const questKey in newAnswerObj) {
        if (questKey === questions[0].questionID) continue
        newAnswerObj[questKey] = null
      }
    }
  }
  if (section === 'AGR') {
    // if answer to AGR0 is 'keine', reduce number of AGR questions and reset AGR1-3 to null
    if (newAnswerObj[questions[0].questionID] === questions[0].answerArray[0]) {
      newNumberOfQs = questions.length - AGROptionalQs.length
      for (const questKey of AGROptionalQs) {
        newAnswerObj[questKey] = null
      }
    }
  }
  if (section === 'KON') {
    const result = adaptNewAnswerObjForKON({ ...newAnswerObj }, questions)
    newAnswerObj = {...result.konAnswerObj}
    newNumberOfQs = result.newNumberOfQs
  }
  if (section === 'KVE45') {
    // if answer to KVE4a is 'Nein', reduce number of KVE4 questions and reset KVE4b-e to null
    if (newAnswerObj[questions[0].questionID] === questions[0].answerArray[1]) {
      newNumberOfQs = questions.length - KVE4OptionalQs.length;
      for (const questKey of KVE4OptionalQs) {
        newAnswerObj[questKey] = null
      }
    }
  }

  setAnswerObj(newAnswerObj)
  updateAnswers(newAnswerObj); // send answers to Module level for saving in DB

  // calculate number of answered questions
  const numAnsweredQs = SECTIONS_WITH_SUB_QUESTIONS.includes(section) ?
    countSubQAns(newAnswerObj)
    : Object.values(newAnswerObj).filter(answer => answer !== null).length;

  // finally, update progress bar
  updateProgress(numAnsweredQs, section, newNumberOfQs)
}

export function adaptNewAnswerObjForKON(konAnswerObj, questions) {
  let newNumberOfQs = questions.length
  switch (konAnswerObj[questions[0].questionID]) {  // 'KON0'
    case questions[0].answerArray[0]:  // 'Teil des Vorstands bzw. des strategischen Organs'
      newNumberOfQs -= KONaQs.length
      KONaQs.forEach(questKey => konAnswerObj[questKey] = null)
      break
    case questions[0].answerArray[1]:  // 'CEO, Direktor:in, Manager:in, usw. (operative Leiterschaft)'
      newNumberOfQs -= KONbQs.length
      KONbQs.forEach(questKey => konAnswerObj[questKey] = null)
      break
    case questions[0].answerArray[2]:  // 'Mitarbeiter:in (nicht Teil der Hauptleiterschaft)'
      newNumberOfQs -= (KONaQs.length + KONbQs.length);
      KONaQs.forEach(questKey => konAnswerObj[questKey] = null)
      KONbQs.forEach(questKey => konAnswerObj[questKey] = null)
      break;
    default:
      console.log("Unknown answer to KON0")
      break;
  }
  return { konAnswerObj, newNumberOfQs }
}
