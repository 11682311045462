import axios from 'axios'
import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Footer from '../Footer/Footer'
import RegisterNowCTA from '../common/RegisterNowCTA/RegisterNowCTA'
import { loginUser } from '../../features/user/userSlice'
import { MIN_PASS_LENGTH, REDIRECTION_TIMEOUT } from '../../utils/constants'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { getMissingFieldsMsg, getPwMismatchMsg, getPwTooShortMsg } from '../../utils/getMiscToastMsg'
import styles from './ResetPassword.module.scss'

const API_URL = process.env.REACT_APP_API_URL

/* This is the landing page from the email link of 'forgotten-password' or 'change-password' */
const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)

  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const code = new URLSearchParams(window.location.search).get('code')

  const onFormSubmit = (event) => {
    event.preventDefault()
    if (!password || !repeatPassword) {
      toast.error(getMissingFieldsMsg(jt.toast))
      return
    }
    if (password.length < MIN_PASS_LENGTH) {
      toast.error(getPwTooShortMsg(jt.toast))
      return
    }
    if (password !== repeatPassword) {
      toast.error(getPwMismatchMsg(jt.toast))
      return
    }
    if (!code) {
      toast.error(
        'Das Token vom Link in der E-Mail fehlt.\nBitte diesen Link verwenden und nochmals versuchen.',
        { autoClose: 8000 }
      )
      return
    }
    axios
      .post(`${API_URL}/api/auth/reset-password`, {
        code,
        password,
        passwordConfirmation: repeatPassword,
      })
      .then(res => {
        // console.log('res.data:', res.data)
        const user = res.data.user
        localStorage.setItem('bjwt', res.data.jwt)
        dispatch(
          loginUser({
            userID: user.id,
            email: user.email,
            firstName: user.firstName,
            strapiJwt: res.data.jwt,
            orgID: user.organizationID,
            orgName: user.organization,
            isOrgCreator: user.isOrgCreator,
            orgCategory: user.orgCategory,
            language: user.language || localStorage.getItem('language'),
            surveyStatus: user.surveyStatus,
          }),
        )
        toast.success(jt.toast?.passwordResetSuccess ||
          'Your new password has been set!\nYou will be redirected shortly...',
        )
        setTimeout(() => navigate('/welcome-back'), REDIRECTION_TIMEOUT)
      })
      .catch(error => handleStrapiAPIError(error))
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>{jt.header?.setNewPassword || 'Set a New Password'}</h1>
        <Form onSubmit={onFormSubmit}>
          <Row className='g-5'>
            <Col xs={6}>
              <Form.Control
                type='password'
                placeholder={jt.placeholder?.password || 'Password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                className={styles.formEntry}
              />
              <Form.Control
                type="password"
                placeholder={jt.placeholder?.repeatPassword || "Repeat password"}
                value={repeatPassword}
                onChange={e => setRepeatPassword(e.target.value)}
                className={styles.formEntry}
              />
            </Col>
            <Col xs={6} className="px-5">
              <p>
                {jt.block?.resetPasswordInstructions ||
                  'Welcome back!  Enter a new password to continue using the website.'
                }
              </p>
            </Col>
          </Row>
          <div className={styles.buttons}>
            <button type="submit" className={styles.headerBtn}>
              {jt.label?.setNewPassword || 'Set New Password'}
            </button>
          </div>
        </Form>
      </div>

      <RegisterNowCTA />
      <Footer />
    </div>
  )
}

export default ResetPassword
