import axios from 'axios'
import { useEffect, useState } from 'react'
import { Container} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateSurveyStatus } from '../../features/user/userSlice'
import ProgressBarContainer from '../common/ProgressBarContainer/ProgressBarContainer'
import Module3KON from './Module3KON'
import Module3OtherQs from './Module3OtherQs'
import { formatDate } from '../../utils/formatDate'
import {
  MAX_SURVEY_QUESTIONS_PER_PAGE,
} from '../../utils/constants'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { answerSavedMsg } from '../../utils/answerSavedMsg'
import { saveAnswersInDB } from '../../utils/saveAnswersInDB'
import { showMissingAnswerWarning, showCategoryNotSetWarning } from '../../utils/getMiscToastMsg'
import { adaptNewAnswerObjForKON } from '../../utils/giveAnswer'
import styles from './Module3.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// render survey Module 3 (Step 4); called from SurveyFull.js
const Module3 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)

  const [progress, setProgress] = useState(0) // in %
  const [progressKON, setProgressKON] = useState(0) // number of answered KON questions
  const [currentKonQlength, setCurrentKonQlength] = useState(0) // Reduced number depending on KON0
  const [progressOtherQs, setProgressOtherQs] = useState(0) // number of answered Other questions
  const [konQuestions, setKonQuestions] = useState(false)
  const [otherModule3Questions, setOtherModule3Questions] = useState(false)
  const [questionsReceived, setQuestionsReceived] = useState(false)
  const [sectionToRender, setSectionToRender] = useState('module3KON')

  const [konPrevAnswers, setKonPrevAnswers] = useState({})
  const [otherQsPrevAnswers, setOtherQsPrevAnswers] = useState({})
  const [konAnswers, setKonAnswers] = useState({})
  const [otherQsAnswers, setOtherQsAnswers] = useState({})

  useEffect(() => {
    if (!user.userID) navigate('/survey')
    if (!questionsReceived) {
      if (user.userID && !user.orgCategory) showCategoryNotSetWarning(jt.toast || {})
      // load Module 3 (Step 4) questions
      axios
        .get(`${API_URL}/api/survey-questions?filters[module][$eq]=3`)
        .then(res => {
          let module3Qs = res.data.data
          module3Qs = module3Qs.map(item => {
            return {
              questionID: item.attributes.questionID,
              question1: item.attributes.question1,
              question2: item.attributes.question2,
              answerArray: item.attributes.answerArray?.answerArray || null,
              format: item.attributes.format,
            }
          })
          const konQs = module3Qs.filter(item => {
            return item.questionID.substring(0, 3) === 'KON'
          })
          konQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setKonQuestions(konQs)
          setCurrentKonQlength(konQs.length)

          const otherQs = module3Qs.filter(item => {
            return item.questionID.substring(0, 3) !== 'KON'
          })
          otherQs.sort((a, b) => a.questionID.localeCompare(b.questionID))
          setOtherModule3Questions(otherQs)
          setQuestionsReceived(true)
        })
        .catch(error => handleStrapiAPIError(error))
    } else {
      // load previous answers if they exist
      axios
        .get(API_URL + '/api/users/me', authHeaders())
        .then(res => {
          const previousAnswers = res.data.module3 || {}
          // console.log('previousAnswers:', previousAnswers)
          if (Object.keys(previousAnswers).length) {
            let konAns = {}
            let otherQsAns = {}
            for (const questKey in previousAnswers) {
              if (questKey.startsWith('KON')) konAns[questKey] = previousAnswers[questKey]
              else if (!questKey.startsWith('progress')) otherQsAns[questKey] = previousAnswers[questKey]
            }
            setKonPrevAnswers(konAns)
            setOtherQsPrevAnswers(otherQsAns)
            // update the KON question length
            setCurrentKonQlength(adaptNewAnswerObjForKON({ ...konAns }, konQuestions).newNumberOfQs)
            // set each section's progress
            setProgressKON(previousAnswers.progressKON ?? 0)
            setProgressOtherQs(previousAnswers.progressOtherQs ?? 0)
          }
        })
        .catch(error => handleStrapiAPIError(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsReceived])

  const advance = async () => {
    // save progress in DB
    const newSurveyStatus = {
      ...user.surveyStatus,
      module3: `${progress}%`,
      lastUpdate: formatDate(new Date()),
    }
    dispatch(updateSurveyStatus({ surveyStatus: newSurveyStatus }))
    // if new answer objects have been filled, use them; else use previous answers
    const saveKonAnswers = Object.keys(konAnswers).length ? konAnswers : konPrevAnswers
    const saveOtherQsAnswers = Object.keys(otherQsAnswers).length ? otherQsAnswers : otherQsPrevAnswers
    const allModule3Answers = { ...saveKonAnswers, ...saveOtherQsAnswers }
    const module3Progress = { progressKON, progressOtherQs }
    await axios
      .put(
        `${API_URL}/api/users/${user.userID}`,
        {
          surveyStatus: newSurveyStatus,
          module3: { ...allModule3Answers, ...module3Progress },
        },
        authHeaders(),
      )
      .then(() => answerSavedMsg(sectionToRender !== 'module3OtherQs'))
      .catch(error => handleStrapiAPIError(error))
    if (progress === 100) {
      /* note: changing all "Ja" answers to 1 and all "Nein" answers to 0
         is not needed in Module 3 */
      saveAnswersInDB(user, '3', allModule3Answers)
    }

    // if (sectionToRender === 'module3KON' && progressKON === currentKonQlength) {
    //   setSectionToRender('module3OtherQs')
    // }
    if (sectionToRender === 'module3KON') {
      if (progressKON < currentKonQlength) showMissingAnswerWarning(jt.toast)
      setSectionToRender('module3OtherQs')
    }
    if (sectionToRender === 'module3OtherQs') {
      if (progress < 100) showMissingAnswerWarning(jt.toast)
      navigate('/survey')
    }
  }

  const updateProgress = (
    numberOfAnsweredQs,
    questionSection,
    newNumberOfQsInSection
  ) => {
    let totalNumberOfQs
    switch (questionSection) {
      case 'KON':
        if (!newNumberOfQsInSection)
          newNumberOfQsInSection = konQuestions.length
        setCurrentKonQlength(newNumberOfQsInSection)
        totalNumberOfQs = newNumberOfQsInSection + otherModule3Questions.length
        setProgressKON(numberOfAnsweredQs)
        setProgress(
          Math.round(
            ((numberOfAnsweredQs + progressOtherQs) / totalNumberOfQs) * 100
          )
        )
        break
      case 'Other':
        setProgressOtherQs(numberOfAnsweredQs)
        totalNumberOfQs = currentKonQlength + otherModule3Questions.length
        const newProgress = Math.round(
          ((progressKON + numberOfAnsweredQs) / totalNumberOfQs) * 100,
        )
        setProgress(newProgress)
        break
      default:
        console.log(`This questionSection:${questionSection} is unknown!`)
    }
  }

  const getSaveAnsBtnText = () => {
    return jt.label?.saveAnswers || 'Save current answers'
    /*
    let text
    // if current section is finished, show 'save and advance to next page'
    if (sectionToRender === 'module3KON' && progressKON === currentKonQlength)
      text = jt.label?.saveAndAdvance || 'Save and advance'
    // if all questions are answered, show 'save and finish'
    else if (progress === 100) text = jt.label?.saveAndFinish || 'Save and finish'
    // Otherwise, simply show 'save current answers'
    else text = jt.label?.saveAnswers || 'Save current answers'
    return text
    */
  }

  return (
    <div className={styles.surveyContainer}>
      <h6 className={styles.moduleHeader}>
        {jt.header?.module3 || 'Module 3'}
      </h6>
      <h1 className='mb-5'>{jt.header?.module3Title.replace('-', '') || 'Context and Development'}</h1>
      <br />
      <Container>
        {questionsReceived && sectionToRender === 'module3KON' && (
          <Module3KON
            questions={konQuestions}
            previousAnswers={konPrevAnswers}
            updateProgress={updateProgress}
            updateAnswers={setKonAnswers}
          />
        )}
        {questionsReceived && sectionToRender === 'module3OtherQs' && (
          <Module3OtherQs
            questions={otherModule3Questions}
            previousAnswers={otherQsPrevAnswers}
            maxQuestionsPerPage={MAX_SURVEY_QUESTIONS_PER_PAGE}
            updateProgress={updateProgress}
            updateAnswers={setOtherQsAnswers}
          />
        )}
      </Container>

      <ProgressBarContainer
        btnLabel={getSaveAnsBtnText()}
        progress={progress}
        handleButtonClick={advance}
      />
    </div>
  )
}

export default Module3
