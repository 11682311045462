import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { CONSTANTS, QUESTION_FORMAT_KEY } from '../../../utils/constants'
import styles from './QuestionFormRadio.module.scss'

// choose one of the following formats and comment out the other:
const FORMAT_KEY = QUESTION_FORMAT_KEY;

/* reusable component for Yes/No or similar-looking radio type questions
  called by Module1GEM.js, Module1KOP.js, Module1AGR.js, Module2MLQ.js
*/
const QuestionFormRadio = ({
  question,
  questionIndex,
  questionFormat,
  answers,
  currentAnswer,
  giveAnswer
}) => {

  const { colors } = FORMAT_KEY[questionFormat];
  // console.log('answers:', answers)

  let toggleBtnsStyle;
  switch (answers.length) {
    case 2:
      toggleBtnsStyle = styles.toggleButtons2
      break;
    case 3:
    case 4:
      toggleBtnsStyle = styles.toggleButtons4
      break;
    case 5:
    case 6:
      toggleBtnsStyle = styles.toggleButtons6
      break;
    case 9:
    case 10:
      toggleBtnsStyle = styles.toggleButtons10
      break;
    default:
      toggleBtnsStyle = styles.toggleButtons10
      break;
  }

  const answerChange = (val) => {
    giveAnswer(val, questionIndex);
  }

  return (
    <div>
      <div className={styles.question}>{question}</div>
      <ToggleButtonGroup
        type="radio"
        value={currentAnswer}
        name={`answer-radios-Q${questionIndex}`}  // name must be shared by all radio buttons in group
        // size="sm"                              // and be distinct from other buttongroups
        onChange={answerChange}
        className={toggleBtnsStyle}
      >
        {answers.map((answerValue, i) => {
          let backgroundColor = CONSTANTS.colors.white
          if (answerValue === currentAnswer) {
            backgroundColor = colors;
          }
          return (
              <ToggleButton
                key={answerValue}
                id={`tbg-btn-Q${questionIndex}-${i + 1}`} // unique id is required in a ToggleButtonGroup
                value={answerValue}                       // this id must also be unique in the HTML document
                className={styles.radios}
                // the only way to overwrite bootstrap styling
                style={{
                    borderColor: colors,
                    borderRadius: '50%',
                    margin: '10px',
                    padding: '2px',
                    backgroundColor: backgroundColor
                }}
                // disabled={offSwitchChecked}
              >
                <div className={styles.radioAnswer}>{answerValue}</div>
              </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  )
}

export default QuestionFormRadio;
