// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SurveyDemo_subtitle__rhmsU {\n  white-space: pre-wrap;\n}", "",{"version":3,"sources":["webpack://./src/components/SurveyDemo/SurveyDemo.module.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;AADF","sourcesContent":["@import \"../../styles/colors\";\n\n.subtitle {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "SurveyDemo_subtitle__rhmsU"
};
export default ___CSS_LOADER_EXPORT___;
