import axios from 'axios'
import { authHeaders } from './authHeaders'
import { handleStrapiAPIError } from './handleStrapiAPIError'
import { getDateOfLastValidSurvey } from './getSurveyDate'

const API_URL = process.env.REACT_APP_API_URL

/**
 * only for organization creators
 * called by SurveyFull.js
 * finds how many surveys that users belonging to the creator's organization have filled out
 * in the present survey cycle (for example, in the last 11 months) for each module
 * @param orgID, number, organization ID
 * @return object { 'module1': <num>, 'module2': <num>...'module5': <num> }
 */
export const getNumSurveysFilledOut = async (orgID) => {
  const surveysFilledOut = {
    module1: 0,
    module2: 0,
    module3: 0,
    module4: 0,
    module5: 0,
  }
  await axios
    .get(
      `${API_URL}/api/answer-sessions` +
      `?filters[organizationID][$eq]=${orgID}` +
      `&filters[updatedAt][$gte]=${getDateOfLastValidSurvey()}`,
      authHeaders(),
    )
    .then(res => {
      const sessions = res.data.data
      // console.log('sessions:', sessions);
      sessions.forEach(session => {
        const mod = parseInt(session.attributes.module, 10)
        // avoid errors if the module is 'demo' or other unexpected value
        if (mod >= 1 && mod <= 5) surveysFilledOut[`module${mod}`]++
      })
    })
    .catch(error => handleStrapiAPIError(error))

  return surveysFilledOut
}
