/**
 * called by ResultsModule1-5.js
 * @param jtResults, object, applicable portion of the jsonText object from Redux
 * @param results, object, { <three-letter-abbreviation>: { mean: <mean>, percentile: <percentile> }, ... }
 * @param constructs, array of construct objects { name: <name>, short: <three-letter-abbreviation> }
 * @return object, { <three-letter-abbreviation>: '<text>', ... }
 */
export const getFeedbackTexts = (jtResults, results, constructs) => {
  const texts = {}
  const notFound = 'Construct feedback text not found...'
  for (const construct of constructs) {
    const name = construct.name
    const short = construct.short
    const percentile = results[short].percentile
    switch (true) {
      case (percentile >= 0 && percentile <= 25):
        texts[short] = jtResults[name]?.['veryLow'] ?? notFound
        break
      case (percentile > 25 && percentile <= 50):
        texts[short] = jtResults[name]?.['low'] ?? notFound
        break
      case (percentile > 50 && percentile <= 75):
        texts[short] = jtResults[name]?.['high'] ?? notFound
        break
      case (percentile > 75 && percentile <= 100):
        texts[short] = jtResults[name]?.['veryHigh'] ?? notFound
        break
      default:
        console.error(`Percentile of ${percentile} is invalid for construct ${short}`)
    }
  }
  return texts
}
