import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Image } from 'react-bootstrap'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon.svg'
import model from '../../../assets/images/EO_Gesamtmodell_Grafik.png'
import CreatePDF from '../ReactPDF/CreatePDF'
import styles from './ResultsHeader.module.scss'

/**
 * renders the picture and everything on it at the top of the results page
 * @param module, string, such as 'module1'
 * @param chartsLoaded, boolean, have all react-charts finished rendering? - for pdf-download link
 * @param chartImages, object, contains the image data after painting the charts to the canvas - for pdf-download
 * @param benchModAll, object, benchmark of all NPOs - for pdf-download
 * @param benchModCat, object, benchmark of NPOs of the current NPO category - for pdf-download
 * @param modAllResults, object, comparison of NPO survey to benchmark of all NPOs - for pdf-download
 * @param modCatResults, object, comparison of NPO survey to benchmark of NPOs of the current NPO category - for pdf-download
 * @param constructs, [object], constructs of current module - for pdf-download
 * @param feedbackTexts, object, constructs.short as keys, texts as values
 * @param pdfFileName, string
 * @return {JSX.Element}
 * @constructor
 */
const ResultsHeader = ({
                         module, chartsLoaded, chartImages, benchModAll,
                         benchModCat, modAllResults, modCatResults, constructs,
                         feedbackTexts, pdfFileName,
                       }) => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const orgName = user.orgName
  const [viewImage, setViewImage] = useState(false)
  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>{jt.header?.yourSurveyResults || 'Your Survey Results'}</h1>
        <p>{jt.block?.seeYourResults || 'See your results...'}</p>
        {chartsLoaded ?
          <PDFDownloadLink
            document={<CreatePDF
              jt={jt}
              module={module}
              images={chartImages}
              benchModAll={benchModAll}
              benchModCat={benchModCat}
              modAllResults={modAllResults}
              modCatResults={modCatResults}
              constructs={constructs}
              feedbackTexts={feedbackTexts}
              pdfFileName={pdfFileName}
              orgName={orgName}
            />}
            fileName={pdfFileName}
          >
            <button className={styles.mainBtn}>
              <DownloadIcon className={styles.downloadIcon} alt='download icon' />
              {jt.label?.downloadPage || 'Download page'}
            </button>
          </PDFDownloadLink>
          :
          <span>
            {modAllResults &&
              <button className={styles.mainBtn}>
                {jt.label?.preparingPdfFile || 'Preparing PDF-file...'}
              </button>
            }
          </span>
        }
        <button className={styles.secondBtn} onClick={() => setViewImage(!viewImage)}>
          {viewImage ? jt.label?.hideModelAgain || 'Hide the model again'
            : jt.label?.viewFullModel || 'View the full model'
          }
        </button>
      </div>
      {viewImage && <Image src={model} className={styles.image} alt='Modell overview' />}
    </div>
  )
}
export default ResultsHeader
