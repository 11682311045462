import { Button, Container, Row } from 'react-bootstrap'
import { BsChevronRight } from 'react-icons/bs'
import ProgressBar from './ProgressBar'
import styles from './ProgressBarContainer.module.scss'

const ProgressBarContainer = ({ handleButtonClick, btnLabel, progress }) => {

  return (
    <div className={styles.progressContainer}>
      <Container>
        <Row>
          <div className={styles.progressInner}>
            <ProgressBar progress={progress} />
            <Button
              onClick={handleButtonClick}
              type="submit"
              variant="success"
              className={styles.saveAnswersButton}
              size="sm"
            >
              {btnLabel}
              <BsChevronRight size={18} />
            </Button>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default ProgressBarContainer
