import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import Footer from '../Footer/Footer'
import styles from './EditOrg.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// allow a user to view their organization and edit it if they are the creator
const EditOrg = () => {
  const user = useSelector(state => state.user.user)
  const jt = useSelector(state => state.jsonText.jsonText)
  const [org, setOrg] = useState({
    id: null,
    name: '',
    creatorID: null,
    streetAndNumber: '',
    postCode: '',
    city: '',
    country: '',
  })
  const [formDisabled, setFormDisabled] = useState(true)

  useEffect(() => {
    if (user.userID)
      axios
        .get(
          `${API_URL}/api/organizations/${user.orgID}`,
          // `${API_URL}/api/organizations?filters[userID][$eq]=${user.userID}`,
          authHeaders(),
        )
        .then(res => {
          // const data = res.data.data[0]
          const data = res.data.data
          // console.log('data:', data)
          setOrg({
            id: data.id,
            name: data.attributes.name,
            creatorID: data.attributes.userID,
            streetAndNumber: data.attributes.streetAndNumber || '',
            postCode: data.attributes.postCode || '',
            city: data.attributes.city || '',
            country: data.attributes.country || '',
          })
          if (data.attributes.userID !== user.userID) {
            toast.warning(
              'Nur der Autor der Organisation kann diese Daten ändern.',
            )
          } else setFormDisabled(false)
        })
        .catch(error => handleStrapiAPIError(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userID])

  const onFormSubmit = (event) => {
    event.preventDefault()
    if (!org.name) {
      toast.error('Der NPO-Name ist ein Pflichtfeld.')
      return
    }
    axios
      .put(
        `${API_URL}/api/organizations/${org.id}`,
        {
          data: {
            name: org.name,
            streetAndNumber: org.streetAndNumber,
            city: org.city,
            postCode: org.postCode,
            country: org.country,
          },
        },
        authHeaders(),
      )
      .then(() => toast.success(
        jt.toast?.npoProfileUpdated || 'NPO Profile successfully updated',
      ))
      .catch(error => {
        handleStrapiAPIError(error)
        if (error.response.data.error.name === 'ValidationError') {
          toast.error(
            jt.toast?.orgNameAlreadyExists || 'An organization with this name already exists.',
            { autoClose: 8000 },
          )
        }
      })
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>{user.orgName}</h1>
      </div>
      <Container className={styles.mainContent}>
        <div className={styles.title}>
          {jt.header?.changeOrganizationDetails || 'Change the Details of Your Organization'}
        </div>
        <Form onSubmit={onFormSubmit} className="text-start">
          <Row className="g-5">
            <Col xs={6}>
              <Form.Group>
                <Form.Label className={styles.label}>
                  {jt.label?.legalOrganizationName || "Your organization's full legal name"}*
                </Form.Label>
                {/* This div is to prevent Bootstrap's .form-control class from overriding our .formEntry class. */}
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.yourOrganizationsName || "Your organization's name"}
                    value={org.name}
                    onChange={(e) => setOrg({ ...org, name: e.target.value })}
                    disabled={formDisabled}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className={styles.label}>
                  {jt.placeholder?.city || "City"}
                </Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.city || "City"}
                    value={org.city}
                    onChange={e => setOrg({ ...org, city: e.target.value })}
                    disabled={formDisabled}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className={styles.label}>
                  {jt.placeholder?.country || "Country"}
                </Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.country || "Country"}
                    value={org.country}
                    onChange={(e) =>
                      setOrg({ ...org, country: e.target.value })
                    }
                    disabled={formDisabled}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <button type="submit" className={styles.orangeBtn}>
                {jt.label?.saveChanges || "Save Changes"}
              </button>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className={styles.label}>
                  {jt.placeholder?.streetAndNumber || "Street and Number"}
                </Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.streetAndNumber || "Street and Number"}
                    value={org.streetAndNumber}
                    onChange={(e) =>
                      setOrg({ ...org, streetAndNumber: e.target.value })
                    }
                    disabled={formDisabled}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className={styles.label}>
                  {jt.placeholder?.postCode || "Post Code"}
                </Form.Label>
                <div className={styles.formEntryContainer}>
                  <Form.Control
                    type="text"
                    placeholder={jt.placeholder?.postCode || "Post Code"}
                    value={org.postCode}
                    onChange={(e) =>
                      setOrg({ ...org, postCode: e.target.value })
                    }
                    disabled={formDisabled}
                    className={styles.formEntry}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Container>

      <Footer />
    </div>
  )
}

export default EditOrg
