// format date to "DD.MM.YYYY" unless "YYYY.MM.DD" is passed as format
export const formatDate = (value, format) => {
  let date = new Date(value)
  // Extract year, month, and day from the date object
  const year = date.getFullYear()
  let month = date.getMonth() + 1 // Months are zero-based, so add 1
  let day = date.getDate()

  // Pad month and day with leading zeros if necessary
  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day

  // Construct the formatted date string
  return format === 'YYYY.MM.DD' ? `${year}.${month}.${day}` : `${day}.${month}.${year}`

  // old: format date to "DD-MMM-YYYY"
  // const day = date.toLocaleString('default', { day: '2-digit' })
  // const month = date.toLocaleString('default', { month: 'short' })
  // const year = date.toLocaleString('default', { year: 'numeric' })
  // return day + '-' + month + '-' + year
}

