import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './BookConsultationCTA.module.scss'

/**
 * Creates the 'Book a Consultation!' call to action component at the bottom of the page
 * Called by Results, ResultsDemo and ResultsModule1-5.js
 */
const BookConsultationCTA = ({ demo }) => {
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)

  return (
    <div className={demo ? styles.rightColContainer : styles.bottomContainer}>
      <h2 className={styles.bottomTitle}>{jt.header?.bookAConsultation || 'Book a consultation!'}</h2>
      <p className={styles.bottomText}>{jt.block?.weCanHelpYou || 'We can help you.'}</p>
      <button
        className={styles.bottomBtn}
        onClick={() => navigate('/contact')}
      >
        {jt.label?.bookAConsultation || 'Book a consultation'}
      </button>
    </div>
  )
}

export default BookConsultationCTA
