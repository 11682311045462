import React from "react";
import { Form } from "react-bootstrap";
import styles from './IconInputField.module.scss';

// InputField
// Icons optional
const IconInputField = (props) => {
  const Icon = props.icon
  return (
    <div>
      <div className={styles.iconInputUnit}>
        {Icon &&
          <div className={props.small ? styles.smallIconBackground : styles.iconBackground}>
            <Icon className={props.small ? styles.smallIcon : styles.icon} alt="icon" />
          </div>
        }
        <Form.Control
          className={props.small ? styles.smallEntryForm : styles.entryForm}
          value={props.value}
          onChange={props.onChange}
          type={props.type}
          placeholder={props.placeholder}
          isInvalid={props.isInvalid}
        />
      </div>
      {props.inputMessage &&
        <div className={props.small ? styles.smallInputMessage : styles.inputMessage}
        >
          {props.inputMessage}
        </div>}
    </div>
  )
}

export default IconInputField;
