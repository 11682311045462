/**
 * Get a Results by Module Array for rendering the table of Module titles and navigation links
 * @param jsonText object, from redux
 * @returns an array of objects like this:
 * [
 *   {
 *     title: 'Gesamtergebnis über alle Module',
 *     resultsLink: '/results',
 *   },
 *   ...
 * ]
 */
export const getResByModArr = (jtHeader = {}) => {
  const resultsArr = [
    // {  // Obsolete since 26.6.24 as per Jonas' email of 20.6.24 Point E
    //   module: '',
    //   title: jtHeader.overallResults || 'Overall Results',
    //   resultsLink: '/results',
    // },
    {}, // this represents Module 0, used only for consistency and readability
  ]
  for (let i = 1; i <= 5; i++) {
    resultsArr.push({
      module: jtHeader[`module${i}`] || `Module ${i}`,
      title: jtHeader[`module${i}Title`] || 'Loading Module Title...',
      resultsLink: i === 1 ? '/results' : `/results/module${i}`,
    })
  }
  return resultsArr
}
