import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './BasicFormContainer.module.scss';

const BasicFormContainer = (props) => {
  return (
    <Container className={styles.myContainer}>
      <h1>{props.containerTitle}</h1>
      {props.children}
    </Container>
  )

}

export default BasicFormContainer;
