import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { pdfStyles } from './pdfStyles'
import { CONSTANTS } from '../../../utils/constants'

const styles = StyleSheet.create({
  paragraph: pdfStyles.paragraph,
  section: pdfStyles.section,
  orgName: {
    marginTop: -20,
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 12,
    color: CONSTANTS.colors.benchLogoOrange,
  },
  titleSmall: {
    marginTop: 5,
    marginBottom: 5,
    textAlign: 'center',
    fontSize: 12,
  },
  titleLarge: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 10,
  },
})

const PdfTop = ({ jt, module, orgName }) => (
  <View style={styles.section}>
    <Text style={styles.orgName}>
      {orgName || 'Unknown Organization'}
    </Text>
    <Text style={styles.titleSmall}>
      {jt.label?.[`${module}ResultsFileName`].replace(/_/g, ' ') || 'Module 1 Results'}:
    </Text>
    <Text style={styles.titleLarge}>
      {jt.header?.[`${module}Title`].replace('-', '') || 'Entrepreneurial Orientation'}
    </Text>
    <Text style={styles.paragraph}>
      {jt.block?.[`${module}Explanation`] || 'Explanation not loaded...please reload page and try again.'}
    </Text>
  </View>
)


export default PdfTop
