import axios from 'axios'
import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addOrgToUser } from '../../features/user/userSlice'
import Footer from '../Footer/Footer'
import RegisterNowCTA from '../common/RegisterNowCTA/RegisterNowCTA'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { REDIRECTION_TIMEOUT } from '../../utils/constants'
import { getOrgConnectSuccessMsg } from '../../utils/getMiscToastMsg'
import styles from './Organization.module.scss'

const API_URL = process.env.REACT_APP_API_URL

/* Allow a user to create a new organization.
   Called by OrgSelect.js */
const OrgCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  // console.log('user:', user)
  const [newOrgFullName, setNewOrgFullName] = useState('')
  const [newOrgShortName, setNewOrgShortName] = useState('')

  const onFormSubmit = async (event) => {
    event.preventDefault()
    let newOrganizationID = null
    let newOrgSaveSuccess = false
    const newOrgName = newOrgFullName + ` (${newOrgShortName})`
    await axios
      .post(
        `${API_URL}/api/organizations`,
        {
          data: { name: newOrgName, userID: user.userID },
        },
        authHeaders(),
      )
      .then(res => {
        newOrganizationID = res.data.data.id
        newOrgSaveSuccess = true
      })
      .catch(error => {
        handleStrapiAPIError(error)
        if (error.response.data.error.name === 'ValidationError') {
          toast.error(
            `Bitte Prüfen ob diese Organisation bereits existiert und nochmals versuchen.`,
            {
              autoClose: 8000,
            },
          )
        }
      })
    if (newOrgSaveSuccess) {
      axios
        .put(
          `${API_URL}/api/users/${user.userID}`,
          {
            organizationID: newOrganizationID,
            organization: newOrgName,
            isOrgCreator: true,
          },
          authHeaders(),
        )
        .then(() => {
          toast.success(getOrgConnectSuccessMsg(
            newOrgName, user.firstName, jt.toast, 'create'),
          )
          setTimeout(() => navigate('/welcome-back'), REDIRECTION_TIMEOUT)
        })
        .catch(error => handleStrapiAPIError(error))

      // also save in redux user object
      dispatch(
        addOrgToUser({
          orgID: newOrganizationID,
          orgName: newOrgName,
          isOrgCreator: true,
        }),
      )
    }
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>
          {jt.header?.createNewOrg || "Create a new organization"}
        </h1>
        <p>
          {jt.block?.createOrgInstructions || "If your organization is already registered, please go back to the 'Select Organization' page."}
        </p>

        <Form onSubmit={onFormSubmit}>
          <Row
            className={styles.orgCreateNameEntry}
            // className={'g-3'}
          >
            <Col xs={8}>
              <Form.Control
                type='text'
                placeholder={jt.placeholder?.yourOrgFullName || 'Your organization\'s full name'}
                value={newOrgFullName}
                onChange={e => setNewOrgFullName(e.target.value)}
                className={styles.formEntry}
              />
            </Col>
            <Col xs={4}>
              <Form.Control
                type='text'
                placeholder={jt.placeholder?.yourOrgShortName || 'Abbreviation or Acronym'}
                value={newOrgShortName}
                onChange={e => setNewOrgShortName(e.target.value)}
                className={styles.formEntry}
              />
            </Col>
          </Row>
          <button type='submit' className={styles.headerBtn}>
            {jt.label?.createOrganization || 'Create organization'}
          </button>
          <div
            onClick={() => navigate('/organization-anon')}
            className={styles.link}
          >
            {jt.label?.generateAnonymousOrg || 'Generate an Anonymous Organization'}
          </div>
        </Form>
      </div>

      <RegisterNowCTA />
      <Footer />
    </div>
  )
}

export default OrgCreate
