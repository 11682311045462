// used by QuestionForm.js and SideTggleBtnForm.js
import { CONSTANTS } from './constants'

export const getToggleBtnStyle = (toggleBtnType, idx, borderColor, backgroundColor) => {
  let height
  let margin = '10px'
  if (toggleBtnType === 'center') {
    switch (idx) {
      case 0:
      case 5:
        height = '26px' // outer circles are largest
        break
      case 1:
      case 4:
        height = '24px'
        break
      default:
        height = '22px' // inner circles are smallest
    }
  } else { // i.e., toggleBtnType === "side"
    height = `${22 + idx}px` // larger towards the right
    // increase margin-top for the smaller circles to maintain vertical center
    margin = `${13 - idx / 2}px 10px 10px 10px`
  }
  if (backgroundColor === CONSTANTS.colors.gray) borderColor = CONSTANTS.colors.gray

  return {
    borderColor,
    backgroundColor,
    borderRadius: '50%',
    margin,
    padding: '2px',
    height,
    width: height,
  }
}
