import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addJsonText } from '../../features/jsonText/jsonTextSlice'
import { loginUser, logoutUser } from '../../features/user/userSlice'
import { authHeaders } from '../../utils/authHeaders'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { getCurrentLanguage } from '../../utils/getCurrentLanguage'
import { updateUserOrgCat } from '../../utils/updateUserOrgCat'
import { CONSTANTS } from '../../utils/constants'
import styles from './HeaderProfile.module.scss'

const API_URL = process.env.REACT_APP_API_URL

const HeaderProfile = () => {
  const dispatch = useDispatch()
  const jt = useSelector(state => state.jsonText.jsonText)
  const reduxUser = useSelector(state => state.user.user) //state.<slice>.<name>
  // console.log('jt:', jt)
  const [loggedIn, setLoggedIn] = useState(false)
  const [firstName, setFirstName] = useState('')
  const updateHasBeenCalled = useRef(false)


  useEffect(() => {
    const strapiJwt = localStorage.getItem('bjwt')
    if (!strapiJwt) {
      setLoggedIn(false)
    } else {
      axios
        .get(API_URL + '/api/users/me', authHeaders())
        .then(res => {
          const user = res.data
          setFirstName(user.firstName)
          setLoggedIn(true)
          dispatch(
            loginUser({
              userID: user.id,
              email: user.email,
              firstName: user.firstName,
              strapiJwt: strapiJwt,
              orgID: user.organizationID,
              orgName: user.organization,
              isOrgCreator: user.isOrgCreator,
              orgCategory: user.orgCategory,
              language: user.language || localStorage.getItem('language'),
              surveyStatus: user.surveyStatus,
            })
          )
        })
        .catch(error => {
          handleStrapiAPIError(error)
          setLoggedIn(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // handle log in / log out
    if (reduxUser.firstName) {
      setFirstName(reduxUser.firstName)
      setLoggedIn(true)
    } else {
      setFirstName('')
      setLoggedIn(false)
    }

    // if JsonText has not yet been uploaded or there has been a language change,
    // get the appropriate JsonText
    const lang = getCurrentLanguage(reduxUser, dispatch)
    if (jt.lang !== lang)
      axios
        .get(API_URL + `/api/website-texts?filters[languageCode][$eq]=${lang}`)
        .then(res => {
          // console.log('res:', res)
          const jsonText = res.data.data[0]?.attributes?.jsonText
          // console.log('website-texts:', jsonText)
          jsonText && dispatch(addJsonText(jsonText))
        })
        .catch(error => handleStrapiAPIError(error))

    // if both jsonText and reduxUser have been loaded, check user's orgCategory
    if (jt.toast && reduxUser.orgID && !updateHasBeenCalled.current) {
      updateUserOrgCat(
        {
          userID: reduxUser.userID,
          orgID: reduxUser.orgID,
          orgCategory: reduxUser.orgCategory,
        },
        jt.toast,
      )
      updateHasBeenCalled.current = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jt, reduxUser])

  const logout = () => {
    localStorage.removeItem('bjwt')
    dispatch(logoutUser())
    setLoggedIn(false)
  }

  if (loggedIn) {
    return (
      <Nav>
        <NavDropdown menuVariant='dark' align='end' title={firstName} id='nav-dropdown' className={styles.linkItem}>
          <LinkContainer to={'/results'}>
            <NavDropdown.Item className={styles.linkItem} eventKey='4.2'>
              {jt.label?.myResults || 'My Results'}
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={'/profile'}>
            <NavDropdown.Item className={styles.linkItem} eventKey='4.3'>
              {jt.label?.myProfile || 'My Profile'}
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={'/organization-edit'}>
            <NavDropdown.Item className={styles.linkItem} eventKey="4.4">
              {jt.label?.myOrganization || "My Organization"}
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={'/change-password'}>
            <NavDropdown.Item className={styles.linkItem} eventKey="4.5">
              {jt.label?.changePassword || "Change password"}
            </NavDropdown.Item>
          </LinkContainer>
          <NavDropdown.Divider />
          <NavDropdown.Item className={styles.linkItem} eventKey="4.1" href={`/`} onClick={logout}>
            {jt.label?.logout || "Log out"}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    )
  } else {
    return (
      <Nav>
        <LinkContainer to={'/login'}>
          <Nav.Link>{jt.label?.login || 'Log in'}</Nav.Link>
        </LinkContainer>
        <span style={{ color: CONSTANTS.colors.white, lineHeight: '40px' }}>/</span>
        <LinkContainer to={'/register'}>
          <Nav.Link>{jt.label?.register || 'Register'}</Nav.Link>
        </LinkContainer>
      </Nav>
    )
  }
}

export default HeaderProfile
