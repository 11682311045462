import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      const {
        userID,
        email,
        firstName,
        strapiJwt,
        orgID,
        orgName,
        isOrgCreator,
        orgCategory,
        language,
        surveyStatus,
      } = action.payload
      state.user = {
        userID,
        email,
        firstName,
        strapiJwt,
        orgID,
        orgName,
        isOrgCreator,
        orgCategory,
        language,
        surveyStatus,
      }
    },
    logoutUser: (state) => {
      state.user = {}
    },
    addOrgToUser: (state, action) => {
      const { orgID, orgName, isOrgCreator, orgCategory } = action.payload
      state.user = { ...state.user, orgID, orgName, isOrgCreator, orgCategory }
      // console.log('updated Redux user:', state.user)
    },
    updateLanguage: (state, action) => {
      const { language } = action.payload
      state.user = { ...state.user, language }
    },
    updateSurveyStatus: (state, action) => {
      const { surveyStatus } = action.payload
      state.user = { ...state.user, surveyStatus }
    },
    updateUserOrgCategory: (state, action) => {
      const { orgCategory } = action.payload
      state.user = { ...state.user, orgCategory }
    },
  },
})

export const { loginUser, logoutUser, addOrgToUser, updateLanguage, updateSurveyStatus, updateUserOrgCategory } =
  userSlice.actions
export default userSlice.reducer
