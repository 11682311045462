import axios from 'axios'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Footer from '../Footer/Footer'
import RegisterNowCTA from '../common/RegisterNowCTA/RegisterNowCTA'
import { checkEmailFormat } from '../../utils/checkEmailFormat'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { getInvalidEmailMsg, getLinkSentMsg, getMissingEmailMsg } from '../../utils/getMiscToastMsg'
import styles from './ForgotPassword.module.scss'

const API_URL = process.env.REACT_APP_API_URL

const ForgotPassword = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const [email, setEmail] = useState('')

  const onFormSubmit = (event) => {
    event.preventDefault()
    if (email === '') {
      toast.error(getMissingEmailMsg(jt.toast))
      return
    }
    if (!checkEmailFormat(email)) {
      toast.error(getInvalidEmailMsg(jt.toast))
      return
    }
    axios
      .post(`${API_URL}/api/auth/forgot-password`, { email })
      .then(() => toast.success(getLinkSentMsg(jt.toast)))
      .catch((error) => handleStrapiAPIError(error))
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>{jt.header?.resetPassword || "Reset Password"}</h1>

        <Form onSubmit={onFormSubmit}>
          <Row className="g-5">
            <Col xs={6}>
              <Form.Control
                type="text"
                placeholder={jt.placeholder?.yourEmail || "Your email"}
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                className={styles.formEntry}
              />
            </Col>
            <Col xs={6} className="px-5">
              <p>
                {jt.block?.enterEmailAndWeWillSendLink || "Enter your email and we will send you a link to reset your password."}
              </p>
            </Col>
          </Row>
          <div className={styles.buttons}>
            <button type="submit" className={styles.headerBtn}>
              {jt.header?.resetPassword || "Reset Password"}
            </button>
          </div>
        </Form>
      </div>

      <RegisterNowCTA />
      <Footer />
    </div>
  )
}

export default ForgotPassword
