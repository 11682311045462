import React from 'react'
import { View, StyleSheet, Image } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  chartImage: {
    // width: 320,
    width: 450,
    border: '1px solid #aaaaaa',
    borderRadius: 5,
    padding: '15px 2px 2px',
  },
  section: {
    marginTop: 20,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

const PdfCharts = ({ images }) => (
  Object.keys(images).map(image => {

    // old: move down chart on Modules 4 and 5 so that it looks nicer
    // if (module === 'module4' || module === 'module5') styles.section.marginTop = 35
    // else styles.section.marginTop = 5 // ensure reset to 5 for other Modules

    return (
      <View style={styles.section} key={image}>
        <Image src={images[image].imgData} style={styles.chartImage} />
      </View>
    )
  })
)


export default PdfCharts
