import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { pdfStyles } from './pdfStyles'
import { CONSTANTS } from '../../../utils/constants'

const styles = StyleSheet.create({
  fileName: {
    fontSize: 8,
    color: CONSTANTS.colors.grayText,
  },
  footerContainer: {
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    paddingHorizontal: 30,
  },
  line: pdfStyles.line,
  pageNumber: {
    fontSize: 12,
    color: CONSTANTS.colors.grayText,
  },
})

const PdfFooter = ({ pdfFileName }) => (
  <View fixed style={styles.footer}>
    <View style={styles.line} />
    <View style={styles.footerContainer}>
      <Text style={styles.fileName}>{pdfFileName}</Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `${pageNumber} / ${totalPages}`
        }
      />
    </View>
  </View>
)


export default PdfFooter
