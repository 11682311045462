// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderProfile_linkItem__hrRkC {\n  font-family: \"Barlow\", Helvetica, sans-serif;\n}", "",{"version":3,"sources":["webpack://./src/components/HeaderProfile/HeaderProfile.module.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACC,4CCiBY;ADlBb","sourcesContent":["@import '../../styles/variables';\n\n.linkItem {\n\tfont-family: $ff-primary;\n}\n","// FONT SIZES\n$fs-h1: 3.3rem;\n$fs-h2-large: 2.5rem;\n$fs-h2: 1.8rem;\n$fs-h3: 1.4rem;\n$fs-h4: 1.25rem;\n$fs-h5: 1.1rem;\n$fs-sectionTitle: 40px;\n$fs-body: 22px;\n$fs-label: 20px;\n$fs-small: 16px;\n$fs-very-small: 14px;\n\n// Font weights\n$fw-light: 300;\n$fw-normal: 400;\n$fw-medium: 500;\n$fw-semi-bold: 600;\n\n// Font Families\n$ff-primary: 'Barlow', Helvetica, sans-serif;\n$ff-secondary: 'Barlow', Helvetica, sans-serif;\n//$ff-secondary: 'Source Sans Pro', Helvetica, sans-serif;\n\n$max-width: 1420px;\n\n$answer-radio-grid-height: 60px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkItem": "HeaderProfile_linkItem__hrRkC"
};
export default ___CSS_LOADER_EXPORT___;
