import { getDataForChart } from '../../../utils/reactChartConfig'
import { CONSTRUCTS } from '../../../utils/constants'
import { onlyPreserveValidData } from './onlyPreserveValidData'

/**
 * called by ResultsModule3.js
 * @param benchMod3All, object, benchmark of all organization categories
 * @param benchMod3Cat, object, benchmark of user's organization category
 * @param mod3AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod3CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param type, string, either 'summary' (overall result) or the section name (not yet used)
 * @return object, the chart data for Module 3
 */
export const getChartDataMod3 = (jtLabels, benchMod3All, benchMod3Cat, mod3AllResults, mod3CatResults, type) => {
  let chartLabels, benchAllData, benchCatData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.CONSENSUS.name] || 'Consensus'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.STRATEGY.name] || 'Strategy'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.COMMUNICATION.name] || 'Communication'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.HR_DIVERSITY.name] || 'hrDiversity'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.HR_LOYALTY.name] || 'hrLoyalty'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod3All.KON.stdDev === 0 ? 100 : 50,
        benchMod3All.STR.stdDev === 0 ? 100 : 50,
        benchMod3All.IKM.stdDev === 0 ? 100 : 50,
        benchMod3All.HRD.stdDev === 0 ? 100 : 50,
        benchMod3All.HRB.stdDev === 0 ? 100 : 50,
      ]
      benchCatData = [
        benchMod3Cat?.KON.percentile,
        benchMod3Cat?.STR.percentile,
        benchMod3Cat?.IKM.percentile,
        benchMod3Cat?.HRD.percentile,
        benchMod3Cat?.HRB.percentile,
      ]
      allResultsData = [
        mod3AllResults.KON.percentile,
        mod3AllResults.STR.percentile,
        mod3AllResults.IKM.percentile,
        mod3AllResults.HRD.percentile,
        mod3AllResults.HRB.percentile,
      ]
      catResultsData = [
        mod3CatResults?.KON.percentile,
        mod3CatResults?.STR.percentile,
        mod3CatResults?.IKM.percentile,
        mod3CatResults?.HRD.percentile,
        mod3CatResults?.HRB.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }
  // if the category benchmark or category results are undefined, null or 0,
  // set the data to null so that they will not be included in chart
  benchCatData = onlyPreserveValidData(benchCatData)
  // if there is no benchmark for this category, the results based on this are irrelevant, also.
  catResultsData = benchCatData ? onlyPreserveValidData(catResultsData) : null
  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatData, allResultsData, catResultsData)
}
