import zTable from './z-table.json'

export const roundToPlace = (num, place) => {
  const factor = Math.pow(10, place)
  return Math.round(num * factor) / factor
}

/**
 * called by calcModResults.js
 * @param mean, numbers, the mean answer pertaining to present item or construct calculation of this NPO's survey
 * @param benchMean, number, the benchmark mean pertaining to present calculation
 * @param benchStdDev, number, the benchmark std. deviation pertaining to present calculation
 * @return percentile, integer
 */
const hundredthPlaceArr = ['0.00', '0.01', '0.02', '0.03', '0.04',
  '0.05', '0.06', '0.07', '0.08', '0.09']
export const calcPercentile = (mean, benchMean, benchStdDev) => {
  // if the values array is empty of answer numbers, return null
  if (mean === null) return null

  // avoid a division by 0 that could happen at the beginning
  if (benchStdDev === 0) return 100

  const zScore = (mean - benchMean) / benchStdDev
  const zScoreAbs = Math.abs(zScore)
  const zString = zScoreAbs.toFixed(2)
  const zTableTenths = zString.substring(0, 3)
  const zTableHundredths = hundredthPlaceArr[zString[3]]
  let percentile = zScoreAbs <= 3.49 ?
    Math.round(zTable[zTableTenths][zTableHundredths] * 100)
    : 100 // if number is out of range, return 100 (this can happen at the beginning)
  if (zScore < 0) percentile = 100 - percentile

  return percentile
}

// Calculate the sum of an array of numbers
// export const calcSum = (values) => {
//   // remove any non-number values and also any -1 values,
//   // which reflect 'No answer possible', from the calculation
//   values = values.filter(item => typeof item === 'number' && item >= 0)
//   return values.length < 1 ? null : values.reduce((sum, current) => sum + current)
// }

// from: https://www.kindacode.com/snippet/calculate-variance-and-standard-deviation-in-javascript/
// Calculate the mean (average) of an array of numbers
// export const calcMean = (values) => {
//   values = values.filter(item => typeof item === 'number') // remove any non-number values from calculation
//   if (values.length < 1) return null
//   const mean = (values.reduce((sum, current) => sum + current)) / values.length
//   // round off to thousandth's place and return
//   return roundToPlace(mean, 3)
// }

// Calculate variance of an array of numbers
// export const calcVariance = (values) => {
//   values = values.filter(item => typeof item === 'number') // remove any non-number values from calculation
//   if (values.length < 1) return null;
//   const average = calcMean(values);
//   const squareDiffs = values.map((value) => {
//     const diff = value - average;
//     return diff * diff;
//   });
//   const variance = calcMean(squareDiffs)
//   return variance
// };

// Calculate standard deviation of an array of numbers
// export const calcSTDEV = (values) => {
//   values = values.filter(item => typeof item === 'number') // remove any non-number values from calculation
//   if (values.length < 1) return null
//   return Math.sqrt(calcVariance(values))
// }

/** obsolete since incorporation of npo-survey collection (11.9.24)
 * called by calcModule1Results.js...calcModule5Results.js
 * @param values, [numbers], the answers pertaining to present calculation
 * @param benchMean, number, the benchmark mean pertaining to present calculation
 * @param benchStdDev, number, the benchmark std. deviation pertaining to present calculation
 * @return object, { mean: <number>, percentile: <integer>}
 */
// const hundredthPlaceArr = ['0.00', '0.01', '0.02', '0.03', '0.04',
//   '0.05', '0.06', '0.07', '0.08', '0.09']
// export const calcMeanAndPercentile = (values, benchMean, benchStdDev) => {
//   const mean = calcMean(values)
//   // if the values array is empty of answer numbers, return null
//   if (mean === null) return { mean: null, percentile: null }
//
//   // avoid a division by 0 that could happen at the beginning
//   if (benchStdDev === 0) return {
//     mean: roundToPlace(mean, 3), // round down to thousandth's place
//     percentile: 100,
//   }
//
//   const zScore = (mean - benchMean) / benchStdDev
//   const zScoreAbs = Math.abs(zScore)
//   const zString = zScoreAbs.toFixed(2)
//   const zTableTenths = zString.substring(0, 3)
//   const zTableHundredths = hundredthPlaceArr[zString[3]]
//   let percentile = zScoreAbs <= 3.49 ?
//     Math.round(zTable[zTableTenths][zTableHundredths] * 100)
//     : 100 // if number is out of range, return 100 (this can happen at the beginning)
//   if (zScore < 0) percentile = 100 - percentile
//
//   return {
//     mean: roundToPlace(mean, 3),
//     percentile,
//   }
// }


// obsolete since incorporation of npo-survey collection (11.9.24)
// export const convertZeroOneToOneSix = (value) => {
//   // convert from 0-1 to 1-6 unless either null or undefined
//   return value != null ? value * 5 + 1 : null
// }
