export function gotoLink(index, item, surveyStatus) {
  // compensate for moving module 0 to the end of the array of modules
  const trueIndex = index < 5 ? index + 1 : 0
  return surveyStatus[`module${trueIndex}`] === '100%'
    ? item.resultsLink
    : item.surveyLink
}

export function gotoSurveyLink(event, item, navigate) {
  event.preventDefault()
  // console.log('gotoSurveyLink() item:', item)
  navigate(item.surveyLink)
}
