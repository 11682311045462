import { store } from '../app/store'
import axios from 'axios'
import { authHeaders } from './authHeaders'
import { updateUserOrgCategory } from '../features/user/userSlice'
import { handleStrapiAPIError } from './handleStrapiAPIError'
import { toast } from 'react-toastify'
import { showCategoryNotSetWarning } from './getMiscToastMsg'

const API_URL = process.env.REACT_APP_API_URL

/**
 * update user's organization category and store updated user back in DB and Redux
 * @param user, object: { userID, orgID, orgCategory }
 * @param jtToast, object
 */
export const updateUserOrgCat = (user, jtToast = {}) => {
  // console.log('updateUserOrgCat() called')
  axios
    .get(
      `${API_URL}/api/organizations/${user.orgID}`,
      authHeaders(),
    )
    .then(res => {
      const data = res.data.data
      const orgCategory = data.attributes.orgCategory
      if (!orgCategory) showCategoryNotSetWarning(jtToast)
      else if (orgCategory !== user.orgCategory) {
        axios
          .put(
            `${API_URL}/api/users/${user.userID}`,
            { orgCategory },
            authHeaders(),
          )
          .then(() => {
            store.dispatch(updateUserOrgCategory({ orgCategory }))
            toast.success(jtToast.orgCategoryUpdated || 'Your organization category was just updated.')
          })
      }
    })
    .catch(err => handleStrapiAPIError(err))

}
