import { useEffect, useState } from "react";
import QuestionForm from "../common/QuestionForm/QuestionForm";
import QuestionFormRadio from "../common/QuestionFormRadio/QuestionFormRadio";
import { giveAnswer } from '../../utils/giveAnswer'
import styles from "./Module2.module.scss"

// render the MLQ section of Module 2 (Step 3) of the full survey; called by Module2.js
const Module2MLQ = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}); // future object like { "MLQ1": 1, "MLQ2": 0, ...}

  useEffect(()=>{
    /* set up initialAnswerObj: { AGR0: null, AGR1: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'MLQ')
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module2.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[previousAnswers])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      return <div key={question.questionID}>
        {idx === 0 ?
          <div className="mb-4">
            <QuestionFormRadio
              questionIndex={idx}
              question={question.question1}
              questionFormat={question.format}
              answers={question.answerArray}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'MLQ', val, questionIndex, null, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
          :
          <div>
            <QuestionForm
              question1={question.question1}
              question2={question.question2}
              questionIndex={idx}
              questionFormat={question.format}
              answerLabels={question.answerLabels || 'default'}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'MLQ', val, questionIndex, null, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
        }
      </div>
    })}
  </div>)
}

export default Module2MLQ;
