import { useSelector } from 'react-redux'
import Footer from '../Footer/Footer'

const News = () => {
  const jt = useSelector(state => state.jsonText.jsonText)

  return <div>
    <h1 className="my-5">{jt.label?.news || "News"}</h1>
    <h3 className="my-5">noch im Aufbau</h3>

    <Footer />
  </div>
}

export default News
