import { useEffect, useState } from 'react'
import QuestionForm from '../common/QuestionForm/QuestionForm'
import QuestionFormRadio from '../common/QuestionFormRadio/QuestionFormRadio'
import { giveAnswer, IVB_SHORT_LENGTH } from '../../utils/giveAnswer'
import styles from './Module1.module.scss'

// render the IVB section of Module 1 (Step 2) of the full survey; called by Module1.js
// IVB = IVB0 + GEM7-10 + PRO10-14 (Interessenverbände)
const Module1IVB = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const [answerObj, setAnswerObj] = useState({}) // future object like { "IVB0": 1, "GEM7": 3, ...}

  // console.log('questions:', questions)

  useEffect(() => {
    /* set up initialAnswerObj: { IVB0: null, GEM7: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar, if IVB0 has been answered 'no' ('Nein'), reduce questions from 10 to 1
      const numIvbQs = previousAnswers.IVB0 === questions[0].answerArray[1] ?
        IVB_SHORT_LENGTH
        : questions.length
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'IVB', numIvbQs)
    }
    setAnswerObj(initialAnswerObj)
    updateAnswers(initialAnswerObj) // send answers to Module1.js for saving in DB

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousAnswers])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      // if first question has been answered 'no', hide the rest of the questions
      if (idx >= 1 && answerObj[questions[0].questionID] === questions[0].answerArray[1]) return null
      return <div key={question.questionID}>
        {question.questionID === 'IVB0' ?
          <div>
            <QuestionFormRadio
              questionIndex={idx}
              question={question.question1}
              questionFormat={question.format}
              answers={question.answerArray}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'IVB', val, questionIndex, null, questions, answerObj,
                setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
          :
          <div>
            <QuestionForm
              question1={question.question1}
              question2={question.question2}
              questionIndex={idx}
              questionFormat={question.format}
              answerLabels={question.answerLabels || 'default'}
              currentAnswer={answerObj[question.questionID]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'IVB', val, questionIndex, null, questions, answerObj,
                setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
        }
      </div>
    })}
  </div>)
}

export default Module1IVB
