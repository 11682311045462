import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import Footer from '../Footer/Footer'
import styles from './Welcome.module.scss'

// welcome screen for logged-in users at route '/welcome-back'
const WelcomeBack = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>
          {jt.header?.welcomeBack || "Welcome back"}{', '}
          <span>{user.firstName}</span>
        </h1>
        <p>
          {jt.block?.benchmarkPresentation || "We are a group of specialists from Freiburg University in Switzerland"}
        </p>
        <div className={styles.buttons}>
          <Row className="align-items-center">
            <Col md="auto">
              <LinkContainer to={'/survey'}>
                <button className={styles.headerBtn}>{jt.label?.takeSurvey || "Take survey"}</button>
              </LinkContainer>
            </Col>
            <Col>
              <LinkContainer to={'/results'}>
                <button className={styles.secondBtn}>{jt.label?.yourResults || "Your Results"}</button>
              </LinkContainer>
            </Col>
          </Row>
        </div>
      </div>

      <div className={styles.bottomBlock}>
        <p className={styles.boldLine}>
          {jt.block?.benchmarkYieldsResults || "NPOs who use the Benchmark achieve better results."}
        </p>
        <p className={styles.secondLine}>
          {jt.block?.beginYourAssessment || 'Start your survey and begin the assessment of your NPO.'}
        </p>
        <LinkContainer to={'/survey'}>
          <button className={styles.bottomBtn}>{jt.label?.startSurveyNow || "Start your survey now"}</button>
        </LinkContainer>
      </div>
      <Footer />
    </div>
  )
}

export default WelcomeBack
