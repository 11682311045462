import { store } from '../app/store'
import axios from 'axios'
import { updateSurveyStatus } from '../features/user/userSlice'
import { authHeaders } from './authHeaders'
import { handleStrapiAPIError } from './handleStrapiAPIError'
import { formatDate } from './formatDate'
import { getDateOfLastValidSurvey } from './getSurveyDate'

const API_URL = process.env.REACT_APP_API_URL

export const closeSurvey = (orgData, navigate) => {
  const surveyStatus = {
    module0: '0%',
    module1: '0%',
    module2: '0%',
    module3: '0%',
    module4: '0%',
    module5: '0%',
    lastUpdate: formatDate(new Date()), // send this here, so we don't need to create the same fn on BE
  }
  const closeSurveyData = {
    orgData,
    dateOfLastValidSurvey: getDateOfLastValidSurvey(), // send this here, so we don't need to create the same fn on BE
    surveyStatus,
  }
  axios
    .post(
      `${API_URL}/api/close-survey`,
      { closeSurveyData },
      authHeaders(),
    )
    .then(res => {
      console.log('res:', res)
      store.dispatch(updateSurveyStatus({ surveyStatus }))
      navigate(0) // reload this page
    })
    .catch(error => handleStrapiAPIError(error))

}
