import styles from './ResearchBadge.module.scss'

/**
 * render the 'Nexus of Research and Practice' badge
 */
const ResearchBadge = ({ jtLabel }) => {
  // const screenSize = window.innerWidth > 830 ? 'lg' : 'sm'
  return (
    <div className={styles.container}>
      <div className={styles.badge}>
        <div className={styles.topLine}>
          {jtLabel?.nexusOfResearchAndPractice || 'Nexus of Research and Practice'}
          {':'}
        </div>
        <div className={styles.secondLine}>
          {jtLabel?.boostingProfessionalismOfNPOs || 'Boosting the Professionalism of NPOs'}
        </div>
      </div>
    </div>
  )
}

export default ResearchBadge
