import axios from 'axios'
import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { addOrgToUser } from '../../features/user/userSlice'
import Footer from '../Footer/Footer'
import RegisterNowCTA from '../common/RegisterNowCTA/RegisterNowCTA'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { authHeaders } from '../../utils/authHeaders'
import { REDIRECTION_TIMEOUT } from '../../utils/constants'
import { getOrgConnectSuccessMsg } from '../../utils/getMiscToastMsg'
import styles from './Organization.module.scss'

const API_URL = process.env.REACT_APP_API_URL

// allow a user to select the organization they belong to or navigate to '/organization-create'
const OrgSelect = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  // console.log('user:', user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [organization, setOrganization] = useState({})
  const [allOrgs, setAllOrgs] = useState([])

  useEffect(() => {
    if (user.strapiJwt) {
      axios
        .get(`${API_URL}/api/organizations`, authHeaders())
        .then(res => {
          const orgs = res.data.data.map(org => {
            return {
              value: org.id,
              label: org.attributes.name,
              category: org.attributes.orgCategory,
            }
          })
          // console.log('orgs:', orgs)
          setAllOrgs(orgs)
        })
        .catch(error => handleStrapiAPIError(error))
    }
  }, [user])

  const onFormSubmit = async (event) => {
    event.preventDefault()
    axios
      .put(
        `${API_URL}/api/users/${user.userID}`,
        {
          organizationID: organization.value,
          organization: organization.label,
          isOrgCreator: false,
          orgCategory: organization.category,
        },
        authHeaders()
      )
      .then(() => {
        dispatch(
          addOrgToUser({
            orgID: organization.value,
            orgName: organization.label,
            isOrgCreator: false,
            orgCategory: organization.category,
          })
        )
        toast.success(getOrgConnectSuccessMsg(
          organization.label, user.firstName, jt.toast, 'select'),
        )
        setTimeout(() => navigate('/welcome-back'), REDIRECTION_TIMEOUT)
      })
      .catch(error => handleStrapiAPIError(error))
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>{jt.header?.selectYourOrg || "Select Your Organization"}</h1>
        <p>
          {jt.block?.selectOrgInstructions || "If your organization is already registered, enter the name and select it from the list."}
        </p>

        <Form onSubmit={onFormSubmit}>
          <Row className="g-3">
            <Col xs={6}>
              <Select
                value={organization}
                onChange={val => {
                  console.log('react-select val', val)
                  setOrganization(val)
                }}
                options={allOrgs}
                placeholder={jt.placeholder?.selectYourOrganization || "Your organization's name"}
                isMulti={false}
                isSearchable
                styles={{
                  control: (styles) => ({
                    ...styles,
                    borderRadius: '0',
                    padding: '10px 12px',
                    fontFamily: 'Source Sans Pro',
                    fontSize: '22px',
                    color: '#000',
                  }),
                  option: (styles) => ({
                    ...styles,
                    padding: '8px 20px',
                    fontFamily: 'Source Sans Pro',
                    fontSize: '22px',
                    color: '#000',
                  }),
                }}
              />
            </Col>
            <Col xs={5}>
              <button type="submit" className={styles.headerBtn}>
                {jt.label?.confirmSelection || "Confirm Selection"}
              </button>
            </Col>
          </Row>
          <div
            onClick={() => navigate('/organization-create')}
            className={styles.link}
          >
            {jt.header?.createNewOrg || "Create a new organization"}
          </div>
        </Form>
      </div>

      <RegisterNowCTA />
      <Footer />
    </div>
  )
}

export default OrgSelect
