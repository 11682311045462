import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import QuestionForm from "../common/QuestionForm/QuestionForm";
import { giveAnswer } from '../../utils/giveAnswer'
import styles from "./Module5.module.scss"


// render the PER1 section of Module 5 (Step 6) of the full survey; called by Module5.js
const Module5PER1 = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {

  const jt = useSelector(state => state.jsonText.jsonText)
  const [answerObj, setAnswerObj] = useState({}) // future object like { "PER1a": 5, "PER1b": 2, ...}

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    /* set up initialAnswerObj: { "PER1a": null, "PER1b": null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'PER1')
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module5.js for saving in DB

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[previousAnswers])

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      let currentAnswer = answerObj[question.questionID]
      return <div key={question.questionID}>
        {idx === 5 ? // PER1f
          <div className='mb-4'>
            <div className={styles.numberQuestion}>
              {question.question1}
            </div>
            <input
              type='number'
              value={currentAnswer || 0}
              onChange={e => giveAnswer('PER1', parseInt(e.target.value), idx, null, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress)
              }
              className={styles.numberInput}
            />
            {currentAnswer > 0 &&
              <div className={styles.numberComment}>
                {jt.block?.workersIncreased || "The number of workers increased"}
              </div>
            }
            {currentAnswer === 0 &&
              <div className={styles.numberComment}>
                {jt.block?.workersSame || "The number of workers is the same"}
              </div>
            }
            {currentAnswer < 0 &&
              <div className={styles.numberComment}>
                {jt.block?.workersDecreased || "The number of workers decreased"}
              </div>
            }
            <hr className={styles.horizontalLine}></hr>
          </div>
          :
          <div>
            <QuestionForm
              question1={question.question1}
              question2={question.question2}
              questionIndex={idx}
              questionFormat={question.format}
              answerLabels={question.answerLabels || 'default'}
              currentAnswer={currentAnswer}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'PER1', val, questionIndex, null, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress,
              )}
            />
            <hr className={styles.horizontalLine}></hr>
          </div>
        }
      </div>
    })}
  </div>)
}

export default Module5PER1;
