import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import QuestionForm from '../common/QuestionForm/QuestionForm'
import { giveAnswer } from '../../utils/giveAnswer'
import styles from './Module3.module.scss'


// render the questions of Module 3 (Step 4) of the full survey; called by Module3.js
const Module3OtherQs = ({
  questions,
  previousAnswers,
  maxQuestionsPerPage,
  updateProgress,
  updateAnswers,
}) => {

  const jt = useSelector(state => state.jsonText.jsonText)
  const [answerObj, setAnswerObj] = useState({}); // future object like { "HRB1": 3, "HRB2": 2, ...}
  const [currentQuestions, setCurrentQuestions] = useState([]) // questions of current page
  const [questionOffset, setQuestionOffset] = useState(0) // first question of current page

  useEffect(() => {
    /* set up initialAnswerObj: { AGR0: null, AGR1: null, ...} */
    const initialAnswerObj = {}
    questions.forEach(question => initialAnswerObj[question.questionID] = null)

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) initialAnswerObj[questKey] = previousAnswers[questKey]
      }
      // also update progress bar
      updateProgress(Object.values(previousAnswers).filter(answer => answer !== null).length, 'Other')
    }
    setAnswerObj(initialAnswerObj);
    updateAnswers(initialAnswerObj); // send answers to Module3.js for saving in DB

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousAnswers])

  useEffect(() => {
    const endOffset = questionOffset + maxQuestionsPerPage
    setCurrentQuestions((prev) => questions.slice(questionOffset, endOffset))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionOffset])

  const totalPages = Math.ceil(questions.length / maxQuestionsPerPage)

  const handlePageClick = ({ selected }) => {
    const newOffset = (selected * maxQuestionsPerPage) % questions.length
    setQuestionOffset((prev) => newOffset)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div>
      {/* Avoid errors where answerArray has not yet been filled out
       or where currentQuestions has not yet updated and temporarily attempts
        to render more questions than there are answers for */}
      {Object.keys(answerObj).length > questionOffset + currentQuestions.length - 1 &&
        currentQuestions.map((question, idx) => {
          return (
            <div key={questionOffset + idx}>
              <QuestionForm
                question1={question.question1}
                question2={question.question2}
                questionIndex={questionOffset + idx}
                questionFormat={question.format}
                answerLabels={question.answerLabels || 'default'}
                currentAnswer={answerObj[question.questionID]}
                giveAnswer={(val, questionIndex) => giveAnswer(
                  'Other', val, questionIndex, null, questions, answerObj,
                  setAnswerObj, updateAnswers, updateProgress,
                )}
              />
              <hr className={styles.horizontalLine}></hr>
            </div>
          )
        })}

      <ReactPaginate
        nextLabel={jt.label?.nextPage || "Next >"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel={jt.label?.prevPage || "Back >"}
        renderOnZeroPageCount={null}
        containerClassName={styles.paginationBtns}
        activeClassName={styles.paginationActiveBtn}
      />
    </div>
  )
}

export default Module3OtherQs
