import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { CONSTANTS } from '../../utils/constants'
import styles from './Module0.module.scss'



/* render the follow-up questions and answers to KVO1a;
   called by Module0.js */
const Module0FollowQ = ({
  questionID,
  question,
  questionOptions,
  setFollowQAnswer,
  previousAnswers,
  previousAnswerQuestionID,
}) => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const [answerArray, setAnswerArray] = useState([])
  const [inputText, setInputText] = useState('')

  useEffect(() => {
    // re-initialize with each change in KVO1a
    let addedText = ''
    const initArray = []
    // if this is same question that was previously saved, populate with saved answers
    if (questionID === previousAnswerQuestionID) {
      questionOptions.forEach((option) => {
        if (previousAnswers.includes(option)) {
          initArray.push(true)
          /* if 'sontiges' was selected and there is another string
            following it, initialize added text field with this. */
          if (
            option === questionOptions[questionOptions.length - 1] &&
            option !== previousAnswers[previousAnswers.length - 1]
          ) {
            addedText = previousAnswers[previousAnswers.length - 1]
          }
        } else initArray.push(false)
      })
    }
    setAnswerArray(initArray)
    setInputText(addedText)
    updateAnswers(initArray, addedText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionID])

  const onRadioClick = (idx) => {
    const newAnswerArray = answerArray
    newAnswerArray[idx] = !newAnswerArray[idx]
    updateAnswers(newAnswerArray, inputText)
  }

  const handleTextInput = (text) => {
    setInputText(text)
    updateAnswers(answerArray, text)
  }

  const updateAnswers = (newAnswerArray, newText) => {
    const answers = []
    newAnswerArray.forEach((answer, idx) => {
      if (answer) answers.push(questionOptions[idx])
    })
    if (newAnswerArray[questionOptions.length - 1] && newText)
      answers.push(newText)
    setAnswerArray(newAnswerArray)
    setFollowQAnswer(answers)
  }

  const activeButtonStyling = {
    borderColor: CONSTANTS.colors.orange,
    backgroundColor: CONSTANTS.colors.orange,
  }
  const inactiveButtonStyling = {
    borderColor: CONSTANTS.colors.orange,
    backgroundColor: 'white',
  }

  return (
    <div>
      <div className={styles.question}>
        {question}
        <span>
          ({jt.block?.youCanSelectMultiple || "You can select multiple options"})
        </span>
      </div>
      <ToggleButtonGroup
        type="checkbox"
        name={`answer-radios-${questionID}`} // name must be shared by all radio buttons in group
        className={styles.toggleButtons}
      >
        {questionOptions.map((answer, i) => {
          return (
            <ToggleButton
              key={answer}
              id={`tbg-btn-${questionID}${i + 1}`} // unique id is required in a ToggleButtonGroup
              value={i} // this id must also be unique in the HTML document
              onClick={() => onRadioClick(i)}
              className={styles.radios}
              // the only way to overwrite bootstrap styling
              style={
                answerArray[i] ? activeButtonStyling : inactiveButtonStyling
              }
            >
              <span className={styles.radioAnswer}>{answer}</span>
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
      {answerArray[questionOptions.length - 1] && (
        <div className={styles.inputText}>
          <Form.Control
            value={inputText}
            onChange={(e) => handleTextInput(e.target.value)}
          />
        </div>
      )}
    </div>
  )
}

export default Module0FollowQ
