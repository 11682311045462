import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg'
import SideTgglBtnForm from '../common/SideTgglBtnForm/SideTgglBtnForm'
import { countSubQAns } from '../../utils/countSubQAns'
import { giveAnswer } from '../../utils/giveAnswer'
import styles from './Module2.module.scss'

// render the IPQ section of Module 2 (Step 3) of the full survey; called by Module2.js
const Module2IPQ = ({ questions, previousAnswers, updateProgress, updateAnswers }) => {
  const jt = useSelector(state => state.jsonText.jsonText)
  /* future object like
    {
      "IPQ1": {
        "Strategisches Gremium": 1,
        "Operative Geschäftsleitung": 5,
        "Spezialist:innen": 4,
        "Basis": 2
      },
      "IPQ2": {
        "Strategisches Gremium": 2,
        "Operative Geschäftsleitung": 1,
        "Spezialist:innen": 3,
        "Basis": 5
      }
    }
  */
  const [answerObj, setAnswerObj] = useState({})
  const [offSwitchObj, setOffSwitchObj] = useState({})

  // get array of subQuestions from the first question of this section
  const subQuestions = [questions[0].subQuestion1, questions[0].subQuestion2,
    questions[0].subQuestion3, questions[0].subQuestion4]

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    /* initially fill in answerObj with null values for each subQuestion like:
      {
        "IPQ1": {
          StratGremium: null,
          OperativeGL: null,
          Spezialisten: null,
          Basis: null
        },
        ...
      }
    */
    const initialAnswerObj = {}
    const initialOffSwitchObj = {}
    questions.forEach(question => {
      initialAnswerObj[question.questionID] = {}
      subQuestions.forEach(subQ => initialAnswerObj[question.questionID][subQ] = null)
      initialOffSwitchObj[question.questionID] = false
    })

    /* if previous answers exist, update initialAnswerObj */
    if (Object.keys(previousAnswers).length) {
      for (const questKey in initialAnswerObj) {
        if (previousAnswers[questKey]) {
          for (const subQ in previousAnswers[questKey]) {
            initialAnswerObj[questKey][subQ] = previousAnswers[questKey][subQ]
            if (previousAnswers[questKey][subQ] === -1) initialOffSwitchObj[questKey] = true
          }
        }
      }
      // also update progress bar
      updateProgress(countSubQAns(initialAnswerObj), 'IPQ')
    }
    setAnswerObj(initialAnswerObj)
    updateAnswers(initialAnswerObj) // send answers to Module2.js for saving in DB
    setOffSwitchObj(initialOffSwitchObj)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOffSwitch = (questionIndex) => {
    const questKey = questions[questionIndex].questionID
    const val = !offSwitchObj[questKey] ? -1 : null  // state still holds the old value
    giveAnswer(
      'IPQ', val, questionIndex, null, questions,
      answerObj, setAnswerObj, updateAnswers, updateProgress,
    )
    setOffSwitchObj({
      ...offSwitchObj,
      [questKey]: !offSwitchObj[questKey],
    })
  }

  return (<div>
    {Object.keys(answerObj).length && questions.map((question, idx) => {
      return <div key={question.questionID} className={styles.container}>
        <div className={styles.questionBeforeSideToggle}>{question.question1}</div>
        <div><RightArrow className={styles.rightArrow} alt='right arrow' /></div>
        {subQuestions.map((subQ, subIdx) => {
          return <div key={subQ}>
            <SideTgglBtnForm
              question={subQ}
              questionIndex={idx}
              subQuestionIndex={subIdx}
              questionFormat={question.format}
              answerLabels={question.answerLabels || 'default'}
              currentAnswer={answerObj[question.questionID][subQ]}
              giveAnswer={(val, questionIndex) => giveAnswer(
                'IPQ', val, questionIndex, subQ, questions,
                answerObj, setAnswerObj, updateAnswers, updateProgress,
              )}
            />
          </div>
        })}
        <div>
          <Form.Check
            type='checkbox'
            id={`Q${idx}-offSwitch`}
            className={styles.ipqOffSwitch}
            label={jt.label?.noAnswerPossible || 'No answer is possible'}
            checked={offSwitchObj[question.questionID]}
            onChange={() => handleOffSwitch(idx)}
          />
        </div>
        <hr className={styles.horizontalLine2}></hr>
      </div>
    })}
  </div>)
}

export default Module2IPQ;
