import axios from 'axios'
import { useState, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { toast } from 'react-toastify'
import { loginUser } from '../../features/user/userSlice'
import Footer from '../Footer/Footer'
import RegisterNowCTA from '../common/RegisterNowCTA/RegisterNowCTA'
import TermsAndConditions from '../common/TermsAndConditions/TermsAndConditions'
import { MIN_PASS_LENGTH, REDIRECTION_TIMEOUT } from '../../utils/constants'
import { checkEmailFormat } from '../../utils/checkEmailFormat'
import { handleStrapiAPIError } from '../../utils/handleStrapiAPIError'
import { getInvalidEmailMsg, getMissingEmailMsg, getPwTooShortMsg } from '../../utils/getMiscToastMsg'
import styles from './Login.module.scss'

const API_URL = process.env.REACT_APP_API_URL

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const jt = useSelector(state => state.jsonText.jsonText)
  const [identifier, setIdentifier] = useState('') // Strapi accepts email or username
  const [password, setPassword] = useState('')

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const onFormSubmit = (event) => {
    event.preventDefault()
    if (identifier === '') {
      toast.error(getMissingEmailMsg(jt.toast))
      return
    }
    if (!checkEmailFormat(identifier)) {
      toast.error(getInvalidEmailMsg(jt.toast))
      return
    }
    if (password === '') {
      toast.error(jt.toast?.missingPassword || "Your password is missing.\nPlease check and try again.")
      return
    }
    if (password.length < MIN_PASS_LENGTH) {
      toast.error(getPwTooShortMsg(jt.toast))
      return
    }
    axios
      .post(`${API_URL}/api/auth/local`, {
        identifier,
        password,
      })
      .then(res => {
        // console.log('res.data:', res.data)
        const user = res.data.user
        localStorage.setItem('bjwt', res.data.jwt)
        dispatch(
          loginUser({
            userID: user.id,
            email: user.email,
            firstName: user.firstName,
            strapiJwt: res.data.jwt,
            orgID: user.organizationID,
            orgName: user.organization,
            isOrgCreator: user.isOrgCreator,
            orgCategory: user.orgCategory,
            language: user.language || localStorage.getItem('language'),
            surveyStatus: user.surveyStatus,
          }),
        )
        toast.success(jt.toast?.youHaveBeenLoggedIn ||
          'You have been logged in!\nYou will be redirected shortly...',
        )
        setTimeout(
          () =>
            user.organizationID
              ? navigate('/welcome-back')
              : navigate('/organization-select'),
          REDIRECTION_TIMEOUT,
        )
      })
      .catch(error => handleStrapiAPIError(error))
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        <h1>
          {jt.header?.loginToStart || "Log in to start"}
        </h1>
        <Form onSubmit={onFormSubmit}>
          <Row className="g-5">
            <Col xs={6}>
              <Form.Control
                type="text"
                placeholder={jt.placeholder?.email || "Email address"}
                autoComplete="username"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                className={styles.formEntry}
              />
              <Form.Control
                type="password"
                placeholder={jt.placeholder?.password || "Password"}
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                // isInvalid={isPasswordInvalid}
                className={styles.formEntry}
              />
            </Col>
            <Col xs={6} className="px-5">
              <p>
                {jt.block?.logInAndGetStarted || "Welcome once more! Login to get started or to continue with your assessment."}
              </p>
            </Col>
          </Row>
          <div className={styles.buttons}>
            <Row className="align-items-center">
              <Col md="auto">
                <button type="submit" className={styles.headerBtn}>
                  Einloggen
                </button>
              </Col>
              <Col>
                <LinkContainer to={'/forgot-password'}>
                  <button className={styles.secondBtn}>Passwort vergessen?</button>
                </LinkContainer>
              </Col>
            </Row>
          </div>
        </Form>
      </div>

      <RegisterNowCTA />
      <TermsAndConditions />
      <Footer />
    </div>
  )
}

export default Login
