import { Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { CONSTANTS, QUESTION_FORMAT_KEY } from '../../../utils/constants'
import { getToggleBtnStyle } from '../../../utils/getToggleBtnStyle'
import styles from './SideTgglBtnForm.module.scss'

// choose one of the following formats and comment out the other:
const FORMAT_KEY = QUESTION_FORMAT_KEY

/* render survey subQuestions with colored toggle-button-groups on the side
  called by Module2IPQ.js, Module5PER4.js, Module5PER5af.js, Module5PER5gm.js,*/
const SideTgglBtnForm = ({
                           question,
                           questionIndex,
  subQuestionIndex,
  questionFormat,
  answerLabels,
  currentAnswer,
  giveAnswer
}) => {

  const { answers, colors, defaultLabels } = FORMAT_KEY[questionFormat];

  const answerChange = (val) => {
    giveAnswer(val, questionIndex, subQuestionIndex);
  }

  return (
    <div className={styles.container}>
      <Row className="align-items-center g-1">
        <Col xs={5} className={styles.leftColumn}>
          <div className={styles.question}>{question}:</div>
        </Col>
        <Col xs={7} className={styles.rightCol}>
          <ToggleButtonGroup
            type="radio"
            value={currentAnswer}
            className="mt-1 something"
            name={`answer-radios-Q${questionIndex}-SQ${subQuestionIndex}`}  // name must be shared by all radio buttons in group
            onChange={answerChange}                                         // and be distinct from other buttongroups
          >
            {answers.map((answerValue, i) => {
              let backgroundColor = CONSTANTS.colors.white
              if (answerValue === currentAnswer) backgroundColor = colors[i]
              if (currentAnswer === -1) backgroundColor = CONSTANTS.colors.gray
              return (
                <ToggleButton
                  key={answerValue}
                  id={`tbg-btn-Q${questionIndex}-SQ${subQuestionIndex}-${i + 1}`} // unique id is required in a ToggleButtonGroup
                  value={answerValue}                                             // this id must also be unique in the HTML document
                  className={styles.radios}
                  data-toggle='tooltip'
                  data-placement='right'
                  title={answerLabels === 'default' ? defaultLabels[i] : answerLabels[i]}
                  // the only way to overwrite bootstrap styling
                  style={getToggleBtnStyle('side', i, colors[i], backgroundColor)}
                  disabled={currentAnswer === -1}
                />
              );
            })}
          </ToggleButtonGroup>
        </Col>
      </Row>
    </div>
  )
}

export default SideTgglBtnForm;
